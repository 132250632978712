
<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{'shopping.title' | translate}}</h1>
    </div>
  </div>
</div>

<!--shopping -->
<div class="shopping prevent">
  <div class="container">
    <div class="row text-center m-0">
      <div class="col-md-6">
        <h6>{{'cart.table.package.name' | translate}}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{'cart.table.price' | translate}}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{'cart.table.quantity' | translate}}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{'cart.table.amount' | translate}}</h6>
      </div>
    </div>
  </div>
</div>

<div class="packages-bg">
  <div class="container pt-1">
    <div class="package" *ngFor="let row of cartService.value.cartItems">
      <div class="row">
        <!-- item -->
        <div class="col-md-6 d-sm-flex">
          <div class="col-12 col-sm-3 col-md-3 p-0">
            <div class="discount">
              <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" alt="Alternate Text" />
            </div>
            <div class="image">
              <img [src]="static(row.imagePath)" onError="this.src='/assets/images/thumb.png'">
            </div>
          </div>
          <div class="col-12 col-sm-9 col-md-9 pe-0 ps-2">
            <div class="name pe-2">
              <!-- <h6>{{row.packageName}}</h6> -->
              <a [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                {{row.packageName}}
              </a>
              <div class="row applydate">
                <span *ngIf="row.isApplyDate" class="timeDate"> <i class="far fa-calendar-alt pe-2"></i>Áp dụng: {{row.fromDate | date: 'dd/MM/yyyy'}} - {{row.toDate | date: 'dd/MM/yyyy'}}</span>
                <!-- <span *ngIf="!row.isApplyDate" class="timeDate"> <i class="far fa-calendar-alt pe-2"></i>Ngày: {{row.publishDate | date: 'dd/MM/yyyy'}}</span> -->
                <p [innerHTML]="row.shortDescription" class="p-3"></p>
              </div>
            </div>
            <!-- Số lượng mobile -->
            <div class="mobile d-flex my-3 d-block d-md-none">
              <h6 class="price float-left me-3">Số lượng</h6>
              <div class="">
                <div class="col-md-2 align-self-center d-md-block justify-content-center">
                  <div class="number-input md-number-input justify-content-center mx-auto" style="width:100px">
                    <button type="button" (click)="addQuantity(-1, row)">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <input class="quantity" min="1" name="quantity" type="number" value="row.quantity" [(ngModel)]="row.quantity" disabled />
                    <button type="button" (click)="addQuantity(+1, row)">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>

            <!-- đơn giá mobile -->
            <div class="mobile">
              <h6 class="float-left">Đơn giá : <strong>{{row.salePrice | number:0}}</strong></h6>
            </div>
          </div>
        </div><!-- end item -->

        <div class="col-md-2 sale prevent">
          <h6>{{row.salePrice | number:0}}</h6>
        </div>

        <!-- số lượng -->
        <div class="col-md-2 align-self-center d-md-block btn-price justify-content-center">
          <div class="number-input md-number-input justify-content-center mx-auto" style="width:100px">
            <button type="button" (click)="addQuantity(-1, row)">
              <i class="fa-solid fa-minus"></i>
            </button>
            <input class="quantity" min="1" name="quantity" type="number" value="row.quantity" [(ngModel)]="row.quantity" disabled />
            <button type="button" (click)="addQuantity(+1, row)">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        </div><!-- end số lượng -->

        <!-- tổng cộng -->
        <div class="col-md-2 sale-right prevent">
          <h6>{{row.totalAmount | number:0}}</h6>
        </div><!-- end tổng cộng -->

        <!-- button delete -->
        <button type="button" class="remove" (click)="removeItemShoppingCart(row)"><i class="fas fa-times"></i></button>
      </div>
    </div>

    <div class="row package p-2" *ngIf="cartService.value.cartItems.length==0">
      <span class="m-2"><em>{{'cart.cartEmpty' | translate}}</em></span>
    </div>
  </div>

  <div class="sum">
    <div class="container">
      <div class="mergin">
        <div class="row">
          <div class="col-md-5 d-flex p-0">
          </div>
          <label class="col-3"></label>
          <div class="col-md-4 d-flex p-0">
            <div class="col-6 align-self-center">
              <h6>{{'cart.summary.total' | translate}}:</h6>
            </div>
            <div class="col-6 sale-mergin">
              <h5>{{cartService.value.summary.totalAmount| number:0}}₫</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-bg">
      <div class="container footer">
        <button type="button" class="btn btn-back my-2" [routerLink]='["/goi-kham"]'><i class="fas fa-long-arrow-alt-left"></i>  {{'cart.button.continue' | translate}}</button>
        <!-- <button type="submit" class="btn btn-pay my-2" [disabled]="!isPayment" target="_blank"><i class="fas fa-shopping-cart pe-2"></i>Thanh toán</button> -->
        <button *ngIf="cartService.value.cartItems.length>0" type="button" class="btn btn-pay my-2 ms-2" [routerLink]='["/thanh-toan"]'><i class="fas fa-shopping-cart pe-2"></i> {{'cart.button.checkout' | translate}}</button>
        <button *ngIf="cartService.value.cartItems.length>0" type="button" class="btn btn-remove my-2 ms-2" (click)="removeShoppingCart()"><i class="fas fa-trash-alt pe-2"></i>{{'cart.button.remove' | translate}}</button>
      </div>
    </div>
  </div>

</div>
