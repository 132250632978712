import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonldComponent } from './jsonld/jsonld.component';



@NgModule({
  declarations: [
    JsonldComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[JsonldComponent]
})
export class JsonldModule { }
