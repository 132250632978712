import { Component, ViewEncapsulation, Injector, Inject } from '@angular/core';
import { BaseComponent } from 'src/app/core/BaseComponent';
import { DepartmentService } from '@shared/services/department.service';
import { VaccinService } from '@shared/services/vaccin.service';
import { ResponeResult } from '@core/models/ResponeResult';

import { DOCUMENT } from '@angular/common';

import { trigger, transition, style, animate } from '@angular/animations';
import { Tag, TagType } from '@shared/models/tags.model';

@Component({
  selector: 'app-servicedetails',
  templateUrl: './servicedetails.component.html',
  styleUrls: ['./servicedetails.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(700, style({ opacity: 1 })),
      ]),
      transition('* => void', [animate(700, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ServiceDetailsComponent extends BaseComponent {
  public dataDTO: DataDTO = new DataDTO();
  public urlLink: string | any;
  private linkCode: string | any;
  public vaccin: Vaccin[];

  constructor(
    injector: Injector,
    private departmentService: DepartmentService,
    private vaccinService: VaccinService,
    @Inject(DOCUMENT) document
  ) {
    super(injector);

    this.route.paramMap.subscribe((params) => {
      this.linkCode = this.route.snapshot.paramMap.get('id');
      this.getData();

      let vaccin = this.route.snapshot.queryParamMap.get('vaccin');
      if (vaccin && this.isBrowser) {
        setTimeout(() => {
          this.scrollTo(vaccin);
        }, 500);
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('Chuyên khoa - Phòng khám Sim Med');
  }

  ngAfterViewInit() {
    this.initLightbox();
  }

  public scrollTo(elementId: string): void {
    if (this.isBrowser) {
      // this.viewportScroller.scrollToAnchor(elementId);
      let container = document.getElementById('vaccin');
      let ele = document.getElementById(elementId);
      if (ele) {
        const offsetTop = ele.offsetTop + container.offsetTop - 150;
        scroll({
          top: offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }

  getData() {
    this.departmentService
      .noValid()
      .getDataID({ linkCode: this.linkCode })
      .then((result: ResponeResult) => {
        if (result.isOk) {
          this.dataDTO = result.repData;
          if (this.dataDTO == null || this.dataDTO.rowDepartment == null) {
            //Gõ sai url param thì load default chuyên khoa
            this.router.navigate(['chuyen-khoa']);
          } else {
            this.seo.title = this.dataDTO.rowDepartment.departmentName;
            this.seo.description =
              this.dataDTO.rowDepartment.imageSeodescription;
            this.seo.tags.push(<Tag>{
              type: TagType.property,
              property: 'og:type',
              content: 'article',
            });
            this.seo.tags.push(<Tag>{
              type: TagType.property,
              property: 'og:image',
              content: this.static(this.dataDTO.rowDepartment.imageLarge),
            });
            this.seo.tags.push(<Tag>{
              type: TagType.name,
              name: 'twitter:image',
              content: this.static(this.dataDTO.rowDepartment.imageLarge),
            });
            this.updateSEO();
          }
        }
      })
      .catch(() => {
        this.router.navigate(['/page-not-found']);
      });
    this.vaccinService.getData().then(
      (result: ResponeResult) => {
        if (result.isOk) {
          var dataResult = result;
          if (dataResult.isOk) {
            this.vaccin = dataResult.repData;
          }
        }
      },
      (error) => console.error(error)
    );
  }
}

export class DataDTO {
  rowDepartment: Department = new Department();
  doctors: Doctor[] = [];
}

export class Department {
  linkCode: string | any;
  titleName: string | any;
  newsTitle: string | any;
  departmentId: number | any;
  serviceTitle: string | any;
  departmentName: string | any;
  shortDescription: string | any;
  longDescription: string | any;
  logoImage: string | any;
  imagePath: string | any;
  imageLarge: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
}

export class Doctor {
  linkCode: string | any;
  linkDepartment: string | any;
  imagePath: string | any;
  imageSmall: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  levelName: string | any;
  doctorName: string | any;
  positionTitle: string | any;
  departmentTitle: string | any;
  diplomaTitle: string | any;
  shortDescription: string | any;
}

export class Vaccin {
  productCode: string | any;
  productName: string | any;
  madeIn: string | any;
  containt: string | any;
  comments: string | any;
  productNameEng: string | any;
  madeInEng: string | any;
  containtEng: string | any;
  commentsEng: string | any;
  createdBy: number | any;
  createdDate: Date | any;
  modifyBy: number | any;
  modifyDate: Date | any;
  sortOrder: string | any;
  isPublish: boolean;
  searchString: string | any;
}
