import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";

@Injectable({ providedIn: 'root' })
export class UploadService extends BaseService {
    public upload({files, albumid}) {
        return this.postFormData("upload/file", {files, albumid});
    }

    public uploadExsit({files, albumid, overight =false}) {
      return this.postFormData("upload/file-exist", {files, albumid , overight} );
    }
}