import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { MenuItem } from '@app/frontend/model/menu-item.model';
import { BaseComponent } from '@core/BaseComponent';
import { ResponeResult } from '@core/models/ResponeResult';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap/dropdown/dropdown';
import { AnounmentDTO } from '@shared/models/anounment.model';
import { Department } from '@shared/models/department.model';
import { NewsCategorys } from '@shared/models/new-category.model';
import { OPTION_MENU } from '@shared/models/option-system';
import { PackageCategory } from '@shared/models/PackageCategory';
import { QueryObj } from '@shared/models/query.model';
import { SearchDTO } from '@shared/models/search.model';
import { BlockService } from '@shared/module/block/services/block.service';
import { CartService } from '@shared/module/cart/services/cart.service';
import { ModalService } from '@shared/module/modal';
import { TranslatesService } from '@shared/module/translate';
import { PackageService } from '@shared/services';
import { AdvertiseService } from '@shared/services/advertise.service';
import { ConfigService } from '@shared/services/config.service';
import { MenuService } from '@shared/services/menu.service';
import { SearchService } from '@shared/services/search.service';
import { TextdataService } from '@shared/services/textdata.service';
import { Observable } from 'rxjs';
// import { CartService } from 'src/app/common/services/cart.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent extends BaseComponent {
  isExpanded = false;
  isMenuCollapsed = true;
  companyInfo: Object = new Object();

  public packageCategorys: PackageCategory[];
  public departments: Department[];
  public newCatgorys: NewsCategorys[];
  public querySearch: QueryObj = new QueryObj();
  public searchDTO: SearchDTO = new SearchDTO();
  public isShowResultSearch = false;
  public isShowResultSearchMobie = false;

  public listMenu: MenuItem[];
  public menuConfig = null;
  public isShowLang: boolean = false;
  constructor(
    private modalService: ModalService,
    private menuService: MenuService,
    private searchService: SearchService,
    private advertiseService: AdvertiseService,
    private configService: ConfigService,
    private blockService: BlockService,
    private translate: TranslatesService,
    private textdataService: TextdataService,
    injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector, false);
    this.querySearch.limit = 5;
    this.querySearch.search = this.route.snapshot.queryParams.q
      ? this.route.snapshot.queryParams.q
      : '';
  }

  ngOnInit(): void {
    this.getPackageCategory();
    // this.getDataInfo();
  }
  getDataInfo() {
    this.textdataService
      .getCompanyInfo()
      .then((res) => {
        this.companyInfo = res;
      })
      .catch()
      .finally(() => {});
  }
  getPackageCategory() {
    this.listMenu = [];
    this.translate
      .onLoad()
      .then(() => {
        this.configService
          .noValid()
          .getOptionHome({ key: OPTION_MENU })
          .then((res: ResponeResult) => {
            let optionMenu = res.repData;
            if (optionMenu && optionMenu.optionId != '') {
              this.menuConfig = JSON.parse(optionMenu.optionValue);
            }

            this.isShowLang = this.checkActiveMenu('language');
            this.menuService
              .getMenu()
              .then((res: ResponeResult) => {
                let packageCategorys = res.repData.packagesCategorys;
                let newCatgorys = res.repData.newCatgorys;
                let departments = res.repData.departments;

                this.listMenu = [];
                //1. Menu Trang chủ + Đặt lịch
                if (this.checkActiveMenu('home')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.home'),
                      link: '/',
                      key: 'home',
                      pos: 0,
                    })
                  );
                }
                if (this.checkActiveMenu('booking')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.booking'),
                      link: '/dat-lich-kham',
                      key: 'booking',
                      pos: 0,
                    })
                  );
                }

                //2. Menu gói Khám
                let packageMenus = [];
                for (let i = 0; i < packageCategorys.length; i++) {
                  const element = packageCategorys[i];
                  let menu = new MenuItem({
                    label: element.categoryName,
                    link: '/goi-kham/' + element.linkCode,
                    key: 'package_' + element.packageCategoryId,
                  });
                  this.checkActiveMenu(menu.key);
                  {
                    packageMenus.push(menu);
                  }
                }

                if (this.checkActiveMenu('package-product')) {
                  packageMenus.push(
                    new MenuItem({
                      label: this.translate.instant('menu.product'),
                      link: '/gia-dich-vu',
                      key: 'package-product',
                    })
                  );
                }
                if (this.checkActiveMenu('package')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.package'),
                      link: '/goi-kham',
                      key: 'package',
                      childs: packageMenus,
                    })
                  );
                }

                //3. Menu Chuyên khoa + && +  Đội ngũ bác sĩ
                let departmentMenus = [];
                let doctorMenus = [];
                for (let i = 0; i < departments.length; i++) {
                  const element = departments[i];
                  let menuDepartment = new MenuItem({
                    label: element.departmentName,
                    link: '/chuyen-khoa/' + element.linkCode,
                    key: 'department_' + element.departmentId,
                  });
                  if (this.checkActiveMenu(menuDepartment.key)) {
                    departmentMenus.push(menuDepartment);
                  }

                  if (
                    element.departmentId != 11 &&
                    element.departmentId != 10 &&
                    element.departmentId != 2
                  ) {
                    let menuDoctor = new MenuItem({
                      label: element.departmentName,
                      link: '/doi-ngu-bac-si/' + element.linkCode,
                      key: 'doctor_' + element.departmentId,
                    });
                    if (this.checkActiveMenu(menuDoctor.key)) {
                      doctorMenus.push(menuDoctor);
                    }
                  } else if (element.departmentId === 2) {
                    let menuDoctor = new MenuItem({
                      label: this.translate.instant('menu.departmentObgy'),
                      link: '/doi-ngu-bac-si/' + element.linkCode,
                      key: 'doctor_' + element.departmentId,
                    });
                    if (this.checkActiveMenu(menuDoctor.key)) {
                      doctorMenus.push(menuDoctor);
                    }
                  }
                }
                if (this.checkActiveMenu('doctor_all')) {
                  doctorMenus.push(
                    new MenuItem({
                      label: this.translate.instant('menu.doctorAll'),
                      link: '/doi-ngu-bac-si',
                      key: 'doctor_all',
                    })
                  );
                }

                if (this.checkActiveMenu('department')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.department'),
                      link: '/chuyen-khoa',
                      key: 'department',
                      childs: departmentMenus,
                    })
                  );
                }

                if (this.checkActiveMenu('doctor')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.doctor'),
                      link: false,
                      key: 'doctor',
                      childs: doctorMenus,
                    })
                  );
                }

                //4. Menu Tin Tức
                let newMenus = [];
                for (let i = 0; i < newCatgorys.length; i++) {
                  const element = newCatgorys[i];
                  let menuNew = new MenuItem({
                    label: element.categoryName,
                    link: '/tin-tuc/' + element.linkCode,
                    key: 'news_' + element.categoryId,
                  });
                  if (this.checkActiveMenu(menuNew.key)) {
                    newMenus.push(menuNew);
                  }
                }
                if (this.checkActiveMenu('news')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.news'),
                      link: '/tin-tuc',
                      key: 'news',
                      childs: newMenus,
                    })
                  );
                }

                //5. Menu Dịch vụ doanh nghiệp
                if (this.checkActiveMenu('company')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.company'),
                      link: '/suc-khoe-doanh-nghiep',
                      key: 'company',
                      pos: 0,
                    })
                  );
                }

                //6. Menu Giới thiệu
                if (this.checkActiveMenu('about')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.about'),
                      link: '/gioi-thieu-sim-med',
                      key: 'about',
                    })
                  );
                }

                //7. Menu Tuyển dụng
                if (this.checkActiveMenu('job')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.job'),
                      link: '/tuyen-dung',
                      key: 'job',
                      pos: 0,
                    })
                  );
                }

                //8. Menu Liên hệ
                if (this.checkActiveMenu('contact')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.contact'),
                      link: '/thong-tin-lien-he',
                      key: 'contact',
                    })
                  );
                }

                //9. Menu FAQ
                let faqMenu = [];
                if (this.checkActiveMenu('insurance')) {
                  faqMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.faq.insurance'),
                      link: '/bao-lanh-vien-phi',
                      key: 'insurance',
                    })
                  );
                }

                if (this.checkActiveMenu('policy')) {
                  faqMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.faq.policy'),
                      link: '/chinh-sach-hoat-dong',
                      key: 'policy',
                    })
                  );
                }

                if (this.checkActiveMenu('security')) {
                  faqMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.faq.security'),
                      link: '/chinh-sach-bao-mat',
                      key: 'security',
                    })
                  );
                }

                if (this.checkActiveMenu('refund_policy')) {
                  faqMenu.push(
                    new MenuItem({
                      label: this.translate.instant('menu.faq.refund_policy'),
                      link: '/chinh-sach-thanh-toan-hoan-tien',
                      key: 'refund_policy',
                    })
                  );
                }

                if (this.checkActiveMenu('faqs')) {
                  this.listMenu.push(
                    new MenuItem({
                      label: 'FAQs',
                      key: 'faqs',
                      childs: faqMenu,
                    })
                  );
                }
                // this.listMenu.push(
                //   new MenuItem({
                //      label: "DEMO 1",
                //      key: "demo",
                //      link: "/demo-1"
                //   })
                // );
                // this.listMenu.push(
                //   new MenuItem({
                //      label: "DEMO 2",
                //      key: "demo",
                //      link: "/demo-2"
                //   })
                // );

                if (!optionMenu) {
                  let arr = [];
                  for (let i = 0; i < this.listMenu.length; i++) {
                    const element = this.listMenu[i];
                    arr[element.key] = element.active;
                    if (element.childs.length > 0) {
                      for (let j = 0; j < element.childs.length; j++) {
                        const sub = element.childs[j];
                        arr[sub.key] = sub.active;
                      }
                    }
                  }
                  this.menuConfig = arr;
                }
              })
              .catch();
          });
      })
      .catch(() => {});
  }

  checkActiveMenu(key) {
    if (this.menuConfig) {
      return this.menuConfig[key] == true;
    }
    return false;
  }

  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;

  outMainMenu(mainMenu: NgbDropdown) {
    // this.isMenuCollapsed = true;
    mainMenu.close();
  }

  menuLeave(mainMenu) {
    this.isMatMenuOpen = false;
    setTimeout(() => {
      if (!this.isMatMenuOpen && !this.enteredButton) {
        mainMenu.close();
      }
    }, 80);
  }

  menuEnter(mainMenu: NgbDropdown) {
    if (!this.isMatMenuOpen) {
      mainMenu.open();
    }
    this.isMatMenuOpen = true;
  }
  buttonMenuEnter(mainMenu: NgbDropdown) {
    this.isMatMenuOpen = true;
    this.enteredButton = true;
    mainMenu.open();
  }
  buttonMenuLeave(mainMenu) {
    this.isMatMenuOpen = false;
    this.enteredButton = false;
    setTimeout(() => {
      if (!this.isMatMenuOpen) {
        mainMenu.close();
      }
    }, 100);
  }

  onEvent(event: { cancelBubble: boolean }) {
    event.cancelBubble = true;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  onFocusSearchMobie() {
    this.isShowResultSearchMobie = true;
    this.getDataSearch();
  }
  onBlurSearchMobie() {
    //this.querySearch.search = "";
    setTimeout(() => {
      this.isShowResultSearchMobie = false;
    }, 150);
  }
  toggleSearchMobie() {
    this.isShowResultSearchMobie = !this.isShowResultSearchMobie;
  }

  onFocusSearch() {
    this.isShowResultSearch = true;
    this.getDataSearch();
  }
  onBlurSearch() {
    //this.querySearch.search = "";
    setTimeout(() => {
      this.isShowResultSearch = false;
    }, 150);
  }

  onEnter() {
    this.document.location.href = '/tim-kiem?q=' + this.querySearch.search;
  }

  getDataSearch() {
    this.searchDTO.isLoading = true;
    this.searchDTO.isNoData = true;
    this.blockService.load('search-box');
    this.searchService
      .search(this.querySearch)
      .then((res: ResponeResult) => {
        if (res.isOk) {
          this.searchDTO.setData(res.repData);
          this.searchDTO.isLoading = false;
          this.searchDTO.isNoData =
            this.searchDTO.doctors.length == 0 &&
            this.searchDTO.packages.length == 0 &&
            this.searchDTO.news.length == 0;
        }
      })
      .catch()
      .finally(() => {
        this.blockService.unload('search-box');
      });
  }

  timerSearch = null;
  doSearch() {
    clearTimeout(this.timerSearch);
    this.timerSearch = setTimeout(() => {
      this.getDataSearch();
    }, 500);
  }
}
