import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Contact } from '@shared/models/contact.model';
import { BlockService } from '@shared/module/block/services/block.service';
import { ModalService } from '@shared/module/modal';
import { TranslatesService } from '@shared/module/translate';
import { ContactService } from '@shared/services/contact.service';
import { environment } from 'src/environments/environment';
import { CartItem } from '../../models/cart.model';
import { CartService } from '../../services/cart.service';

declare var bootstrap;

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
})
export class AddToCartComponent implements OnInit {
  reqData: Contact = new Contact();
  isLoading = false;
  mobNumberPattern = '^((\\+84-?)|0)?[0-9]{0,50}$';
  emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';

  constructor(
    public cartService: CartService,
    private modalService: ModalService,
    private translate: TranslatesService,
    private contactService: ContactService,
    private blockService: BlockService
  ) {}

  @Input('product') product: CartItem;

  ngOnInit(): void {}

  addToCart() {
    var rowPackage = new CartItem(this.product);
    this.cartService.add(rowPackage);
    this.cartService.openMenuItem();
  }

  addQuantity(step = 1) {
    this.product.quantity += step;
    this.changeQuantity();
  }
  changeQuantity() {
    if (this.product.quantity < 1) this.product.quantity = 1;
  }

  public static(file = '') {
    return environment.STATIC_URL + file;
  }

  resetData() {
    this.reqData = new Contact();
    this.reqData.status = 1;
    this.reqData.createdDate = new Date();
    this.reqData.contactType = 3;
  }

  onSubmit(frm: NgForm) {
    if (frm.invalid) {
      return;
    } else {
      this.isLoading = true;
      this.blockService.load('contact');
      this.reqData.contactType = 3;
      this.reqData.createdDate = new Date();
      this.reqData.subject = this.product.packageName;
      this.reqData.packageId = this.product.packageId;
      this.reqData.trackingNotes = 'WEBSITE';
      this.contactService
        .addPackageContact({
          rowData: this.reqData,
          linkCode: this.product.linkCode,
        })
        .then((res) => {
          this.closeBootstrapModal();
          this.openModal('custom-modal-1');
          frm.onReset();
        })
        .catch(() => {})
        .finally(() => {
          frm.onReset();
          this.blockService.unload('contact');
          this.isLoading = false;
        });
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  closeBootstrapModal() {
    var myModalEl = document.getElementById('modalConsultation');
    var modal = bootstrap.Modal.getInstance(myModalEl);
    if (modal) modal.hide();
  }
}
