export class ComplainTypes{
   
    CheckListComplainTypeID:number = 0 //danh sách vấn đề của khách
    CheckListComplainTypeName:string = "";
    checked:boolean = false;
    
    constructor(id:number, name:string ="")
    {
        this.CheckListComplainTypeID = id;
        this.CheckListComplainTypeName = name;
        
    }
}