import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { ResponeResult } from './models/ResponeResult';
import { NotiService } from '../shared/module/notification';
import { ServiceLocator } from '@app/common/locator.service';
import { DialogService } from '@shared/module/dialog';
import { ButtonDialog, ButtonType } from '@shared/module/dialog/dialog-config';
import { Account } from '@shared/models/account.model';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { throwError } from 'rxjs';

export class BaseService {
  private REST_API_SERVER = environment.API_URL;
  private header: HttpHeaders;
  private httpClient: HttpClient;
  private notiService: NotiService;
  private dialogService: DialogService;
  public progress: number = 0;
  public isErrorHandle: boolean = true;

  public isBrowser: boolean;

  public user: Account;
  public isShowError: boolean = false;
  public isCache = true;

  constructor() {
    this.httpClient = ServiceLocator.injector.get(HttpClient);
    this.notiService = ServiceLocator.injector.get(NotiService);
    this.dialogService = ServiceLocator.injector.get(DialogService);
    this.header = new HttpHeaders().set('Content-type', 'application/json');
    this.isBrowser = isPlatformBrowser(
      ServiceLocator.injector.get(PLATFORM_ID)
    );
  }

  public setNoCache() {
    this.isCache = false;
    return this;
  }

  public valid() {
    this.isErrorHandle = true;
    return this;
  }

  public noValid() {
    this.isErrorHandle = false;
    return this;
  }

  public sendGetRequest() {
    return this.httpClient.get(this.REST_API_SERVER);
  }

  public get(path: string = '', params: any = {}) {
    let paramX = this.isCache ? params : { ...params, t: new Date().getTime() };
    this.isCache = false;
    return new Promise((resolve, reject) => {
      try {
        this.httpClient
          .get(this.REST_API_SERVER + path, {
            params: paramX,
            headers: this.header,
          })
          .pipe(
            catchError((error) => {
              console.log('1ERGET', error, path);
              this.handleError(error, reject);
              return throwError(error);
            })
          )
          .subscribe(
            (res: ResponeResult) => {
              if (res.isOk == false) {
                console.log('call', path, params);
              }
              return this.handleRespons(res, resolve, reject);
            },
            (error) => {
              console.log('2ERGET', error, path);
              this.handleError(error, reject);
            }
          );
      } catch (error) {
        console.log(error, path);
      }
    });
  }

  public post(path: string = '', body) {
    let url = '';
    if (path.includes('http')) {
      url = path;
    } else {
      url = this.REST_API_SERVER + path;
    }
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(url, body, { headers: this.header })
        .pipe(retry(2))
        .subscribe(
          (res: ResponeResult) => {
            return this.handleRespons(res, resolve, reject);
          },
          (error) => {
            console.log('POST', error, path);
            this.handleError(error, reject);
          }
        );
    });
  }

  public postFormData(path, body) {
    let formData = new FormData();
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });

    this.header = new HttpHeaders().set('Content-type', 'multipart/form-data');
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.REST_API_SERVER + path, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .pipe(retry(2))
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
            } else if (event.type === HttpEventType.Response) {
              let res = event.body;
              resolve(res);
            }
          },
          (error) => {
            console.log('postFormData', error, path);
            this.handleError(error, reject);
          }
        );
    });
  }

  handleRespons(res: ResponeResult, resolve, reject) {
    try {
      // if(res instanceof(ResponeResult))
      if ('isOk' in res && 'repData' in res) {
        this.user = res.user;
        if (this.user && this.user.role) {
          if (this.isBrowser) {
            localStorage.setItem('role', this.user.role);
          }
        }

        if (res.isOk) {
          resolve(res);
        } else {
          console.log('handleRespons', res);
          this.handleError(res, reject);
        }
      } else {
        resolve(res);
        console.error('Sai cấu trúc trả về nhé');
        console.log(res);
        console.log('================================');
      }
    } catch (error) {
      console.log('handleRespons', error);
      this.handleError(error, reject);
    }
  }

  countErrorLog: number = 0;

  handleError(res, reject) {
    if (this.isErrorHandle) {
      if (res instanceof Object && 'isOk' in res) {
        this.notiService.error(res.messageText);
        if (!this.isShowError) {
          this.isShowError = true;
          this.countErrorLog += 1;
          console.log('Số lần show Error', this.countErrorLog);
          this.dialogService
            .alert({
              message: res.messageText,
            })
            .result.then((res: ButtonDialog) => {
              this.isShowError = false;
              if (res.type == ButtonType.isOk) {
              }
            });
        }
        console.error('Lỗi dữ từ server:', res.messageError);
        console.log('================================');
        reject('Lỗi dữ từ server: ' + res.messageText);
      } else {
        this.notiService.error('Lỗi kết nối: ' + res);
        if (!this.isShowError) {
          this.isShowError = true;
          this.countErrorLog += 1;
          console.log('Số lần show Error', this.countErrorLog);
          // this.dialogService.alert({
          //   message:"Lỗi kết nối với server : "+ res
          // }).result.then((res : ButtonDialog)=>{
          //   this.isShowError = false;
          // });;
        }
        reject('Lỗi kết nối với server : ' + res);
      }
    } else {
      this.valid();
      reject(res);
    }
  }
}
