import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from "@core/BaseComponent";
import { ActivatedRoute } from '@angular/router';
import { DocterService } from "@shared/services/doctor.service";
import { ResponeResult } from "@core/models/ResponeResult";
import { TranslatesService } from '@shared/module/translate';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(700, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(700, style({ opacity: 0 }))
      ])
    ])
  ]
})

export class DoctorComponent extends BaseComponent {
  public departmentName: string | any;
  public doctorList: DoctorList = new DoctorList;
  public viewMobile: boolean = false;
  public urlLink: string | any;
  private linkCode: string | any;


  constructor(
    private translate: TranslatesService,
    public route: ActivatedRoute,
    private docterService: DocterService) {
    super();
    let self = this;
    this.route.paramMap.subscribe(params => {
      this.linkCode = self.route.snapshot.paramMap.get("id");
      this.getData();
    });
  }

  ngOnInit() {
   if(this.isBrowser)
   {
    if (window.screen.width <= 812) {
      this.viewMobile = true;
    }
   }
    this.seo.title = "Đội ngũ bác sĩ";
    this.updateSEO();
  }

  ngAfterViewInit(){
    this.initLightbox();
  }

  getData() {
    this.docterService.noValid().getData({ linkCode: this.linkCode }).then((result: ResponeResult) => {
      if (result.isOk) {
        this.doctorList = result.repData;
        if (this.doctorList != null && this.doctorList.doctors.length > 0) {
          this.departmentName = this.doctorList.doctors[0].departmentName;
          this.translate.onLoad().then(() => {

            this.seo.title = this.translate.instant("menu.doctor") + " - " + this.departmentName;
            this.updateSEO();
          });
        }
        else {
          //Gõ sai url param thì load default khoa nội
          this.router.navigate(['doi-ngu-bac-si/noi-khoa']);
        }
      }
    }).catch((err) => {
      this.router.navigate(['/page-not-found']);
    });
  }
}

export class DoctorList {
  doctors: Doctors[] = [];
  news: News[] = [];
  doctorsRelated: DoctorsRelated[] = [];
}

export class Doctors {
  linkCode: string | any;
  linkDepartment: string | any;
  imageSmall: string | any;
  imagePath: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  levelName: string | any;
  doctorName: string | any;
  positionTitle: string | any;
  departmentTitle: string | any;
  diplomaTitle: string | any;
  literacy: string | any;
  shortDescription: string | any;
  departmentName: string | any;
  departmentId: number | any;

}

export class DoctorsRelated {
  linkCode: string | any;
  linkDepartment: string | any;
  imageSmall: string | any;
  imagePath: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  levelName: string | any;
  doctorName: string | any;
  positionTitle: string | any;
  departmentTitle: string | any;
  diplomaTitle: string | any;
  literacy: string | any;
  shortDescription: string | any;
  departmentName: string | any;
  departmentId: number | any;

}

export class News {
  linkCode: string | any;
  linkCategory: string | any;
  titleName: string | any;
  shortDescription: string | any;
  tags: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  viewNumber: number | any;
  newsDate: Date | any;
}
