<link rel="stylesheet" href="https://sandbox.megapay.vn:2810/pg_was/css/payment/layer/paymentClient.css" />
<!-- titile -->
<div class="container-fluid">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ 'pay.title' | translate }}</h1>
    </div>
  </div>
</div>
<app-block id="block-pay">
  <!-- stepper -->
  <div class="container">
    <div class="stepper">
      <ul class="progressbar">
        <li class="step_one" [ngClass]="{ finish: cartService.value.step > 1, active: cartService.value.tabselect == 1 }" (click)="setStep(1)">
          <p>{{ 'pay.order_information' | translate }}</p>
        </li>
        <li class="step_two" [ngClass]="{ finish: cartService.value.step > 2, active: cartService.value.tabselect == 2 }" (click)="setStep(2)">
          <p>{{ 'pay.payment' | translate }}</p>
        </li>
        <li [ngClass]="{ finish: cartService.value.step > 3, active: cartService.value.tabselect == 3 }" (click)="setStep(3)">
          <p>{{ 'pay.checkout' | translate }}</p>
        </li>
      </ul>
    </div>
  </div>

  <form #frmCustomerInfo="ngForm" (ngSubmit)="onSubmitCustomer(frmCustomerInfo)" *ngIf="cartService.value.step == 1">
    <div class="title-mobile">
      <h5>thông tin đơn hàng</h5>
    </div>
    <div class="container">
      <div class="row">
        <app-input
          class="col-md-4"
          minlength="3"
          required
          [(ngModel)]="cartService.value.customer.customerName"
          name="customerName"
          label="{{ 'form.label.fullname' | translate }}"
          [name-control]="'form.name.fullname' | translate"
          placeholder="VD : Nguyen Van A"
        ></app-input>
        <div class="form-group col-md-4">
          <label for="exampleInputEmail1">{{ 'form.label.birthday' | translate }}</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="dd/mm/yyyy"
              #c2="ngModel"
              name="customerBirthday"
              [(ngModel)]="cartService.value.customer.customerBirthday"
              ngbDatepicker
              #d="ngbDatepicker"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" style="padding: 7px 11px" (click)="d.toggle()" type="button">
                <i class="fa-solid fa-calendar-days fa-1x"></i>
              </button>
            </div>
          </div>
        </div>
        <app-input
          class="col-md-4"
          minlength="3"
          required
          [(ngModel)]="cartService.value.customer.customerPhone"
          name="customerPhone"
          label="{{ 'form.label.phone' | translate }}"
          [name-control]="'form.name.phone' | translate"
          placeholder="VD : 090 123 456"
        ></app-input>
      </div>
      <div class="row">
        <app-input
          class="col-md-4"
          [(ngModel)]="cartService.value.customer.customerEmail"
          name="customerEmail"
          pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
          [name-control]="'form.name.email' | translate"
          [label]="'form.label.email' | translate"
          placeholder="VD : ten@gmail.com"
        ></app-input>
        <app-input
          class="col-md-8"
          minlength="5"
          required
          [(ngModel)]="cartService.value.customer.customerAddress"
          name="customerAddress"
          [name-control]="'form.name.address' | translate"
          [label]="'form.label.address' | translate"
          placeholder="VD : 123 Nguyễn Huệ, Quận 1, Hồ Chí Minh"
        ></app-input>
      </div>
      <div class="row">
        <div class="col-md-4 select-store">
          <app-input
            class="mb-0"
            type="select"
            required
            [list]="stores"
            [(ngModel)]="cartService.value.storeId"
            required
            name="storeId"
            [name-control]="'form.name.store' | translate"
            [label]="'form.label.store' | translate"
            [defaultOption]="customOption"
            key="storeId"
          >
            <ng-template #customOption let-item>
              {{ item.storeName }}
            </ng-template>
          </app-input>
          <span class="text-danger">{{ 'form.instructions.store' | translate }}</span>
        </div>
      </div>
      <div class="row">
        <app-input
          class="col-md-12"
          type="textarea"
          [(ngModel)]="cartService.value.note"
          name="note"
          [name-control]="'form.name.note' | translate"
          [label]="'form.label.note' | translate"
        ></app-input>
      </div>
      <div class="d-flex justify-content-center mb-4 mt-4">
        <button type="button" class="btn btn-secondary me-3" [routerLink]="['/gio-hang']">
          <i class="fa-solid fa-cart-plus me-2"></i>
          <span>{{ 'cart.label' | translate }}</span>
        </button>
        <button type="submit" class="btn btn-primary">
          {{ 'cart.next' | translate }}
          <i class="fa-solid fa-arrow-right ms-2"></i>
        </button>
      </div>
    </div>
  </form>

  <!-- thanh toán -->
  <div class="pay" *ngIf="cartService.value.step == 2">
    <div class="title-mobile">
      <h5>{{ 'pay.payment' | translate }}</h5>
    </div>
    <div class="package pb-0">
      <div class="container">
        <!-- đơn hàng -->
        <div class="shopping">
          <div class="row">
            <div class="col-md-3">
              <div class="titlecircle">
                <span class="circle">1</span>
                <h6 class="text-uppercase">{{ 'pay.order_information' | translate }}</h6>
              </div>
            </div>
            <div class="col-md-3 prevent pt-1">
              <h6>{{ 'cart.table.package.name' | translate }}</h6>
            </div>
            <div class="col-md-2 prevent pt-1 text-center">
              <h6>{{ 'cart.table.price' | translate }}</h6>
            </div>
            <div class="col-md-2 prevent pt-1 pe-0 text-center">
              <h6>{{ 'cart.table.quantity' | translate }}</h6>
            </div>
            <div class="col-md-2 prevent pt-1 ps-0 text-center">
              <h6>{{ 'cart.table.amount' | translate }}</h6>
            </div>
          </div>
        </div>

        <!-- chi tiết đơn hàng  -->
        <div class="packages-bg pt-3">
          <div class="packagepay" *ngFor="let row of cartService.value.cartItems">
            <div class="row">
              <div class="col-md-6 d-flex">
                <div class="col-5 col-sm-3 col-md-3 p-0">
                  <div class="discount">
                    <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" [alt]="row.packageName" />
                  </div>
                  <div class="image">
                    <img [src]="static(row.imagePath)" onError="this.src='/assets/images/thumb.png'" />
                  </div>
                </div>
                <div class="col-7 col-sm-9 col-md-9 pe-0 ps-2">
                  <div class="name pe-2">
                    <h6>{{ row.packageName }}</h6>
                    <div class="row applydate prevent">
                      <span *ngIf="row.isApplyDate" class="timeDate">
                        <i class="far fa-calendar-alt pe-2"></i>Áp dụng: {{ row.fromDate | date : 'dd/MM/yyyy' }} -
                        {{ row.toDate | date : 'dd/MM/yyyy' }}</span
                      >
                      <p [innerHTML]="row.shortDescription" class="px-3 desc-package"></p>
                    </div>
                  </div>
                  <div class="mobile_n mt-2">
                    <h6 class="float-left">{{ 'cart.table.quantity' | translate }}</h6>
                    <h6 class="float-right font-weight-bold">{{ row.quantity | number : 0 }}</h6>
                  </div>
                  <div class="clearfix"></div>
                  <div class="mobile mt-2">
                    <h6 class="float-left">{{ 'cart.table.price' | translate }}</h6>
                    <h6 class="float-right font-weight-bold">{{ row.salePrice | number : 0 }}</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-2 sale prevent">
                <h6>{{ row.salePrice | number : 0 }}</h6>
              </div>
              <div class="col-md-2 sale-number prevent">
                <h6>{{ row.quantity | number : 0 }}</h6>
              </div>
              <div class="col-md-2 sale prevent">
                <h6>{{ row.totalAmount | number : 0 }}</h6>
              </div>
            </div>
          </div>
          <!-- tổng tiền -->
          <div class="totalpay">
            <div class="row">
              <div class="d-none d-sm-none d-md-block d-lg-block col-md-7"></div>
              <div class="col-6 col-md-3">
                <p class="font-weight-bold m-0">{{ 'cart.summary.total' | translate }}:</p>
              </div>
              <div class="col-6 col-md-2 totalsum">
                <h5>{{ cartService.value.summary.totalAmount | number : 0 }}₫</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- hình thức thanh toán -->

      <div class="payment">
        <div class="container">
          <div class="titlecircle">
            <span class="circle">2</span>
            <h6 class="text-uppercase">{{ 'pay.payments' | translate }}</h6>
          </div>
          <div class="row payment-list">
            <div class="col-md-{{ 12 / listPayment.length }} mb-2" *ngFor="let item of listPayment">
              <a
                class="btn btn-outline-info payment-item btn-block"
                role="button"
                [ngClass]="{ active: cartService.value.paymentType == item.id }"
                (click)="changePaymentType(item.id)"
              >
                <div class="payment-item-header">
                  <button class="btn btn-outline-primary btn-check" (click)="changePaymentType(item.id)">
                    <i class="fa-solid fa-check" *ngIf="cartService.value.paymentType == item.id"></i>
                  </button>
                  <strong class="text-danger">{{ item.title }}</strong>
                </div>
                <div class="payment-item-body">
                  <p [innerHTML]="item.description"></p>
                </div>
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4 mb-4">
            <button type="button" class="btn btn-secondary me-3" (click)="backStep()">
              <i class="fa-solid fa-chevron-left me-2"></i>
              <span>{{ 'cart.back' | translate }}</span>
            </button>
            <button type="button" class="btn btn-primary me-3" (click)="nextStep()">
              <span>{{ 'cart.next' | translate }}</span>
              <i class="fa-solid fa-chevron-right ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mua -->
  <form #frmCheckout="ngForm" (ngSubmit)="onSubmitCheckout(frmCheckout)" *ngIf="cartService.value.step == 3">
    <div class="summary">
      <div class="title-mobile">
        <h5>{{ 'pay.checkout' | translate }}</h5>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-4 pb-4">
            <div class="titlecircle3">
              <span class="circle">1</span>
              <h6>{{ 'pay.payment_gateway.terms' | translate }}</h6>
            </div>
            <div class="note">
              <div class="titlecircle1a">
                <span class="circle">A</span>
                <h6>{{ 'pay.payment_gateway.purpose' | translate }}</h6>
              </div>
              <p>
                {{ 'pay.payment_gateway.purpose_des' | translate }}
              </p>
            </div>
            <div class="note">
              <div class="titlecircle1a">
                <span class="circle">B</span>
                <h6>{{ 'pay.payment_gateway.scope' | translate }}</h6>
              </div>
              <ul>
                <li>
                  <i class="fas fa-check-circle text-info pe-2"></i>
                  <p>{{ 'pay.payment_gateway.scope_des_1' | translate }}</p>
                </li>
                <li>
                  <i class="fas fa-check-circle text-info pe-2"></i>
                  <p>{{ 'pay.payment_gateway.scope_des_2' | translate }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 pb-4">
            <div class="titlecircle3">
              <span class="circle">2</span>
              <h6 class="text-uppercase">{{ 'pay.order_information' | translate }}</h6>
            </div>
            <div class="products-list">
              <div class="product-item" *ngFor="let item of cartService.value.cartItems">
                <div style="width: 100%" class="p-0">
                  <a [routerLink]="['/goi-kham', item.categoryCode, item.linkCode]">
                    <span>{{ item.packageName }}</span>
                  </a>
                </div>
                <div style="width: 50px" class="text-end p-0 m-auto">
                  <p>
                    <span>x</span>
                    <span>{{ item.quantity | number : 0 }}</span>
                  </p>
                </div>
                <div style="width: 150px" class="text-end p-0 m-auto">
                  <p>{{ item.totalAmount | number : 0 }}₫</p>
                </div>
              </div>
            </div>
            <div class="pay2">
              <div class="col-md-6 p-0">
                <h6>{{ 'cart.summary.total' | translate }} :</h6>
              </div>
              <div class="col-md-6 p-0">
                <h5>{{ cartService.value.summary.totalAmount | number : 0 }} đ</h5>
              </div>
            </div>
            <div class="bank-info mt-5" *ngIf="cartService.value.paymentType == 2">
              <div class="titlecircle3">
                <span class="circle">*</span>
                <h6 class="text-uppercase">{{ 'pay.bank_info.title' | translate }}</h6>
              </div>
              <!-- <ul class="content-list">
                <li>{{'pay.bank_info.account_holder' | translate}} : SIM MEDICAL CENTER – CHI NHÁNH CÔNG TY CỔ PHẦN SIM MED</li>
                <li>{{'pay.bank_info.account_number' | translate}} : 19134144243010</li>
                <li>{{'pay.bank_info.bank' | translate}} : {{'pay.bank_info.branch' | translate}}</li>
              </ul> -->

              <table class="table">
                <tr>
                  <td style="min-width: 80px">{{ 'pay.bank_info.account_holder' | translate }}</td>
                  <td>SIM MEDICAL CENTER – CHI NHÁNH CÔNG TY CỔ PHẦN SIM MED</td>
                </tr>
                <tr>
                  <td>{{ 'pay.bank_info.account_number' | translate }}</td>
                  <td>19134144243010</td>
                </tr>
                <tr>
                  <td>{{ 'pay.bank_info.bank' | translate }}</td>
                  <td>{{ 'pay.bank_info.branch' | translate }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col-md-4 pb-4">
            <div class="note3">
              <div class="titlecircle3">
                <span class="circle">3</span>
                <h6 class="text-uppercase">{{ 'pay.payment_gateway.customer_information' | translate }}</h6>
              </div>
              <p class="name text-uppercase">
                {{ cartService.value.customer.customerName }}
              </p>
              <ul>
                <li>
                  <i class="fas fa-check-circle text-info"></i>
                  <p>
                    <strong>{{ 'form.name.address' | translate }} : </strong>
                    <span>{{ cartService.value.customer.customerAddress }}</span>
                  </p>
                </li>
                <li>
                  <i class="fas fa-check-circle text-info"></i>
                  <p>
                    <strong>{{ 'form.name.email' | translate }} : </strong>
                    <span>{{ cartService.value.customer.customerEmail }}</span>
                  </p>
                </li>
                <li>
                  <i class="fas fa-check-circle text-info"></i>
                  <p>
                    <strong>{{ 'form.name.phone' | translate }} : </strong>
                    <span>{{ cartService.value.customer.customerPhone }}</span>
                  </p>
                </li>
              </ul>
            </div>
            <div class="note3">
              <div class="titlecircle3">
                <span class="circle">4</span>
                <h6 class="text-uppercase">{{ 'pay.payments' | translate }}</h6>
              </div>
              <p class="name">
                {{ getPaymentItem(cartService.value.paymentType)?.title }}
              </p>
            </div>
            <div class="note3">
              <div class="titlecircle3">
                <span class="circle">5</span>
                <h6 class="text-uppercase">{{ 'pay.location' | translate }}</h6>
              </div>
              <div class="mt-2">
                <app-input
                  class="mb-0"
                  type="select"
                  required
                  [list]="stores"
                  [(ngModel)]="cartService.value.storeId"
                  required
                  name="storeId"
                  name-control="Phòng khám"
                  [defaultOption]="customOption"
                  key="storeId"
                >
                  <ng-template #customOption let-item>
                    {{ item.storeName }}
                  </ng-template>
                </app-input>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center text-center policy">
          <div class="form-group form-check" [ngClass]="{ 'is-invalid': cbPolicy.invalid && frmCheckout.submitted }">
            <input
              required
              ngModel
              name="policy"
              type="checkbox"
              #cbPolicy="ngModel"
              class="form-check-input"
              id="checkPolicy"
              [ngClass]="{ 'is-invalid': cbPolicy.invalid && frmCheckout.submitted }"
            />
            <label class="form-check-label" for="checkPolicy">{{ 'pay.payment_gateway.approve_policy' | translate }}</label>
            <br />
            <a href="/chinh-sach-thanh-toan-hoan-tien">{{ 'menu.faq.refund_policy' | translate }}</a>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-4 mt-4">
          <button type="button" class="btn btn-secondary me-3" (click)="backStep()">
            <i class="fa-solid fa-chevron-left me-2"></i>
            <span>{{ 'cart.back' | translate }}</span>
          </button>
          <button type="submit" class="btn btn-success">
            {{ 'pay.checkout' | translate }}
            <i class="fa-solid fa-credit-card ms-2"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
  <!-- popup -->
  <ng-template #paypay let-modal>
    <div class="modal-body paypay-success">
      <span class="fas fa-check-circle"></span>
      <h5 class="title1">{{ 'popupMessage.booking.titleSucessfull' | translate }}</h5>
      <div class="title2">
        <p>{{ 'popupMessage.booking.msgContent' | translate }}</p>
        <h6>{{ 'popupMessage.booking.msgWaring' | translate }}</h6>
        <a href="tel:1900252535" title="Call: 1900 25 25 35">1900 25 25 35</a>
      </div>
      <button class="btn-close" [routerLink]="['/']" (click)="modal.close()"><i class="fas fa-times"></i></button>
      <button class="btn btn-secondary mt-3 me-2" (click)="completeOrder(false, modal)" [routerLink]="['/']">
        <i class="fa-solid fa-house me-2"></i>
        Về trang chủ
      </button>
      <button class="btn btn-primary mt-3" (click)="completeOrder(false, modal)" [routerLink]="['/goi-kham']">
        Tiếp tục mua gói khám
        <i class="fa-solid fa-cart-plus ms-2"></i>
      </button>
    </div>
  </ng-template>

  <!-- popup -->
  <ng-template #payMethod let-modal>
    <div class="modal-header">
      <div class="d-flex flex-column align-items-center w-100">
        <img class="logo" src="/assets/images/icons/ic_bank.svg" alt="ic_bank" srcset="" />
        <strong class="mt--2">Vui lòng chọn hình thức thanh toán !</strong>
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modal.close(false)"
        aria-label="Close"
        style="position: absolute; right: 10px; background: transparent; top: 13px"
      >
        <span aria-hidden="true">
          <img src="/assets/images/icons/ic_x.svg" alt="ic_x" srcset="" />
        </span>
        <!-- &times; -->
      </button>
    </div>
    <div class="modal-body payonline py-md-3 px-md-5">
      <div class="row g-2 justify-content-center">
        <div class="col-12 col-sm-6 p-2" *ngFor="let item of listPayOnline">
          <div class="card text-center payonline-item" [ngClass]="{ active: item.checked }" (click)="doChooseOnlineMethod(item)">
            <button class="btn-check">
              <i *ngIf="item.checked" class="far fa-check-circle" aria-hidden="true"></i>
              <!-- <i *ngIf="!item.checked"  class="far fa-check-circle" aria-hidden="true"></i> -->
            </button>
            <img class="card-img-top" [src]="item.logo" [alt]="item.name" />
            <div class="pb-2">
              <p class="card-text">{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <button class="btn btn-lg btn-primary my-3" (click)="modal.close(true)" [disabled]="!payOnlineSelected?.checked">Thanh toán ngay</button>
      </div>
    </div>
  </ng-template>
</app-block>

<form id="megapayForm" name="megapayForm" method="POST">
  <input type="hidden" name="invoiceNo" [value]="megaPay.invoiceNo" />
  <input type="hidden" name="amount" [value]="megaPay.amount" />
  <input type="hidden" name="currency" [value]="megaPay.currency" />
  <input type="hidden" name="goodsNm" [value]="megaPay.goodsNm" />
  <input type="hidden" name="buyerPhone" value="" />
  <input type="hidden" name="buyerAddr" value="" />
  <input type="hidden" name="buyerCity" value="" />
  <input type="hidden" name="buyerState" value="" />
  <input type="hidden" name="buyerPostCd" value="" />
  <input type="hidden" name="buyerCountry" />
  <input type="hidden" name="fee" value="0" />
  <input type="hidden" name="payType" value="NO" />

  <!-- Delivery Info -->
  <input type="hidden" name="receiverFirstNm" value="" />
  <input type="hidden" name="receiverLastNm" value="" />
  <input type="hidden" name="receiverPhone" value="" />
  <input type="hidden" name="receiverAddr" value="" />
  <input type="hidden" name="receiverCity" value="" />
  <input type="hidden" name="receiverState" value="" />
  <input type="hidden" name="receiverPostCd" value="" />
  <input type="hidden" name="receiverCountry" value="VN" />
  <input type="hidden" name="description" [value]="megaPay.description" />
  <!------------------------------- Main Value ------------------------------>
  <!-- Call Back URL -->
  <input type="hidden" name="callBackUrl" value="http://localhost:4200/thanh-toan" />
  <!-- Notify URL -->
  <input type="hidden" name="notiUrl" [value]="megaPay.notiUrl" />
  <!-- Merchant ID -->
  <input type="hidden" name="merId" [value]="megaPay.merId" />
  <!-- Encode Key -->

  <input type="hidden" name="reqDomain" value="http://localhost:4200" />
  <input type="hidden" name="userLanguage" [value]="megaPay.userLanguage" />
  <input type="hidden" name="merchantToken" [value]="megaPay.token" />
  <input type="hidden" name="payToken" id="payToken" value="" />
  <input type="hidden" name="timeStamp" [value]="megaPay.timeStamp" />
  <input type="hidden" name="merTrxId" [value]="megaPay.merTrxId" />
  <input type="hidden" name="windowType" value="" />
  <input type="hidden" name="windowColor" value="#0B3B39" />
  <input type="hidden" name="userFee" id="userFee" value="0" />
  <input type="hidden" name="vaCondition" value="03" />
</form>
