export const environment = {
  production: true,
  // API_URL: 'http://localhost:1897/',
  // STATIC_URL: 'http://localhost:1897/',
  // hostUrl: 'http://192.168.20.7:385',

  // API_URL: "http://14.161.16.85:386/",
  // STATIC_URL: "http://14.161.16.85:386/",
  // hostUrl: 'http://14.161.16.85:385/',

  API_URL: 'https://api.simmed.vn/',
  STATIC_URL: 'https://api.simmed.vn/',
  hostUrl: 'http://simmed.vn/',

  hash: 'ab83a7dff817ec66f44442007202690a93763950',
  //API_URL: "http://14.161.16.85:386/",
  //STATIC_URL: "http://14.161.16.85:386/",
  //hostUrl: 'http://demo.simmed.vn/'
};
