import { Component, ViewEncapsulation, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/core/BaseComponent';
import { PackageService } from '@shared/services';
import { ResponeResult } from '@core/models/ResponeResult';
import { Package, PackageCategory } from '@shared/models/package.model';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PackageComponent extends BaseComponent {
  private urlRoot: string = '';
  public linkCode: string = '';
  public packages: Package[];
  public packageCategorys: PackageCategory[];
  public packageCategorySelected: PackageCategory;
  public isLoadding: boolean = true;

  constructor(
    private injector: Injector,
    private packageSevice: PackageService,
    private rou: ActivatedRoute
  ) {
    super(injector);
    this.route.paramMap.subscribe((params) => {
      this.linkCode = this.route.snapshot.paramMap.get('id') || '';
      this.getData();
    });
  }

  ngOnInit() {
    this.seo.title = 'Gói khám';
    this.updateSEO();
  }

  navigateTo(value: any) {
    if (value) {
      this.router.navigate(['/goi-kham', value]);
    }
    return false;
  }

  doChangePackageCategory() {
    if (this.packageCategorySelected) {
      this.router.navigate([
        '/goi-kham',
        this.packageCategorySelected.linkCode,
      ]);
    }
  }

  getData() {
    this.isLoadding = true;
    var pCategory = this.packageSevice
      .getListCategoryPackage()
      .then((res: ResponeResult) => {
        this.packageCategorys = res.repData;
        this.packageCategorys.unshift(new PackageCategory());
      })
      .catch();

    var pPackageList = this.packageSevice
      .getListWithCategory(this.linkCode)
      .then((res: ResponeResult) => {
        this.packages = res.repData.packages;
        this.packageCategorySelected = res.repData.packageCategory;
        if (this.packageCategorySelected) {
          this.seo.title = this.packageCategorySelected.categoryName;
          this.updateSEO();
        }

        if (this.linkCode != '' && !this.packageCategorySelected) {
          this.router.navigate(['/page-not-found']);
        }
      })
      .catch((err) => {
        {
        }
      });

    Promise.all([pCategory, pPackageList]).then((values) => {
      this.isLoadding = false;
      if (this.packageCategorySelected) {
        let packageCategoryId = this.packageCategorySelected.packageCategoryId;
        this.packageCategorySelected = this.packageCategorys.find(
          (x) => x.packageCategoryId == packageCategoryId
        );
      } else {
        this.packageCategorySelected = this.packageCategorys[0];
      }
    });
  }
}
