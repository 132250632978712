export class New {
  newsId: number | any;
  linkCode: string | any;
  newsDate: Date | any;
  departmentId: number | any;
  tags: string | any;
  titelSeonews: string | any;
  descriptionSeonews: string | any;
  thumbnailSeonews: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  categoryId: number | any;
  createdBy: number | any;
  createdDate: Date | any;
  modifyBy: number | any;
  modifyDate: Date | any;
  publishBy: number | any;
  publishDate: Date | any;
  viewNumber: number | any;
  isPublish: boolean;
  isHot: boolean;
  sortOrder: number | any;
  isActive: boolean = true;
  languageId: string | any;
  titleName: string | any;
  shortDescription: string | any;
  longDescription: string | any;
  imageSmall: string | any;
  imageLarge: string | any;
  searchString: string | any;
  departmentName: string | any;
  categoryName: string | any;
  notes: string | any;
  newsRelatedId: number | any;
}

export class NewsDTO {
  rowNew: News = new News();
  newsRelateds: NewsRelated[] = [];
}

export class News {
  linkCode: string | any;
  linkCategory: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  viewNumber: number | any;
  titleName: string | any;
  newsDate: Date | any;
  newsTitle: string | any;
  imageLarge: string | any;
  longDescription: string | any;
  categoryTitleName: string | any;
  categoryName: string | any;
  shortDescription: string | any;
  descriptionSeonews: string;
  titelSeonews: string;
  canonicalURL: string;
}

export class NewsRelated {
  linkCode: string | any;
  categoryCode: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  thumbnailSeonews: string | any;
  viewNumber: number | any;
  titleName: string | any;
  tags: string | any;
  imageSmall: string | any;
  shortDescription: string | any;
}
