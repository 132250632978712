import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class FrontendhGuard implements CanLoad {
  constructor(private auth: AuthService) {
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
      //this.auth.clearToken();
    return true;
  }

 

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //this.auth.clearToken();
    return true;
  }
}
