<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>
        {{ packageDTO.rowPackage?.packageName }}
      </h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item"><a [routerLink]="'/goi-kham'">Gói khám</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="'/goi-kham/' + packageDTO.rowPackage?.categoryCode">
              <span *ngIf="packageDTO.rowPackage?.categoryCode == 'tong-quat-dinh-ky'">Gói khám tổng quát</span>
              <span *ngIf="packageDTO.rowPackage?.categoryCode == 'san-phu-khoa'">Gói khám sản phụ khoa</span>
              <span *ngIf="packageDTO.rowPackage?.categoryCode == 'suc-khoe-nhi'">Gói khám sức khỏe nhi</span>
            </a>
          </li>
          <!-- <li class="breadcrumb-item">
            {{ packageDTO.rowPackage?.packageName }}
          </li> -->
        </ol>
      </nav>
    </div>
  </div>
</div>
<div class="packages-detail">
  <div class="container">
    <div class="detail">
      <div class="title-name">
        <h4 class="text-uppercase" [@fade]="'active'">{{ 'packageDetailPage.title' | translate }}</h4>
      </div>

      <div class="row" *ngIf="packageDTO && packageDTO.rowPackage">
        <div class="col-12">
          <div
            class="disccountText mb-4"
            *ngIf="packageDTO.rowPackage.isDisccount && packageDTO.rowPackage.disccountText && packageDTO.rowPackage.disccountText.length > 0"
          >
            <img class="icon" src="/assets/images/promotion.png" width="50px" alt="promotion" />
            <div class="text" [innerHTML]="packageDTO.rowPackage.disccountText"></div>
          </div>
        </div>
        <div class="col-lg-4 item-left mb-3" style="position: relative">
          <div class="discount">
            <img [@fade]="'active'" *ngIf="packageDTO.rowPackage.isHost" src="/assets/images/general/discount1.png" alt="discount" />
          </div>
          <div class="image" [@fade]="'active'">
            <img
              class="glightbox"
              [src]="static(packageDTO.rowPackage.imagePath)"
              alt="{{ packageDTO.rowPackage.imageSeodescription }}"
              onError="this.src='/assets/images/thumb.png'"
            />
          </div>
          <div class="d-block d-lg-none py-3">
            <div class="package-price">
              {{ 'packageDetailPage.salePrice' | translate }}:
              <span [@fade]="'active'" class="package-priceList" *ngIf="packageDTO.rowPackage.listPrice > packageDTO.rowPackage.salePrice">
                {{ packageDTO.rowPackage.listPrice | numberformat }}₫
              </span>
              <span [@fade]="'active'" class="package-priceSale"> {{ packageDTO.rowPackage.salePrice | numberformat }}₫ </span>
            </div>
            <app-add-to-cart *ngIf="isShowPay" [product]="packageDTO.rowPackage"></app-add-to-cart>
          </div>
        </div>
        <div class="col-lg-8 item-right">
          <div class="content">
            <div class="text-justify" [@fade]="'active'" [innerHTML]="packageDTO.rowPackage.longDescription | safeHtml"></div>
          </div>
          <div class="package-price">
            {{ 'packageDetailPage.salePrice' | translate }}:
            <span [@fade]="'active'" class="package-priceList" *ngIf="packageDTO.rowPackage.listPrice > packageDTO.rowPackage.salePrice">
              {{ packageDTO.rowPackage.listPrice | numberformat }}₫
            </span>
            <span [@fade]="'active'" class="package-priceSale"> {{ packageDTO.rowPackage.salePrice | numberformat }}₫ </span>
          </div>
          <app-add-to-cart *ngIf="isShowPay" [product]="packageDTO.rowPackage"></app-add-to-cart>
        </div>
      </div>
      <hr style="margin-bottom: 0" />
      <div class="serivce-package" *ngIf="packageDTO && packageDTO.rowPackage">
        <div class="row" *ngIf="packageDTO.rowPackage.groupServices && packageDTO.rowPackage.groupServices.length > 0">
          <div class="col-md-12" *ngFor="let item of packageDTO.rowPackage.groupServices">
            <div class="group">
              <h5 class="title-group" [@fade]="'active'">{{ item.groupName }}</h5>
              <div class="content" [@fade]="'active'">
                <div class="list-service" *ngFor="let service of item.services; let j = index">
                  <ng-container *ngIf="!service.isPackage">
                    <a class="service-title collapsed" data-bs-toggle="collapse" href="#serviceCllapse_{{ item.groupId }}_{{ j }}" role="button">
                      <i class="fas fa-arrow-circle-right"></i> {{ service.serviceName }}
                    </a>
                    <div class="collapse" id="serviceCllapse_{{ item.groupId }}_{{ j }}">
                      <div class="text-justify description" [innerHTML]="service.description"></div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="service.isPackage">
                    <a class="service-title collapsed" data-bs-toggle="collapse" href="#serviceCllapse_{{ item.groupId }}_{{ j }}" role="button">
                      <i class="fas fa-arrow-circle-right"></i> {{ service.serviceName }}
                    </a>
                    <div class="collapse" id="serviceCllapse_{{ item.groupId }}_{{ j }}">
                      <div class="text-justify" [innerHTML]="service.description | safeHtml"></div>
                      <ul style="list-style: none">
                        <li *ngFor="let detail of service.serviceDetails"><i class="fas fa-plus"></i> {{ detail.serviceName }}</li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="experience"
        *ngIf="
          packageDTO && packageDTO.rowPackage && (packageDTO.rowPackage.groupServices == null || packageDTO.rowPackage.groupServices.length == 0)
        "
      >
        <ul class="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center" id="myTab" role="tablist">
          <li *ngIf="packageDTO.rowPackage.doctorNotes != null" class="nav-item">
            <a
              id="tab-doctor"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="doctor"
              aria-selected="true"
              (click)="tabIndex = 1"
              class="nav-link text-uppercase font-weight-bold me-sm-3 rounded-0"
              [ngClass]="{ active: tabIndex == 1 }"
            >
              <h3><i class="fas fa-user-md"></i>{{ 'packageDetailPage.consultants' | translate }}</h3>
            </a>
          </li>
          <li *ngIf="packageDTO.rowPackage.ultrasoundNotes != null" class="nav-item">
            <a
              id="tab-ultrasound"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="ultrasound"
              (click)="tabIndex = 2"
              aria-selected="false"
              class="nav-link text-uppercase font-weight-bold me-sm-3 rounded-0"
              [ngClass]="{ active: tabIndex == 2 }"
            >
              <h3><i class="fas fa-x-ray"></i>{{ 'packageDetailPage.diagnostic' | translate }}</h3>
            </a>
          </li>
          <li *ngIf="packageDTO.rowPackage.labNotes != null" class="nav-item">
            <a
              id="tab-lab"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="lab"
              aria-selected="false"
              (click)="tabIndex = 3"
              class="nav-link text-uppercase font-weight-bold me-sm-3 rounded-0"
              [ngClass]="{ active: tabIndex == 3 }"
            >
              <h3><i class="fas fa-vial"></i>{{ 'packageDetailPage.test' | translate }}</h3>
            </a>
          </li>
          <li *ngIf="packageDTO.rowPackage.termconditionNotes != null" class="nav-item">
            <a
              id="tab-condition"
              data-bs-toggle="tab"
              role="tab"
              aria-controls="condition"
              (click)="tabIndex = 4"
              aria-selected="false"
              class="nav-link text-uppercase font-weight-bold me-sm-3 rounded-0"
              [ngClass]="{ active: tabIndex == 4 }"
            >
              <h3><i class="fas fa-clipboard-check"></i>{{ 'packageDetailPage.termcondition' | translate }}</h3>
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTab2Content">
          <div
            id="doctor"
            role="tabpanel"
            aria-labelledby="tab-doctor"
            class="tab-pane fade py-3"
            [ngClass]="{ 'show active': tabIndex == 1 }"
            [innerHTML]="packageDTO.rowPackage.doctorNotes | safeHtml"
          ></div>
          <div
            id="ultrasound"
            role="tabpanel"
            aria-labelledby="tab-ultrasound"
            class="tab-pane fade py-3 text-start"
            [ngClass]="{ 'show active': tabIndex == 2 }"
            [innerHTML]="packageDTO.rowPackage.ultrasoundNotes | safeHtml"
          ></div>
          <div
            id="lab"
            role="tabpanel"
            aria-labelledby="tab-lab"
            class="tab-pane fade py-3 text-start"
            [ngClass]="{ 'show active': tabIndex == 3 }"
            [innerHTML]="packageDTO.rowPackage.labNotes | safeHtml"
          ></div>
          <div
            id="condition"
            role="tabpanel"
            aria-labelledby="tab-condition"
            class="tab-pane fade py-3"
            [ngClass]="{ 'show active': tabIndex == 4 }"
            [innerHTML]="packageDTO.rowPackage.termconditionNotes | safeHtml"
          ></div>
        </div>
      </div>

      <div
        class="row accordion"
        id="accordionExample"
        *ngIf="
          packageDTO != null &&
          packageDTO.rowPackage != null &&
          (packageDTO.rowPackage.groupServices == null || packageDTO.rowPackage.groupServices.length == 0)
        "
      >
        <div class="card" *ngIf="packageDTO.rowPackage.doctorNotes != null">
          <div class="card-header">
            <button
              class="btn accordion-button collapsed"
              href="#collapseOne"
              type="button"
              data-bs-toggle="collapse"
              ata-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <i class="fas fa-user-md pe-2"></i>
              <h3 class="text-uppercase">{{ 'packageDetailPage.consultants' | translate }}</h3>
            </button>
          </div>
          <div class="accordion-collapse collapse show" id="collapseOne">
            <div class="card-body" [innerHTML]="packageDTO.rowPackage.doctorNotes | safeHtml"></div>
          </div>
        </div>
        <div class="card" *ngIf="packageDTO.rowPackage.ultrasoundNotes != null">
          <div class="card-header">
            <button
              class="btn accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <i class="fas fa-x-ray pe-2"></i>
              <h3 class="text-uppercase">{{ 'packageDetailPage.diagnostic' | translate }}</h3>
            </button>
          </div>
          <div class="accordion-collapse collapse" id="collapseTwo">
            <div class="card-body" [innerHTML]="packageDTO.rowPackage.ultrasoundNotes | safeHtml"></div>
          </div>
        </div>
        <div class="card" *ngIf="packageDTO.rowPackage.labNotes != null">
          <div class="card-header">
            <button
              class="btn accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="#collapseThree"
            >
              <i class="fas fa-syringe pe-2"></i>
              <h3 class="text-uppercase">{{ 'packageDetailPage.test' | translate }}</h3>
            </button>
          </div>
          <div class="collapse" id="collapseThree">
            <div class="card-body" [innerHTML]="packageDTO.rowPackage.labNotes | safeHtml"></div>
          </div>
        </div>
        <div class="card" *ngIf="packageDTO.rowPackage.termconditionNotes != null">
          <div class="card-header">
            <button
              class="btn accordion-button collapsed"
              href="#collapseFour"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="#collapseFour"
            >
              <i class="fas fa-file-medical pe-2"></i>
              <h3 class="text-uppercase">{{ 'packageDetailPage.termcondition' | translate }}</h3>
            </button>
          </div>
          <div class="collapse" id="collapseFour">
            <div class="card-body" [innerHTML]="packageDTO.rowPackage.termconditionNotes | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Gói khám liên quan -->
<div class="package py-4 bg-light card-block" *ngIf="packageDTO != null && packageDTO.packages != null">
  <div class="container">
    <h3 class="title-top">{{ 'packageDetailPage.packageList' | translate }}</h3>
    <div class="row" [dsMatchHeight]="'card'">
      <div class="col-md-12 col-lg-6 mb-4" *ngFor="let row of packageDTO.packages">
        <div class="category card">
          <div class="row">
            <div class="col-sm-6 col-md-5 col-image">
              <div class="discount">
                <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" alt="Alternate Text" />
              </div>
              <div class="image">
                <a [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                  <img [src]="static(row.imagePath)" alt="{{ row.imageSeotitle }}" onError="this.src='/assets/images/thumb.png'" />
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-md-7">
              <div class="title">
                <a [title]="row.packageName" class="link" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                  <span>{{ row.packageName }}</span>
                </a>
                <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                <div class="pt-2">
                  <div class="d-flex justify-content-start">
                    <span class="salePrice">{{ row.salePrice | number : 0 }}₫</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="emptydata" *ngIf="!packageDTO || packageDTO.packages?.length == 0">
        {{ 'servicePage.dataEmpty' | translate }}
      </div>
    </div>
  </div>
</div>
