<app-block id="media" (onInit)="onInit()">
  <div class="card border-primary" id="media-libray" [ngClass]="{'open':isNavOpen}">
    <div class="card-body px-1 py-0">
      <div class="album d-flex">
        <div class="sidebar">
          <div class="sidebar-content">
            <div class="sidebar-header d-flex p-2">
              <div ngbDropdown class="w-100 me-2">
                <button type="button" class="btn btn-primary btn-block w-100" ngbDropdownToggle>
                  <i class="fa-solid fa-plus"></i>
                  <span>Tạo mới</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button   type="button"ngbDropdownItem (click)="openModal(createFolder)">
                    <i class="fa-solid fa-folder-plus"></i>
                    Tạo mới Album
                  </button>
                  <button  type="button" ngbDropdownItem (click)="openUploader()">
                    <i class="fa-solid fa-cloud-arrow-up"></i>
                    Tải file lên
                  </button>
                </div>
              </div>
              <button  type="button" class="btn btn-close" (click)="toggleNav()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <ul class="list-tree">
              <li *ngFor="let item of album.data">
                <div class="d-flex pt-2">
                  <button  type="button" class="btn p-0 me-2">
                    <i class="fa-solid fa-square-plus"></i>
                  </button>
                  <button  type="button" (dblclick)="openAlbum(item)" class=" w-100 btn btn-block btn-outline-secondary text-start"
                    [ngClass]="{'seleced': item.selected}">
                    {{item.albumName}}
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="main">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li class="nav-item">
              <button  type="button" class="nav-link btn-open" (click)="toggleNav()">☰</button>
            </li>
            <li [ngbNavItem]="1">
              <a ngbNavLink>Upload</a>
              <ng-template ngbNavContent>
                <app-upload [album]="albumSelected" [aid]="albumId" (close)="doCloseUpload($event)"></app-upload>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink (click)="getMedia()">Media</a>
              <ng-template ngbNavContent>
                <div class="header">
                  <div class="header-top">
                    <div class="d-flex align-items-center">
                      <i class="fa-solid fa-photo-film fa-1x me-2"></i>
                      <strong class="m-0">Media Libray</strong>
                    </div>
                  </div>
                  <div class="header-bottom row mx-2">
                    <div class="col-md-8 col-lg-8 px-0">
                      <div class="align-items-center">
                        <div class="row mx-0">
                          <div class="col-12 d-block d-sm-none d-div"></div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-5 px-2">
                            <select class="form-control me-2" name="albumSelected" [(ngModel)]="albumSelected" style="padding: 3px 8px; font-size: 12px;"
                              (ngModelChange)="changeFilterAlbum($event)">
                              <option [ngValue]="null" selected="selected">Tất cả thư mục</option>
                              <option *ngFor="let item of album.data" [ngValue]="item">{{item.albumName}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-block d-md-none d-div"></div>
                    <div class="col-md-4 col-lg-4 px-3">
                      <div class="d-flex p-0">
                        <!-- <span style="width:100px" class="align-self-center">Tìm kiếm</span> -->
                        <input [(ngModel)]="queryObj.search" (ngModelChange)="searchChange()" type="text" style="padding: 3px 8px; font-size: 12px;"
                          class="form-control" name="" placeholder="Nhập tên muốn tìm ...">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body">
                  <div class="row g-2" *ngIf="albumDetail.data.length > 0 ;else  noDetail">
                    <div (click)="doChooseMedia(item)" class="col col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2"
                      *ngFor="let item of albumDetail.data; let i=index">
                      <div class="media-item" [ngClass]="{'item-select item-focus' : item.selected}">
                        <button  type="button" *ngIf="item.selected" class="btn btn-primary btn-check">
                          <i class="fa-solid fa-circle-check"></i>
                        </button>
                        <button (click)="openDelete(item,i)"  type="button" class="btn btn-danger btn-delete">
                          <i class="fa-solid fa-trash-can"></i>
                        </button>
                        <!-- <img loading="lazy" #img data-src="{{static(item.imagePath)}}" class="img-fluid"> -->
                        <app-media-item [src]="item.imagePath"></app-media-item>
                        <span>{{item.imageName}} {{img?.nativeElement?.offsetWidth}}</span>
                      </div>
                    </div>
                  </div>
                  <ng-template #noDetail>
                    <div class="row">
                      <div class="py-3 px-3">
                        Không có file nào ...
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
      <app-paging *ngIf="active == 2" (goPage)="goToPage($event)" (changeLimit)="changeLimit($event)" (goNext)="onNext()"
                  (goPrev)="onPrev()" [pagesToShow]="6" [page]="queryObj.page" [perPage]="queryObj.limit" [count]="queryObj.total">
                  </app-paging>
    </div>
  </div>
</app-block>

<ng-template #createFolder let-modal>
  <!-- <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <form #frmCreateFolder="ngForm">
    <div class="modal-body">
      <div class="form-group">
        <label for="">Tên Album</label>
        <input minlength="3" required [(ngModel)]="folerName"  type="text" class="form-control" name="folderName" placeholder="Nhập vào tên album cần tạo">
      </div>
      <!-- <app-input minlength="3" required [(ngModel)]="folerName" name="folderName" name-control="Tên Album"
        label="Tên Album" placeholder="Nhập vào tên album cần tạo">
      </app-input> -->
    </div>
    <div class="modal-footer">
      <button  type="button" (click)="doCreateFolder(frmCreateFolder, modal)" class="btn btn-outline-primary">Tạo Album</button>
    </div>
  </form>
</ng-template>
