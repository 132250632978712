import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Injector,
  PLATFORM_ID,
} from '@angular/core';
import { ImageLazyService } from './image-lazy.service';

@Directive({
  selector: '[loading]',
})
export class ImageLazyDirective {
  public isBrowser: boolean;
  constructor(
    private injector: Injector,
    private el: ElementRef,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    const img = this.el.nativeElement;

    // const lazyService = this.injector.get(ImageLazyService);
    // lazyService.observe(img);
    // console.log("Kích hoạt lazyload", img)

    // return;

    // Nếu browser đã support thuộc tính "loading", chúng ta không cần phải làm gì thêm nữa, hãy để nó làm công việc của nó.
    // Tuy nhiên nếu element không phải là img (nó là background image), thì fallback về intersection observable
    if (
      'loading' in HTMLImageElement.prototype &&
      img.tagName.toLowerCase() === 'img'
    ) {
      img.src = img.dataset?.src;
      // console.log("trình duyệt hỗ trợ lazyload")
    } else {
      // fallback sử dụng intersection observable API
      if (this.isBrowser) {
        // fallback sử dụng intersection observable API
        const lazyService = this.injector.get(ImageLazyService);
        lazyService.observe(img);
      }
    }
  }
}
