<!-- <div class="dialog">
  <ng-content></ng-content>
</div> -->
<div class="dialog modal fade">
  <div class="modal-dialog modal-xl" [ngClass]="'modal-'+size">
    <div class="modal-content">
      <div class="modal-header" *ngIf="title">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="activeDialog.close('click close')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-template *ngTemplateOutlet="itemTemplate; context: {$implicit: activeDialog}"></ng-template>
      <!-- <ng-content></ng-content> -->
    </div>
  </div>
</div>

