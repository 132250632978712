import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { User } from '@shared/models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  currentUser: User | undefined;
  title = 'app';

  constructor(private router: Router) {
    // this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(){
    //lightbox settings
    
 }

  logout() {
    // this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  onActivate( event: any)  {
    //window.scroll(0, 0);
  }
}
