export class VNPayResponse {
  public vnp_TmnCode:string = ""; //Mã website của merchant trên hệ thống của VNPAY.Ví dụ: 2QXUI4J4
  public vnp_Amount:number= 0;//"10000"
  public  vnp_BankTranNo:string = "" // Mã giao dịch tại Ngân hàng. Ví dụ: NCB20170829152730
  public  vnp_CardType:string = ""; // Loại tài khoản/thẻ khách hàng sử dụng:ATM,IB,ACC,QRCODE
  public  vnp_BankCode:string = ""; // Mã Ngân hàng thanh toán. Ví dụ: NCB
  public  vnp_PayDate:string ="" //""
  public  vnp_OrderInfo:string = ""; // Thông tin mô tả nội dung thanh toán (Tiếng Việt, không dấu). Ví dụ: **Nap tien cho thue bao 0123456789. So tien 100,000 VND**
  public  vnp_TransactionNo:string = ""; // Mã giao dịch ghi nhận tại hệ thống VNPAY. Ví dụ: 20170829153052
  public  vnp_ResponseCode:string = ""; //Mã phản hồi kết quả thanh toán. Quy định mã trả lời 00 ứng với kết quả Thành công cho tất cả các API. Tham khảo thêm tại bảng mã lỗi
  public  vnp_TxnRef:number= 0; //""Giống mã gửi sang VNPAY khi gửi yêu cầu thanh toán. Ví dụ: 23554
  public  vnp_SecureHashType:string = ""; //"" Loại mã băm sử dụng: MD5, SHA256
  public  vnp_SecureHash:string = ""; //Mã kiểm tra (checksum) để đảm bảo dữ liệu của giao dịch không bị thay đổi trong quá trình chuyển từ VNPAY về Website TMĐT Cần kiểm tra đúng checksum khi bắt đầu xử lý yêu cầu(trước khi thực hiện các yêu cầu khác)
  constructor(payload: Partial<VNPayResponse>){
    for(const key in payload){
      if(this.hasOwnProperty(key)){
        this[key] = payload[key];
      }
    }
  }
}