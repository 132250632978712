export class FileObj{ 
  file:File = null;
  status:boolean = false;
  progress:number= 0;
  preview:any;
  path:string;
  isError:boolean = false;

  timerProgress = null;

  constructor(item?){
    if(item)
    {
     this.file = item;
     this.status = item.status?item.status:false;
    }
  }

  public start(time=1000){
    this.isError = false;
    this.progress = 0;
    let max = 80;
    let step = time/max;
    clearInterval(this.timerProgress);
    this.timerProgress = setInterval(() => {
      if(this.progress >= max)
      {
        clearInterval(this.timerProgress);
      }
      this.progress += 1;
    }, step);
  }

  public reset(){
    clearInterval(this.timerProgress);
    this.progress = 0;
    this.isError = false;
  };

  public finished(){
    clearInterval(this.timerProgress);
    this.progress = 100;
  }
}

export enum TypeFile{
  FOLDER = "FOLODER",
  FILE = "FILE",
}