<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1 *ngIf="linkCode == ''; else title">{{ 'packagePage.packageTitle' | translate }}</h1>
      <ng-template #title>
        <h1 *ngIf="packageCategorySelected">{{ packageCategorySelected.categoryName }}</h1>
      </ng-template>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item"><a [routerLink]="'/goi-kham'">Gói khám</a></li>
          <!-- <li class="breadcrumb-item" *ngIf="linkCode != ''">{{ packageCategorySelected.categoryName }}</li> -->
        </ol>
      </nav>
    </div>
  </div>
</div>

<!--package -->
<section class="package card-block bg-light pt-3">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-8">
        <h2 class="title-top">{{ 'packagePage.packageAll' | translate }}</h2>
      </div>
      <div class="col-md-4 form-group">
        <div class="choose-package">
          <select (change)="doChangePackageCategory()" [(ngModel)]="packageCategorySelected" class="form-control">
            <option [ngValue]="item" *ngFor="let item of packageCategorys">
              {{ item.categoryName }}
            </option>
          </select>
          <i class="fa-solid fa-chevron-down"></i>
        </div>
      </div>
    </div>
    <div *ngIf="isLoadding">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 mb-4">
          <div class="timeline-wrapper">
            <div class="timeline-item">
              <div class="animated-background">
                <div class="background-masker header-top"></div>
                <div class="background-masker header-left"></div>
                <div class="background-masker header-right"></div>
                <div class="background-masker header-bottom"></div>
                <div class="background-masker subheader-left"></div>
                <div class="background-masker subheader-right"></div>
                <div class="background-masker subheader-bottom"></div>
                <div class="background-masker content-top"></div>
                <div class="background-masker content-first-start"></div>
                <div class="background-masker content-first-end"></div>
                <div class="background-masker content-second-line"></div>
                <div class="background-masker content-second-end"></div>
                <div class="background-masker content-third-line"></div>
                <div class="background-masker content-third-end"></div>
                <div class="background-masker content-fourth-line"></div>
                <div class="background-masker content-fifth-line"></div>
                <div class="background-masker content-sixth-line"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 mb-4">
          <div class="timeline-wrapper">
            <div class="timeline-item">
              <div class="animated-background">
                <div class="background-masker header-top"></div>
                <div class="background-masker header-left"></div>
                <div class="background-masker header-right"></div>
                <div class="background-masker header-bottom"></div>
                <div class="background-masker subheader-left"></div>
                <div class="background-masker subheader-right"></div>
                <div class="background-masker subheader-bottom"></div>
                <div class="background-masker content-top"></div>
                <div class="background-masker content-first-start"></div>
                <div class="background-masker content-first-end"></div>
                <div class="background-masker content-second-line"></div>
                <div class="background-masker content-second-end"></div>
                <div class="background-masker content-third-line"></div>
                <div class="background-masker content-third-end"></div>
                <div class="background-masker content-fourth-line"></div>
                <div class="background-masker content-fifth-line"></div>
                <div class="background-masker content-sixth-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="spinner-grow text-primary mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-secondary mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoadding" class="row" [dsMatchHeight]="'card'">
      <div class="col-sm-12 col-md-6 col-lg-6 mb-4" *ngFor="let row of packages">
        <div class="category card">
          <div class="row">
            <!-- images -->
            <div class="col-sm-6 col-md-12 col-lg-6 col-image">
              <div class="discount">
                <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" alt="Alternate Text" />
              </div>
              <div class="image">
                <a [title]="row.packageName" class="link" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                  <img [src]="static(row.imagePath)" alt="{{ row.imageSeotitle }}" onError="this.src='/assets/images/thumb.png'" />
                  <div class="disccountText" *ngIf="row.isDisccount && row.disccountText && row.disccountText.length > 0">
                    <img class="icon" src="/assets/images/promotion.png" width="50px" alt="promotion" />
                    <div class="text" [innerHTML]="row.disccountText"></div>
                  </div>
                </a>
              </div>
            </div>
            <!-- content -->
            <div class="col-sm-6 col-md-12 col-lg-6">
              <div class="title mb-2 mt-md-3 mt-3 m-sm-0 m-lg-0">
                <a [title]="row.packageName" class="link" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                  <h3>{{ row.packageName }}</h3>
                </a>
                <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                <div class="pt-2">
                  <div class="d-flex justify-content-start" *ngIf="row.listPrice > row.salePrice">
                    <span class="listPrice">{{ row.listPrice | numberformat }}₫</span>
                  </div>
                  <div class="d-flex justify-content-start">
                    <span class="salePrice">{{ row.salePrice | numberformat }}₫</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="emptydata" *ngIf="!packageCategorySelected; else lbUpdatePackage">
        {{ 'packagePage.dataEmpty' | translate }}
      </div>
      <ng-template #lbUpdatePackage>
        <div class="emptydata" *ngIf="!packages || packages.length === 0">{{ 'packagePage.dataEmpty' | translate }}...</div>
      </ng-template>
    </div>
  </div>
</section>
