import { Component, SimpleChanges, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@core/BaseComponent';
import { DocterService } from '@shared/services/doctor.service';
import { ResponeResult } from '@core/models/ResponeResult';
import { trigger, transition, style, animate } from '@angular/animations';
import { Tag, TagType } from '@shared/models/tags.model';
@Component({
  selector: 'app-doctordetail',
  templateUrl: './doctordetail.component.html',
  styleUrls: ['./doctordetail.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(700, style({ opacity: 1 })),
      ]),
      transition('* => void', [animate(700, style({ opacity: 0 }))]),
    ]),
  ],
})
export class DoctorDetailComponent extends BaseComponent {
  public doctorDTO: DoctorDTO = new DoctorDTO();
  private linkCode: string | any;
  lightbox: any;

  constructor(
    public route: ActivatedRoute,
    private docterService: DocterService,
    injector: Injector
  ) {
    super(injector);
    this.route.paramMap.subscribe((params) => {
      this.linkCode = this.route.snapshot.paramMap.get('id');
      this.getData();
    });
  }

  ngOnInit() {
    this.seo.title = 'Đội ngũ bác sĩ';
    this.updateSEO();
  }
  ngAfterViewInit() {
    this.initLightbox();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getData();
  }

  getData() {
    this.docterService
      .noValid()
      .getDataID({ linkCode: this.linkCode })
      .then((result: ResponeResult) => {
        if (result.isOk) {
          this.doctorDTO = result.repData;
          this.seo.title =
            this.doctorDTO.doctor.doctorName +
            ' - ' +
            this.doctorDTO.doctor.diplomaTitle;
          this.seo.description = this.doctorDTO.doctor.shortDescription;
          this.seo.tags.push(<Tag>{
            type: TagType.property,
            property: 'og:type',
            content: 'article',
          });
          this.seo.tags.push(<Tag>{
            type: TagType.property,
            property: 'og:image',
            content: this.static(this.doctorDTO.doctor.imagePath),
          });

          this.seo.tags.push(<Tag>{
            type: TagType.name,
            name: 'twitter:image',
            content: this.static(this.doctorDTO.doctor.imagePath),
          });

          this.updateSEO();
        }
      })
      .catch((er) => {
        this.router.navigate(['/page-not-found']);
      });
  }
}

export class DoctorDTO {
  doctor: Doctor = new Doctor();
  doctorsRelated: DoctorRelated[] = [];
  achievement: any;
  packages: Package[] = [];
  vaccine: Vaccin[] = [];
}

export class Doctor {
  doctorId: number;
  linkCode: string | any;
  linkDepartment: string | any;
  imagePath: string | any;
  imageSmall: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  levelName: string | any;
  doctorName: string | any;
  positionTitle: string | any;
  departmentTitle: string | any;
  diplomaTitle: string | any;
  longDescription: string | any;
  studyProcess: string | any;
  researchProcess: string | any;
  workExperience: string | any;
  achievement: any;
  departmentName: any;
  shortDescription: any;
}

export class DoctorRelated {
  linkCode: string | any;
  departmentCode: string | any;
  imagePath: string | any;
  imageSmall: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  levelName: string | any;
  doctorName: string | any;
  positionTitle: string | any;
  departmentTitle: string | any;
  diplomaTitle: string | any;
  shortDescription: string | any;
}

export class Package {
  categoryCode: string | any;
  linkCode: string | any;
  listPrice: number | any;
  salePrice: number | any;
  fromDate: Date | any;
  toDate: Date | any;
  publishDate: Date | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  packageName: string | any;
  titleName: string | any;
  shortDescription: string | any;
  imagePath: string | any;
  isApplyDate: boolean;
  isHost: boolean;
  isDisccount: boolean;
}

export class Vaccin {
  productCode: string | any;
  productName: string | any;
  madeIn: string | any;
  containt: string | any;
  comments: string | any;
  productNameEng: string | any;
  madeInEng: string | any;
  containtEng: string | any;
  commentsEng: string | any;
  image: string | any;
  createdBy: number | any;
  createdDate: Date | any;
  modifyBy: number | any;
  modifyDate: Date | any;
  sortOrder: string | any;
  isPublish: boolean;
  searchString: string | any;
}
