import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {
  //frontend
  public getData() {
    return this.get("home/GetContact");
  }

  public addContact(body){
    return this.post("home/AddContact", body);
  }
  public addPackageContact(body){
    return this.post("home/AddPackageContact", body);
  }

  //backend
  public getInitalStatus(query) {
    return this.get("admin/GetInitalStatus", query);
  }
  public getContact(query) {
    return this.get("admin/GetContact", query);
  }

  public getContactDashboard(query) {
    return this.get("admin/dashboard/contact", query);
  }

  public saveContact(body) {
    return this.post("admin/saveContact",body);
  }
}