<div class="">
  <div class="container-fluid p-0">
    <!--Background top-->
    <div class="page-background-tt">
      <div class="col-12 text-center page-title-top">
        <h1>{{ textData.titleName }}</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
            <li class="breadcrumb-item">Bảo lãnh viện phí</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>

  <section class="insurrance content-page">
    <!-- content -->
    <div class="bg-white pt-3">
      <div [innerHTML]="textData.longDescription"></div>
    </div>
    <!-- logo -->
    <div class="sectionTwo bg-light">
      <div class="container">
        <div class="my-3">
          <h4 class="title-top">TỰ HÀO LÀ ĐỐI TÁC BẢO HIỂM</h4>
        </div>
        <div class="category">
          <div class="slide mt-3">
            <div class="carousel row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 justify-content-center g-4">
              <div class="col" *ngFor="let row of partners">
                <div class="item-slide">
                  <div class="image">
                    <a>
                      <img [src]="static(row.logo)" alt="{{ row.companyName }}" onError="this.src='/assets/images/thumb.png'" />
                    </a>
                  </div>
                  <div class="title">
                    <a>
                      <h3 class="name">{{ row.companyName }}</h3>
                    </a>
                  </div>
                  <!-- <p>{{ row.notes }}</p> -->
                </div>
              </div>
            </div>
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" (init)="slickInit($event)" [config]="slideConfig">
              <div ngxSlickItem *ngFor="let row of partners">
                <div class="image">
                  <a>
                    <img [src]="static(row.logo)" alt="{{ row.companyName }}" onError="this.src='/assets/images/thumb.png'" />
                  </a>
                </div>
                <div class="title">
                  <a>
                    <h3 class="name">{{ row.companyName }}</h3>
                  </a>
                </div>
                <p>{{ row.notes }}</p>
              </div>
            </ngx-slick-carousel> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
