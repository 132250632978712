import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ILang, TranslatesService } from '../..';

@Component({
  selector: 'app-translate-menu-item',
  templateUrl: './translate-menu-item.component.html',
  styleUrls: ['./translate-menu-item.component.scss'],
})
export class TranslateMenuItemComponent implements OnInit {
  public langList$: Observable<ILang[]>;
  public currentLang: ILang;
  constructor(private _translatesService: TranslatesService) {}

  ngOnInit(): void {
    this.langList$ = this._translatesService.getLangList();
    this.currentLang = this._translatesService.getLanguage();
  }

  changeLang(lang: ILang) {
    this.currentLang = lang;
    this._translatesService.changeLang(lang.code);
    if (window) window.location.reload();
  }
}
