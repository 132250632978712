<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ dataDTO.rowDepartment.departmentName }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item"><a [routerLink]="'/chuyen-khoa'">Chuyên khoa</a></li>
          <!-- <li class="breadcrumb-item">{{ dataDTO.rowDepartment.departmentName }}</li> -->
        </ol>
      </nav>
    </div>
  </div>
</div>

<section class="special">
  <div class="special-detail-top">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-lg-6 py-2">
          <div class="image" [@fade]="'active'">
            <img
              class="glightbox"
              [src]="static(dataDTO.rowDepartment?.imageLarge)"
              alt="{{ dataDTO.rowDepartment?.imageSeodescription }}"
              onError="this.src='/assets/images/thumb.png'"
            />
          </div>
        </div>
        <div class="col-xs-12 col-lg-6 py-2">
          <!-- <h4 class="title-top">{{ dataDTO.rowDepartment.departmentName }}</h4> -->
          <div class="text-justify shortDescription">
            <div [innerHTML]="dataDTO.rowDepartment.shortDescription | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="special-detail-end">
    <div class="container">
      <h2 class="title-top">{{ dataDTO.rowDepartment.serviceTitle }}</h2>
      <div class="content" [innerHTML]="dataDTO.rowDepartment.longDescription | safeHtml"></div>
    </div>
  </div>
</section>

<section id="vaccin" class="vaccin" *ngIf="dataDTO && dataDTO.rowDepartment.departmentId == '11'">
  <div class="container">
    <h2 class="title-top">{{ 'serviceDetailPage.vaccinList' | translate }}</h2>
    <div class="table-responsive text-nowrap table-sm desktop">
      <table class="table table-hover">
        <thead class="head-table">
          <tr>
            <th class="text-center" width="5%">#</th>
            <th class="text-center" width="20%">
              {{ 'serviceDetailPage.vaccinName' | translate }}
            </th>
            <th class="text-center" width="15%">
              {{ 'serviceDetailPage.madeIn' | translate }}
            </th>
            <th class="text-center" width="30%">
              {{ 'serviceDetailPage.ingredient' | translate }}
            </th>
            <th class="text-center" width="30%">
              {{ 'serviceDetailPage.prevention' | translate }}
            </th>
          </tr>
        </thead>
        <tbody class="table-bordered">
          <tr *ngFor="let row of vaccin; let index = index" [id]="row.productCode">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-uppercase">{{ row.productName }}</td>
            <td>{{ row.madeIn }}</td>
            <td>{{ row.containt }}</td>
            <td>{{ row.comments }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="table-responsive text-nowrap table-sm mobile">
      <table class="table table-hover">
        <thead class="head-table">
          <tr>
            <th class="text-center" width="8%">#</th>
            <th class="text-center" width="25%">
              {{'serviceDetailPage.vaccinName' | translate}}</th>
            <th class="text-center" width="33%">
              {{'serviceDetailPage.madeIn' | translate}}</th>
            <th class="text-center" width="34%">
              {{'serviceDetailPage.prevention' | translate}}</th>
          </tr>
        </thead>
        <tbody class="table-bordered">
          <tr *ngFor="let row of vaccin; let index = index">
            <td class="text-center">{{index + 1}}</td>
            <td class="text-uppercase">{{row.productName}}</td>
            <td>{{row.madeIn}}</td>
            <td>{{row.comments}}</td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <!---vaccine -->
    <div class="vaccinex clearfix card-block py-3 mobile">
      <div class="row">
        <div class="col-md-3 col-sm-6 mb-3" *ngFor="let row of vaccin">
          <div class="category card card-vaccine mb-0">
            <ul style="padding-left: 18px; color: #17a2b8; margin-bottom: 0px">
              <li>
                Tên vaccine : <b>{{ row.productName }}</b>
              </li>
              <li>
                Nước sản xuất : <b>{{ row.madeIn }}</b>
              </li>
              <li>
                Thành phần : <span style="color: #000">{{ row.containt }}</span>
              </li>
              <li>Phòng bệnh : <span [innerHTML]="row.comments | safeHtml" style="color: #000"></span></li>
            </ul>
            <div class="content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- bác sĩ -->
<section class="doctor-related card-block bg-light py-4" [hidden]="!dataDTO">
  <div class="container">
    <h2 class="title-top">{{ 'serviceDetailPage.doctor' | translate }}</h2>
    <div class="row" [dsMatchHeight]="'card'">
      <div class="col-lg-3 col-md-6 mb-3" *ngFor="let row of dataDTO.doctors">
        <div class="card">
          <a [routerLink]="['/bac-si', row.linkCode]">
            <img [src]="static(row.imageSmall)" alt="{{ row.imageSeotitle }}" class="img-fluid" onError="this.src='/assets/images/thumb.png'" />
          </a>
          <div class="doctor-title form-inline mt-2">
            <a class="form-inline" [routerLink]="['/bac-si', row.linkCode]">
              <h3>
                <span class="doctor-level text-uppercase">{{ row.levelName }} &nbsp;</span>
                <span class="doctor-name text-uppercase">{{ row.doctorName }}</span>
              </h3>
            </a>
          </div>
          <div class="content" [innerHTML]="row.shortDescription | safeHtml"></div>
          <div class="footer text-center pt-3">
            <a class="btn btn-viewmore" [routerLink]="['/bac-si', row.linkCode]">{{ 'buttons.readmore' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
