<div class="bg-light pb-3">
  <div class="container-fluid p-0">
    <!--Background top-->
    <div class="page-background-tt">
      <div class="col-12 text-center page-title-top-double">
        <h1>{{ doctorDTO.doctor?.levelName }} {{ doctorDTO.doctor?.doctorName }}</h1>
        <!-- <hr /> -->
        <!-- <h1 class="department-tile-top" [@fade]="'active'">{{doctorDTO.doctor?.departmentName}}
        </h1> -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
            <li class="breadcrumb-item">Đội ngũ bác sĩ</li>
            <li class="breadcrumb-item">
              <a [routerLink]="'/doi-ngu-bac-si/' + doctorDTO.doctor?.linkDepartment">{{ doctorDTO.doctor?.departmentName }}</a>
            </li>
            <!-- <li class="breadcrumb-item">{{ doctorDTO.doctor?.doctorName }}</li> -->
          </ol>
        </nav>
      </div>
    </div>
  </div>

  <div class="doctor-detail">
    <div class="container">
      <div class="row">
        <!--Thông tin bác sĩ-->
        <div class="detail">
          <div class="row">
            <div class="col-lg-4">
              <div class="image img-doctor" [@fade]="'active'">
                <img
                  class="glightbox"
                  [src]="static(doctorDTO.doctor?.imagePath)"
                  alt="{{ doctorDTO.doctor?.imageSeodescription }}"
                  onError="this.src='/assets/images/thumb.png'"
                />
              </div>
            </div>
            <div class="col-lg-8 doctor-content">
              <h2 class="text-uppercase doctor-name">
                <span [@fade]="'active'" class="doctor-level me-2">{{ doctorDTO.doctor?.levelName }}</span> {{ doctorDTO.doctor?.doctorName }}
              </h2>
              <ul class="leade list-unstyled m-0">
                <!-- <li [@fade]="'active'" class="pb-2">
                  <i class="literacy fas fa-check-circle"></i>
                  <strong class="literacy title">
                  {{doctorDTO.doctor?.diplomaTitle}}</strong></li> -->
                <li [@fade]="'active'" class="pb-2">
                  <i class="literacy fas fa-check-circle"></i>
                  <strong class="literacy title"> {{ doctorDTO.doctor?.departmentTitle }}</strong>
                </li>
                <li [@fade]="'active'" class="pb-2">
                  <i class="literacy fas fa-check-circle"></i>
                  <strong class="literacy title"> {{ doctorDTO.doctor?.positionTitle }}</strong>
                </li>
              </ul>
              <div class="short-content text-justify">
                <div class="m-0" [@fade]="'active'" [innerHTML]="doctorDTO.doctor?.longDescription | safeHtml"></div>
              </div>
              <div class="btn-process py-3 text-center text-md-start">
                <a class="btn btn-booking-doctor" [routerLink]="['/dat-lich-kham']" [queryParams]="{ doctor: doctorDTO.doctor?.linkCode }"
                  ><i class="far fa-calendar-check"></i> {{ 'buttons.booking' | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <!-- kinh nghiệm bác sĩ-->
        <div class="experience w-100">
          <!-- Lined tabs-->
          <ul class="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center" id="myTab" role="tablist">
            <li *ngIf="doctorDTO.doctor.studyProcess != null && doctorDTO.doctor.studyProcess != ''" class="nav-item">
              <a
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                class="nav-link text-uppercase font-weight-bold me-sm-3 rounded-0 active"
                ><i class="fas fa-graduation-cap pe-2"></i>{{ 'doctorPage.studyHistory' | translate }}</a
              >
            </li>
            <li *ngIf="doctorDTO.doctor.researchProcess != null && doctorDTO.doctor.researchProcess != ''" class="nav-item">
              <a
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                class="nav-link text-uppercase font-weight-bold me-sm-3 rounded-0"
              >
                <i class="fas fa-dice-d20 pe-2"></i>
                {{ 'doctorPage.research' | translate }}</a
              >
            </li>
            <li *ngIf="doctorDTO.doctor.workExperience != null && doctorDTO.doctor.workExperience != ''" class="nav-item">
              <a
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                class="nav-link text-uppercase font-weight-bold rounded-0"
                ><i class="fas fa-briefcase pe-2"></i>{{ 'doctorPage.experience' | translate }}</a
              >
            </li>
            <li *ngIf="doctorDTO.doctor.achievement != null && doctorDTO.doctor.achievement != ''" class="nav-item">
              <a
                id="achievement-tab"
                data-bs-toggle="tab"
                data-bs-target="#achievement"
                role="tab"
                aria-controls="achievement"
                aria-selected="false"
                class="nav-link text-uppercase font-weight-bold rounded-0"
              >
                <i class="fas fa-chess-queen pe-2"></i>
                {{ 'doctorPage.awards' | translate }}</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTab2Content">
            <div
              [innerHTML]="doctorDTO.doctor.studyProcess | safeHtml"
              class="tab-pane fade py-3 show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            ></div>
            <div
              [innerHTML]="doctorDTO.doctor.researchProcess | safeHtml"
              class="tab-pane fade py-3"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            ></div>
            <div
              [innerHTML]="doctorDTO.doctor.workExperience | safeHtml"
              class="tab-pane fade py-3"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            ></div>
            <div
              [innerHTML]="doctorDTO.doctor.achievement | safeHtml"
              class="tab-pane fade py-3"
              id="achievement"
              role="tabpanel"
              aria-labelledby="achievement-tab"
            ></div>
          </div>
        </div>

        <div class="accordion w-100" id="accordionExample">
          <!-- quá trình đào tạo -->
          <div class="card" *ngIf="doctorDTO.doctor.studyProcess != null && doctorDTO.doctor.studyProcess != ''">
            <div class="card-header">
              <button class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="#collapseOne">
                <i class="fas fa-graduation-cap pe-2"></i>
                <h3>{{ 'doctorPage.studyHistory' | translate }}</h3>
              </button>
            </div>
            <div class="collapse show" id="collapseOne">
              <div class="card-body" [innerHTML]="doctorDTO.doctor.studyProcess | safeHtml"></div>
            </div>
          </div>
          <!-- Công trình nghiên cứu -->
          <div class="card" *ngIf="doctorDTO.doctor.researchProcess != null && doctorDTO.doctor.researchProcess != ''">
            <div class="card-header">
              <button class="btn" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="#collapseTwo">
                <i class="fas fa-dice-d20 pe-2"></i>
                <h3>{{ 'doctorPage.research' | translate }}</h3>
              </button>
            </div>
            <div class="collapse" id="collapseTwo">
              <div class="card-body" [innerHTML]="doctorDTO.doctor.researchProcess | safeHtml"></div>
            </div>
          </div>
          <!-- Kinh nghiệm làm việc -->
          <div class="card" *ngIf="doctorDTO.doctor.workExperience != null && doctorDTO.doctor.workExperience != ''">
            <div class="card-header">
              <button
                class="btn"
                href="#collapseThree"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="#collapseThree"
              >
                <i class="fas fa-briefcase pe-2"></i>
                <h3>{{ 'doctorPage.experience' | translate }}</h3>
              </button>
            </div>
            <div class="collapse" id="collapseThree">
              <div class="card-body" [innerHTML]="doctorDTO.doctor.workExperience | safeHtml"></div>
            </div>
          </div>
          <!-- Giải thưởng ghi nhận -->
          <div class="card" *ngIf="doctorDTO.doctor.achievement != null && doctorDTO.doctor.achievement != ''">
            <div class="card-header">
              <button
                class="btn"
                href="#collapseFour"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="#collapseFour"
              >
                <i class="fas fa-chess-queen pe-2"></i>
                <h3>{{ 'doctorPage.awards' | translate }}</h3>
              </button>
            </div>
            <div class="collapse" id="collapseFour">
              <div class="card-body" [innerHTML]="doctorDTO.doctor.achievement | safeHtml"></div>
            </div>
          </div>
        </div>
        <!--End Tab kinh nghiệm bác sĩ-->
      </div>
    </div>
  </div>

  <div class="clearfix">
    <!--Danh sách bác sĩ-->
    <section class="doctor-related card-block" [hidden]="doctorDTO.doctorsRelated == null || doctorDTO.doctorsRelated?.length == 0">
      <div class="container">
        <h2 class="title-top">{{ 'doctorPage.title' | translate }}</h2>
        <div class="row mt-2">
          <div class="col-lg-3 col-md-6" *ngFor="let row of doctorDTO.doctorsRelated">
            <div class="category">
              <a [routerLink]="['/bac-si', row.linkCode]">
                <img [src]="static(row.imageSmall)" alt="{{ row.imageSeotitle }}" onError="this.src='/assets/images/thumb.png'" />
              </a>
              <div class="doctor-title form-inline mt-2">
                <a class="form-inline" [routerLink]="['/bac-si', row.linkCode]">
                  <h3>
                    <span class="doctor-level text-uppercase">
                      {{ row.levelName }}
                      &nbsp;
                    </span>
                    <span class="doctor-name text-uppercase">{{ row.doctorName }}</span>
                  </h3>
                </a>
              </div>
              <div class="content" [innerHTML]="row.shortDescription"></div>
              <div class="footer col-md-12 text-center py-3">
                <a class="btn btn-viewmore" [routerLink]="['/bac-si', row.linkCode]">{{ 'buttons.readmore' | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Gói khám -->
    <section class="package card-block">
      <div class="container">
        <h2 class="title-top">{{ 'homePage.package' | translate }}</h2>
        <div class="row" [dsMatchHeight]="'package-item'">
          <div class="col-md-12 col-lg-6 mb-3" *ngFor="let row of doctorDTO.packages">
            <div class="category card package-item">
              <div class="row">
                <!-- images -->
                <div class="col-sm-6 col-md-5 col-image">
                  <div class="discount">
                    <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" alt="Alternate Text" />
                  </div>
                  <div class="image">
                    <a class="link" [title]="row.packageName" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                      <img [src]="static(row.imagePath)" alt="{{ row.imageSeotitle }}" onError="this.src='/assets/images/thumb.png'" />
                    </a>
                  </div>
                </div>
                <!-- content -->
                <div class="col-sm-6 col-md-7">
                  <div class="content">
                    <div class="title">
                      <a [title]="row.packageName" class="link" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                        <h3>{{ row.packageName }}</h3>
                      </a>
                    </div>
                    <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                    <div class="pt-2">
                      <div class="d-flex justify-content-start">
                        <span class="salePrice">{{ row.salePrice | number : 0 }}₫</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!---vaccine -->
    <section class="vaccine card-block">
      <div class="container">
        <h2 class="title-top">{{ 'homePage.vaccin' | translate }}</h2>
        <div class="row" [dsMatchHeight]="'vaccine-item'">
          <div class="col-md-3 col-sm-6 mb-3" *ngFor="let row of doctorDTO.vaccine">
            <a [title]="row.productName" class="link" [routerLink]="['/chuyen-khoa/tiem-chung']" [queryParams]="{ vaccin: row.productCode }">
              <div class="card category vaccine-item mb-0">
                <div class="vaccine-title">
                  <h3>{{ row.productName }}</h3>
                </div>
                <!-- <img class="img" src="{{row.image}}"> -->
                <img [src]="static(row.image)" alt="{{ row.productName }}" onError="this.src='/assets/images/thumb.png'" />

                <div class="content">
                  <p [innerHTML]="row.comments | safeHtml"></p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="footer text-center">
          <button [routerLink]="['/chuyen-khoa/tiem-chung']" class="btn btn-viewmore">{{ 'buttons.readmore' | translate }}</button>
        </div>
      </div>
    </section>
  </div>
</div>
