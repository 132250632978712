<div class="">
  <!-- background -->
  <div class="container-fluid p-0">
    <!--Background top-->
    <div class="page-background-tt">
      <div class="col-12 text-center page-title-top">
        <h1>{{ 'aboutPage.title' | translate }}</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
            <li class="breadcrumb-item">Giới thiệu</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>

  <!-- about us -->
  <section class="about-details content-page">
    <div class="container" [hidden]="!aboutDTO.abouts || aboutDTO.abouts.length == 0">
      <div class="bg-white py-4">
        <div class="about-title col-md-12 px-0">
          <h2 class="title-top">{{ aboutDTO.abouts[0]?.titleName }}</h2>
          <div class="content" [innerHTML]="aboutDTO.abouts[0]?.contents | safeHtml"></div>
        </div>
        <div class="about-content row m-auto">
          <div class="left col-md-6">
            <img
              class="glightbox"
              [src]="static(aboutDTO.abouts[1]?.imagePath)"
              alt="{{ aboutDTO.abouts[1]?.imageSeotitle }}"
              onError="this.src='/assets/images/thumb.png'"
            />
          </div>
          <div class="right col-md-6 pt-3">
            <div class="right-title d-flex">
              <h3><i class="fas fa-quote-left"></i> {{ aboutDTO.abouts[1]?.titleName }} <i class="fas fa-quote-right"></i></h3>
            </div>
            <div class="content" [innerHTML]="aboutDTO.abouts[1]?.contents | safeHtml"></div>
          </div>
        </div>
        <div class="about-content row m-auto">
          <div class="left order col-md-6">
            <div class="left-title d-flex">
              <h3><i class="fas fa-quote-left"></i> {{ aboutDTO.abouts[2]?.titleName }} <i class="fas fa-quote-right"></i></h3>
            </div>
            <div class="content" [innerHTML]="aboutDTO.abouts[2]?.contents | safeHtml"></div>
          </div>
          <div class="right col-md-6">
            <img
              class="glightbox"
              [src]="static(aboutDTO.abouts[2]?.imagePath)"
              alt="{{ aboutDTO.abouts[2]?.imageSeotitle }}"
              onError="this.src='/assets/images/thumb.png'"
            />
          </div>
        </div>
        <div class="about-content row m-auto">
          <div class="left col-md-6">
            <img
              class="glightbox"
              [src]="static(aboutDTO.abouts[3]?.imagePath)"
              alt="{{ aboutDTO.abouts[3]?.imageSeotitle }}"
              onError="this.src='/assets/images/thumb.png'"
            />
          </div>
          <div class="right col-md-6">
            <div class="right-title d-flex">
              <h3><i class="fas fa-quote-left"></i> {{ aboutDTO.abouts[3]?.titleName }} <i class="fas fa-quote-right"></i></h3>
            </div>
            <div class="content" [innerHTML]="aboutDTO.abouts[3]?.contents | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- counter phòng khám -->
  <!-- <div class="bg-counter">
  <div class="container counter-cc">
    <div class="row">
      <div class="col-md-4 col-sm-4">
        <h1 class="counter" id="counter">132</h1>
        <h6>{{'about.staff' | translate}}</h6>
      </div>
      <div class="col-md-4 col-sm-4">
        <h1 class="counter" id="counter">02</h1>
        <h6>{{'about.clinic' | translate}}</h6>
      </div>
      <div class="col-md-4 col-sm-4">
        <h1 class="counter" id="counter">01</h1>
        <h6>{{'about.nation' | translate}}</h6>
      </div>
    </div>
  </div>
</div> -->

  <!-- doctor -->
  <section class="doctor-related bg-light" [hidden]="!aboutDTO">
    <div class="container">
      <div class="pt-3">
        <h2 class="title-top">{{ 'aboutPage.doctors' | translate }}</h2>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6" *ngFor="let row of aboutDTO.doctors">
          <div class="category">
            <a [routerLink]="['/bac-si', row.linkCode]">
              <img [src]="static(row.imageSmall)" alt="{{ row.imageSeotitle }}" onError="this.src='/assets/images/thumb.png'" />
            </a>
            <div class="doctor-title form-inline mt-2">
              <a class="form-inline" [routerLink]="['/bac-si', row.linkCode]">
                <h3>
                  <span class="doctor-level text-uppercase">{{ row.levelName }} &nbsp;</span>
                  <span class="doctor-name text-uppercase">{{ row.doctorName }}</span>
                </h3>
              </a>
            </div>
            <div class="content" [innerHTML]="row.shortDescription"></div>
            <div class="footer col-md-12 text-center py-3">
              <a class="btn btn-viewmore" [routerLink]="['/bac-si', row.linkCode]">{{ 'buttons.readmore' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
