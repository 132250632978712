import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/core/BaseComponent';
import { NewsRelated, NewsDTO } from '@shared/models/New';
import { NewService } from '@shared/services/new.services';
import { ResponeResult } from '@core/models/ResponeResult';
import Contents from 'contents';

import { trigger, transition, style, animate } from '@angular/animations';
import { Tag, TagType } from '@shared/models/tags.model';

declare var jQuery;

@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.component.html',
  styleUrls: ['./newsdetail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(700, style({ opacity: 1 })),
      ]),
      transition('* => void', [animate(700, style({ opacity: 0 }))]),
    ]),
  ],
})
export class NewsDetailComponent extends BaseComponent {
  newsDTO: NewsDTO = new NewsDTO();
  newsRelateds: NewsRelated[] = [];
  urlLink: string | any;
  linkCode: string | any;
  lightbox: any;
  isShowMore: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public newService: NewService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.seo.title = 'Tin tức';
    this.updateSEO();
    this.route.paramMap.subscribe((params) => {
      this.linkCode = this.route.snapshot.paramMap.get('id');
      this.getData();
    });
  }

  createToc(arr) {
    const toc = document.createElement('ul');
    toc.setAttribute('class', 'toc');
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let name = element.name;
      name = name.replace(/\\n/g, '').replace(/\n/g, ' ');

      const li = document.createElement('li');
      let a = document.createElement('a');
      a.innerText = name;
      a.setAttribute('href', '#' + element.id);
      li.appendChild(a);
      toc.appendChild(li);
      a.addEventListener('click', (event) => {
        event.preventDefault();
        element.element.style.scrollMargin = '140px';
        element.element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        this.readmore();
      });
      const descendants = element.descendants;
      if (descendants.length > 0) {
        let tocChild = this.createToc(descendants);
        li.appendChild(tocChild);
      }
    }
    return toc;
  }
  setupToc() {
    setTimeout(() => {
      (window as any)['global'] = window;

      let cToc = document.getElementsByClassName('newsdetail-content');
      const contents = Contents({
        articles: cToc[0].querySelectorAll('h2, h3, h4, h5, h6'),
        link: () => {},
      });
      const nav = contents.tree();
      //console.log(nav);
      let toc = this.createToc(nav);
      document.querySelector('#ds-toc-container .card-body').appendChild(toc);
      contents.eventEmitter().trigger('resize');
    }, 1000);
  }

  ngAfterViewInit() {
    this.initLightbox();
    if (this.isBrowser) {
      this.setupToc();
    }
  }

  getData() {
    this.newService
      .noValid()
      .getNewID({ linkCode: this.linkCode })
      .then((result: ResponeResult) => {
        if (result.isOk) {
          this.newsDTO = result.repData;
          if (this.newsDTO == null) {
            //Gõ sai url param thì load default tin tức simmed
            this.router.navigate(['tin-tuc/tin-tuc-simmed']);
          } else {
            // tocbot.init({
            //   // Where to render the table of contents.
            //   tocSelector: '.js-toc',
            //   // Where to grab the headings to build the table of contents.
            //   contentSelector: '.newsdetail-content',
            //   // Which headings to grab inside of the contentSelector element.
            //   headingSelector: 'h1, h2, h3',
            //   // For headings inside relative or absolute positioned containers within content.
            //   hasInnerContainers: true,
            // });
            if (this.newsDTO.rowNew.canonicalURL?.length > 0) {
              this.updateCanonicalUrl(this.newsDTO.rowNew.canonicalURL);
            }
            this.seo.title = this.newsDTO.rowNew.titelSeonews?.toLowerCase();
            this.seo.description = this.newsDTO.rowNew.descriptionSeonews;

            this.seo.tags.push(<Tag>{
              type: TagType.property,
              property: 'og:type',
              content: 'article',
            });
            this.seo.tags.push(<Tag>{
              type: TagType.property,
              property: 'og:image',
              content: this.static(this.newsDTO.rowNew.imageLarge),
            });

            this.seo.tags.push(<Tag>{
              type: TagType.name,
              name: 'twitter:image',
              content: this.static(this.newsDTO.rowNew.imageLarge),
            });

            this.updateSEO();
          }
        } else {
          //Show alert lỗi
        }
      })
      .catch((err) => {
        this.router.navigate(['/page-not-found']);
      });
  }

  readmore() {
    this.isShowMore = true;
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
}
