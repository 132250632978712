import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class VaccinService extends BaseService {
  //frontend
  public getData() {
    return this.get("home/GetVaccin");
  }

  //backend
  public getVaccin(query) {
    return this.get("admin/GetVaccin", query);
  }
  public saveVaccin(body) {
    return this.post("admin/SaveVaccin", body);
  }
  public deleteVaccin(body) {
    return this.post("admin/DeleteVaccin", body);
  }
  public updateOrderVaccin(body) {
    return this.post("admin/UpdateOrderVaccin", body);
  }
}