import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ButtonDialog, DSDialogOptions } from '../dialog-config';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-dialog-comfirm',
  templateUrl: './dialog-comfirm.component.html',
  styleUrls: ['./dialog-comfirm.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class DialogComfirmComponent implements OnInit {

  buttons:ButtonDialog[];
  title :string;
  message:string;
  icon:string = "fas fa-check-circle";

  constructor(private modalService: NgbModal, private dialogService: DialogService, private _config:NgbModalConfig) { 
    this._config.backdrop = "static";
    this._config.scrollable = false;
    // this._config.size ="sm";
  }

  ngOnInit(): void {
    this.dialogService.initComfirm(this);
  }

  @ViewChild('content') content: ElementRef;
  open(options: DSDialogOptions = {}, content?:any){
    const combinedOptions =  {...this._config, ...options};
    this.icon = combinedOptions.icon;
    this.buttons = combinedOptions.buttons;
    this.title = combinedOptions.title;
    this.message = combinedOptions.message;
    
    if(content)
      return this.modalService.open(content,combinedOptions);
    return this.modalService.open(this.content,combinedOptions);
  }

  cssClass(button: ButtonDialog, cpn="") {
    if (!button) return false;
    const classes = [cpn];
    classes.push(cpn+'-'+button.style);

    return classes.join(' ');
  }
}
