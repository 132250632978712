import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class HomeService extends BaseService {
    public GetHomeData() {
        return this.get("home/gethomedata");
    }

    public GetNewSYT() {
      return this.get("home/GetNewSYT");
  }
}