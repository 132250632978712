<div class="block-ui" [ngClass]="{'on-loading':isLoad}" [class]="classBlock">
  <ng-content></ng-content>
  <div class="loading">
    <div class="ldr">
      <div class="ldr-blk"></div>
      <div class="ldr-blk an_delay"></div>
      <div class="ldr-blk an_delay"></div>
      <div class="ldr-blk"></div>
    </div>
  </div>
</div>
