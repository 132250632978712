import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'static',
})
export class StaticPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    return environment.STATIC_URL + value;
  }
}
