<div id="uploader">
  <div class="header" *ngIf="isHeader">
    <div class="header-top">
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-cloud-arrow- me-2 fa-4x"></i>
        <h5 class="m-0">Upload File</h5>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="review">
      <div class="row">
        <div class="col-md-3" *ngFor="let item of filePendings">
          <div class="review-item mb-3"  
            [ngClass]="{'border border-danger': item.isError}"
          >
            <div class="review-item-content d-flex">
              <img [src]="item.preview" class="img-fluid align-middle">
              <p class="review-item-title">{{item.file.name}}</p>
            </div>
            <ngb-progressbar *ngIf="!item.status" class="mt-2" type="success" [value]="item.progress" [striped]="true"></ngb-progressbar>
          </div>
        </div>
      </div>
    </div>
    <div class="context"
      (dragover)="onDragOver($event, 'before')"
      (drop)="onDrop($event, 'before')"
      (dragenter)="onDragEnter($event, 'before')"
      (dragleave)="onDragLeave($event, 'before')"
      [ngClass]="{ 'is-highlight': isHighlight }"
    >
      <button type="button" (click)="doClose()" class="close">
        <i class="fa-solid fa-xmark fa-sm"></i>
      </button>
      <div class="uploader-inline-content no-upload-message">
        <div class="upload-ui">
          <h4 class="upload-instructions drop-instructions">
            Thả các tập tin để tải lên
          </h4>
          <p class="upload-instructions drop-instructions">hoặc</p>
          <button type="button" class="btn btn-outline-primary btn-lg">
            Chọn tập tin
          </button>
          <input multiple  #controlFile (change)="inputFileChange($event)" [id]="'id-upload'+autoId" type="file" accept="image/*"/>
          <label [for]="'id-upload'+autoId" ></label>
        </div>
        <div class="upload-inline-status"></div>
        <p class="max-upload-size">Kích thước tập tin tải lên tối đa: 16 MB</p>
      </div>
    </div>
  </div>
</div>