import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@core/BaseComponent';
import { ResponeResult } from '@core/models/ResponeResult';
import { TextData } from '@shared/models/textdata.model';
import { TextdataService } from '@shared/services/textdata.service';

@Component({
  selector: 'app-other-page',
  templateUrl: './other-page.component.html',
  styleUrls: ['./other-page.component.scss'],
})
export class OtherPageComponent extends BaseComponent implements OnInit {
  textData: TextData = new TextData();

  constructor(private textDataService: TextdataService, injector: Injector) {
    super(injector);
    this.route.paramMap.subscribe((params) => {
      let page = this.route.snapshot.paramMap.get('page');
      if (!page.includes('.') && page != 'undefined' && page.length > 0) {
        this.getData(page);
      }
    });
  }

  ngOnInit() {
    this.seo.title = 'Simmed Page';
    this.updateSEO();
  }

  getData(page) {
    this.textDataService
      .noValid()
      .getTextDataByID(page)
      .then((res: ResponeResult) => {
        this.textData = res.repData;
        this.seo.title = this.textData.titleName;
        this.seo.description = this.textData.shortDescription;
        this.updateSEO();
      })
      .catch((err) => {
        this.router.navigate(['/page-not-found']);
      })
      .finally(() => {});
  }
}
