import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[outside]'
})
export class OutsideDirective {

  @Output()
  outSideClick: EventEmitter<MouseEvent> = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    debugger;
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.outSideClick.emit(event);
    }
  }

  constructor(private elementRef: ElementRef) {}

}
