import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/core/BaseComponent';
import { ModalService } from '@shared/module/modal';
import { AuthenParams } from '@app/common/models/AuthenParams';
import { TranslatesService } from '@shared/module/translate';
import { ContactService } from '@shared/services/contact.service';
import { BlockService } from '@shared/module/block/services/block.service';
import { Contact } from '@shared/models/contact.model';
import { TextData } from '@shared/models/textdata.model';
import { TextdataService } from '@shared/services/textdata.service';
import { ResponeResult } from '@core/models/ResponeResult';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent extends BaseComponent implements OnInit {
  reqData: Contact = new Contact();
  isTermCondition: boolean = false;
  submitted: boolean = false;
  isLoading = false;
  mobNumberPattern = '^((\\+84-?)|0)?[0-9]{0,50}$';
  emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';
  isValidFormSubmitted = false;
  langCode: string = 'vi-VN';
  textData: TextData = new TextData();
  constructor(
    private textDataService: TextdataService,
    private modalService: ModalService,
    private translate: TranslatesService,
    private contactService: ContactService,
    private blockService: BlockService
  ) {
    super();
  }

  ngOnInit() {
    this.langCode = this.translate.getLanguage().culture;
    this.seo.title = 'Thông tin liên hệ';
    this.updateSEO();

    this.translate.onLoad().then(() => {});
    this.resetData();
  }

  initData() {
    this.getData('CONTACT');
  }

  resetData() {
    this.reqData = new Contact();
    this.reqData.status = 1;
    this.reqData.createdDate = new Date();
    this.reqData.contactType = 1;
  }

  onSubmit(frmContact: NgForm) {
    this.submitted = true;
    this.isValidFormSubmitted = true;
    if (frmContact.invalid) {
      return;
    } else {
      this.isLoading = true;
      this.blockService.load('contact');
      this.reqData.trackingNotes = 'WEBSITE';
      this.contactService
        .addContact(this.reqData)
        .then((res) => {
          this.openModal('custom-modal-1');
          this.onReset(frmContact);
          frmContact.onReset();
        })
        .catch(() => {})
        .finally(() => {
          frmContact.onReset();
          this.blockService.unload('contact');
          this.submitted = false;
          this.isLoading = false;
        });
    }
  }

  getData(page) {
    this.textDataService
      .noValid()
      .getTextDataByID(page)
      .then((res: ResponeResult) => {
        this.textData = res.repData;
        this.seo.title = this.textData.titleName;
        this.seo.description = this.textData.shortDescription;
        this.updateSEO();
      })
      .catch((err) => {
        //this.router.navigate(['/page-not-found']);
      })
      .finally(() => {});
  }

  onReset(frmContact?: NgForm) {
    if (frmContact) {
      this.submitted = false;
      frmContact.reset();
    }
  }
  addContact() {
    // const header = new HttpHeaders().set('Content-type', 'application/json');
    // const body = JSON.stringify(this.reqData, null);
    // console.log(body);
    // this.isLoading = true;
    // this.http.post<boolean>(this.API_URL + 'home/AddContact', body, { headers: header }).subscribe(data => {
    //   this.openModal('custom-modal-1');
    //   this.onReset();
    //   this.submitted = false;
    //   this.isLoading = false;
    // })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}

export class ReqContact extends AuthenParams {
  rowData: Contact;
}
