import { Component, Input, OnInit } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { TranslatesService } from '@shared/module/translate';

@Component({
  selector: 'app-input-validate',
  templateUrl: './input-validate.component.html',
  styleUrls: ['./input-validate.component.scss'],
})
export class InputValidateComponent implements OnInit {
  @Input('control') control: NgControl;
  @Input('parent') parent: NgModel;
  @Input('name-control') controlName;
  @Input('submit') submit: boolean = false;
  constructor(private translate: TranslatesService) {}

  ngOnInit(): void {}

  get message() {
    if (this.parent)
      for (const err in this.parent.errors) {
        if (
          this.parent.dirty ||
          (this.parent.formDirective && this.parent.formDirective?.submitted)
        ) {
          return this.getErrorMessage(err, this.parent.errors[err]);
        }
      }

    // if(this.control)
    // for (const err in this.control.errors) {
    //   if(this.control.dirty || this.parent.formDirective && this.parent.formDirective?.submitted) {
    //     return this.getErrorMessage(err, this.control.errors[err]);
    //   }
    // }
  }

  getErrorMessage(error, value) {
    let messages = {
      required: this.translate.instant('validate.required', {
        name: this.controlName,
      }),
      minlength: this.translate.instant('validate.minlength', {
        name: this.controlName,
        requiredLength: value.requiredLength,
      }),
      maxlength: this.translate.instant('validate.maxlength', {
        name: this.controlName,
        requiredLength: value.requiredLength,
      }),
      pattern: this.translate.instant('validate.pattern', {
        name: this.controlName,
      }),
      mustMatch: this.translate.instant('validate.mustMatch', {
        name: this.controlName,
      }),
    };
    return messages[error];
  }
}
