import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckList } from '@shared/models/check-list.model';
import { ComplainTypes } from '@shared/models/complain-types.model';
import { Feedback } from '@shared/models/feedback.model';
import { QueryObj } from '@shared/models/query.model';
import { AlertService } from '@shared/module/alert';
import { FeedbackService } from '@shared/services/feedback.service';
import stringUtil from '@shared/utils/string.util';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  query: QueryObj = new QueryObj();
  isSubmit: boolean = false;
  showModal: boolean = false;
  initModal: boolean = false;
  isLoading: boolean = false;

  showModalThank: boolean = false;
  initModalThank: boolean = false;

  feedBack: Feedback = new Feedback();
  complainTypesSelected: ComplainTypes[] = [];
  complainTypes: ComplainTypes[] = [
    new ComplainTypes(1, 'Bác sĩ'),
    new ComplainTypes(2, 'Điều dưỡng'),
    new ComplainTypes(3, 'Nhân viên vục vụ, thu ngân'),
    new ComplainTypes(4, 'Cơ sở vật chất, vệ sinh chung'),
    new ComplainTypes(5, 'Máy móc thiết bị'),
    new ComplainTypes(7, 'Thời gian chờ'),
    new ComplainTypes(6, 'Khác'),
  ];

  listFeedback: Feedback[] = [];
  listFeedbackFull: Feedback[] = [];
  feedbackSelected: Feedback = new Feedback();
  // _hubConnection: HubConnection|any;
  queues: any[] = [];

  constructor(
    private feedbackService: FeedbackService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.feedbackSelected = new Feedback();
    this.initModal = true;
    this.showModal = true;
    this.ref.detectChanges();
    this.route.paramMap.subscribe((params) => {
      this.feedBack.PatientNumber = params.get('num'); // Lấy tham số 'id' từ đường dẫn
    });
  }

  resetData() {
    this.complainTypes.forEach((element) => {
      element.checked = false;
    });
    this.feedBack = new Feedback();
    this.isSubmit = false;
  }

  timeOutCloseThank: any;
  showThankYou() {
    this.showModalThank = true;
    this.initModalThank = true;
    this.isSubmit = true;
  }
  closeThankYou() {
    this.feedBack = new Feedback();
    clearTimeout(this.timeOutCloseThank);
    this.showModalThank = false;
  }

  submit() {
    this.isSubmit = true;
    this.isLoading = true;
    let arrType: number[] = [];
    this.complainTypes.forEach((element) => {
      if (element.checked) {
        arrType.push(element.CheckListComplainTypeID);
      }
    });
    this.feedBack.CheckListComplainTypeId = JSON.stringify(arrType);
    this.feedbackService
      .saveFeedback(this.feedBack)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
        this.showThankYou();
      });
  }

  chooseStatus(status: number) {
    this.feedBack.FeedbackTypeId = status;
  }
}
