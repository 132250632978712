export class BlockService{

  private _blocks: any[] = [];
  private _isloading:boolean = false;

  public get isLoading(){
    return this._isloading;
  }

  add(block: any) {
    this._blocks.push(block);
  }
  remove(id: string) {
    this._blocks = this._blocks.filter(x => x.id !== id);
  }

  public load(id:string){
    // open modal specified by id
    const block = this._blocks.find(x => x.id === id);
    if(block)
    {
      block.load();
    }
  }

  public unload(id:string){
    const block = this._blocks.find(x => x.id === id);
    if(block)
    {
      setTimeout(() => {
        block.unload();
      }, 500);
    }
  }
}