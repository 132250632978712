<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ 'jobPage.title' | translate }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">{{ 'jobPage.title' | translate }}</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<!-- Tuyển dụng -->
<div class="recruitment bg-light pt-3 content-page">
  <div class="container card-block">
    <h4 class="title-top">{{ 'jobPage.list' | translate }}</h4>
    <div class="row">
      <!--Thông tin tuyển dụng-->
      <div class="col-md-12 col-lg-6 mb-4" *ngFor="let row of jobs">
        <div class="job-item card p-3">
          <div class="p-2">
            <div class="title">
              <a [routerLink]="['/tuyen-dung', row.linkCode]">
                <h5 class="font-weight-bold text-uppercase">{{ row.titleName }}</h5>
              </a>
              <div class="label">
                <div><i class="fas fa-file-invoice-dollar pe-2"></i>{{ 'jobPage.wage_agreement' | translate }}</div>
                <div><i class="fas fa-map-marker-alt pe-2"></i>{{ 'jobPage.general_clinic' | translate }}</div>
              </div>
            </div>
            <hr class="my-1" />
            <div class="description" [innerHTML]="row.shortDescription | safeHtml"></div>
            <div class="more text-center text-md-left">
              <a class="btn btn-viewmore" [routerLink]="['/tuyen-dung', row.linkCode]">{{ 'buttons.readmore' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
