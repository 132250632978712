import { Injectable } from '@angular/core';
import { CActiveDialog, CDialogRef } from './cdialog-ref';
import { ButtonDialog, ButtonType, DSDialogOptions } from './dialog-config';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private _dialogs: any[] = [];

  private _dialogComfirm;

  constructor() {
  }

  add(dialog: any) {
    // add dialog to array of active dialogs
    this._dialogs.push(dialog);
  }

  remove(id: string) {
    // remove dialog from array of active dialogs
    this._dialogs = this._dialogs.filter(x => x.id !== id);
  }

  open(id: string) :CDialogRef {
    let ngbModalRef: CDialogRef = new CDialogRef();
    let activeDialog = new CActiveDialog();
    const dialog = this._dialogs.find(x => x.id === id);
    dialog.open(activeDialog);
    activeDialog.close = (result: any) => { 
      dialog.close();
      ngbModalRef.close(result); 
    };
    return ngbModalRef;
  }

  close(id: string) {
    // close dialog specified by id
    const dialog = this._dialogs.find(x => x.id === id);
    dialog.close();
  }

  initComfirm(dialog: any){
    this._dialogComfirm = dialog;
  }

  success(options: DSDialogOptions = {}, content?:any) {
    const classes = ["dialog-confirm", "dialog-success","index-dialog"];
    const combinedOptions = {windowClass: classes.join(' '), icon:"fas fa-check-circle", ...options};
    return this._dialogComfirm.open(combinedOptions, content);
  }
  error(options: DSDialogOptions = {}, content?:any) {
    const classes = ["dialog-confirm", "dialog-error","index-dialog"];
    const combinedOptions = {windowClass: classes.join(' '), icon:"fas fa-bug", ...options};
    return this._dialogComfirm.open(combinedOptions, content);
  }
  warning(options: DSDialogOptions = {}, content?:any) {
    const classes = ["dialog-confirm", "dialog-warning","index-dialog"];
    const combinedOptions = {windowClass: classes.join(' '), icon:"fas fa-exclamation-triangle", ...options};
    return this._dialogComfirm.open(combinedOptions, content);
  }
  info(options: DSDialogOptions = {}, content?:any) {
    const classes = ["dialog-confirm", "dialog-info","index-dialog"];
    const combinedOptions = {windowClass: classes.join(' '), icon:"fas fa-info-circle",...options};
    return this._dialogComfirm.open(combinedOptions, content);
  }
  normal(options: DSDialogOptions = {}, content?:any) {
    const combinedOptions = {icon:"fas fa-info-circle",...options};
    return this._dialogComfirm.open(combinedOptions, content);
  }

  alert(options: DSDialogOptions = {}, content?:any) {
    const combinedOptions = {
      title:"Thông báo",
      buttons:[
        new ButtonDialog({label:"Đóng", id:0, style:"secondary", icon:"fas fa-times"}),
      ],
      ...options};
    return this.warning(combinedOptions, content);
  }
  alerComfirm(options: DSDialogOptions = {}, content?:any) {
    const combinedOptions = {
      title:"Thông báo",
      buttons:[
        new ButtonDialog({label:"Đóng", id:0, style:"secondary", icon:"fas fa-times"}),
        new ButtonDialog({label:"Xác nhận", id:1, style:"success", type: ButtonType.isOk, icon:"fas fa-check-circle"}),
      ],
      ...options};
    return this.warning(combinedOptions, content);
  }

  popup(options: DSDialogOptions = {}, content?:any) {
    const classes = ["dialog-popup"];
    const combinedOptions = {windowClass: classes.join(' '), icon:"fas fa-check-circle", ...options};
    return this._dialogComfirm.open(combinedOptions, content);
  }
}
