<ng-template #content let-modal>
  <div class="modal-header" *ngIf="title">
    <h4 class="modal-title" id="modal-basic-title">
      <i [class]="icon" aria-hidden="true"></i>
      {{title}}
    </h4>
    <button type="button" class="btn btn-outline-secondary btn-sm" aria-label="Close" (click)="modal.close({})">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="message">
   <div [innerHTML]="message"></div>
  </div>
  <div class="modal-footer py-1" *ngIf="buttons && buttons.length > 0">
    <button 
      type="button" 
      *ngFor="let item of buttons"
      class="{{cssClass(item,'btn')}}"
      (click)="modal.close(item)"
    > <i class="pe-1" [ngClass]="item.icon"></i> {{ item.label }}</button>
  </div>
</ng-template>