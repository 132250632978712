import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputValidateComponent } from './component/input-validate/input-validate.component';
import { InputComponent } from './component/input/input.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '../dialog';
import { MediaLibraryModule } from '../media-library/media-library.module';
import { BaseSelectComponent } from './base-select/base-select.component';
@NgModule({
  declarations: [InputValidateComponent, InputComponent, BaseSelectComponent],
  imports: [CommonModule, FormsModule, DialogModule, MediaLibraryModule],
  exports: [InputComponent, BaseSelectComponent],
})
export class InputModule {}
