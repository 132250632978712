<div class="container-fluid p-0">
  <!--Background top-->
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ textData.titleName }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">{{ textData.titleName }}</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<section class="security content-page">
  <div class="container">
    <div [innerHTML]="textData.longDescription"></div>
  </div>
</section>
