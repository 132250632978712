export class Anounment {
  public anounmentId :number;
  public fromDate :Date;
  public toDate :Date;
  public isViewMore:boolean;
  public isUseHtmlTemplate:boolean;
  public isPublish:boolean;
  public linkViewMore:string;
  public createdBy:Number;
  public createdDate :Date;
  public modifyBy:Number;
  public modifyDate :Date;
  public publishDate :Date;
}

export class AnounmentLang {
  public anounmentId :number;
  public languageId:string;
  public title :string;
  public notes :string;
  public imagePath :string;
  public htmlTemplate :string;
  public searchString :string;
}

export class AnounmentDTO extends Anounment{
  public title :string;
  public notes :string;
  public imagePath :string;
  public htmlTemplate :string;
  public searchString :string;
}

export class ReqAnounment{
  public row:Anounment = new Anounment;
  public rowLang:AnounmentLang = new AnounmentLang;
  public isNew:boolean;
}