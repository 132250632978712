<!-- Footer Section -->
<section class="page-footer">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-lg-4 col-md-6">
        <div class="text-center">
          <div class="logo-footer">
            <img
              width="575"
              height="174"
              loading="lazy"
              data-src="{{ static('assets/uploads/footer/logoFooter.png') }}"
              alt="Logo"
              style="width: 100%; cursor: pointer"
            />
          </div>
          <div class="map py-2">
            <iframe
              title="simmed map"
              async
              defer
              data-src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15678.02945008011!2d106.62483200000001!3d10.772399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x547764b75bad0370!2zUEjDkk5HIEtIw4FNIMSQQSBLSE9BIFNJTSBNRUQ!5e0!3m2!1svi!2sus!4v1652669998685!5m2!1svi!2sus"
              height="450"
              style="border: 0; width: 100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >
            </iframe>
          </div>
        </div>
        <br />
        <strong class="h5 text-uppercase">Mạng xã hội</strong>
        <hr style="width: 100%; border: 1px solid #fff" />
        <div class="d-flex align-items-center mb-3">
          <img src="/assets/images/zaoa.jpg" class="w-100" style="max-width: 90px; margin-right: 5px" alt="" />
          <div class="row social-footer">
            <div class="col-6">
              <div class="social-footer-item">
                <a href="https://www.facebook.com/PhongKhamSIMMED/" target="_blank"
                  ><img width="30" height="30" loading="lazy" data-src="/assets/images/icon/fb.svg" alt="Facebook" />
                  Facebook
                </a>
              </div>
            </div>
            <div class="col-6">
              <div class="social-footer-item">
                <a href="https://www.linkedin.com/company/simmed" target="_blank"
                  ><img width="30" height="30" loading="lazy" data-src="/assets/images/icon/in.svg" alt="Linkedin" />Linkedin
                </a>
              </div>
            </div>
            <div class="col-6">
              <div class="social-footer-item">
                <a href="https://www.youtube.com/channel/UC7LKeZk21dMa6F3OL_7ge6A" target="_blank"
                  ><img width="30" height="30" loading="lazy" data-src="/assets/images/icon/youtube.svg" alt="Youtube" />Youtube</a
                >
              </div>
            </div>
            <div class="col-6">
              <div class="social-footer-item">
                <a href="http://zalo.me/0899166538" target="_blank"
                  ><img loading="lazy" data-src="/assets/images/icon/zalo.svg" alt="Zalo" width="30" height="30" />Zalo</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 center">
        <strong class="h5 text-uppercase">THÔNG TIN</strong>
        <hr style="width: 100%; border: 1px solid #fff" />
        <ul>
          <li class="d-flex align-items-center">
            <i class="fas fa-map-marker-alt p-1 pe-2"></i>
            <label class="m-0">
              <span>{{ companyInfo['addresss']?.titleName }}</span> :
              <div class="label m-0" [innerHTML]="companyInfo['addresss']?.shortDescription"></div>
            </label>
          </li>
          <li class="d-flex align-items-center">
            <i class="fas fa-clock"></i>
            <label class="m-0">
              <span>{{ companyInfo['working_time']?.titleName }}</span
              >:
              <div class="label m-0" [innerHTML]="companyInfo['working_time']?.shortDescription"></div>
            </label>
          </li>
        </ul>
        <div class="row contact">
          <div class="col-sm-6">
            <ul>
              <li>
                <i class="fas fa-phone-square"></i>
                <label class="m-0">
                  <span>{{ companyInfo['hotline']?.titleName }}</span
                  >:
                  <div class="label m-0" [innerHTML]="companyInfo['hotline']?.shortDescription"></div>
                </label>
              </li>
              <li>
                <i class="fas fa-phone-alt"></i>
                <label class="m-0">
                  <span>{{ companyInfo['phone']?.titleName }}</span
                  >:
                  <div class="label m-0" [innerHTML]="companyInfo['phone']?.shortDescription"></div>
                </label>
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <ul>
              <li>
                <i class="fas fa-envelope"></i>
                <label class="m-0">
                  <span>{{ companyInfo['email']?.titleName }}</span
                  >:
                  <div class="label m-0" [innerHTML]="companyInfo['email']?.shortDescription"></div>
                </label>
              </li>
              <li>
                <i class="fab fa-internet-explorer pe-2"></i>
                <label class="m-0">
                  <span>{{ companyInfo['website']?.titleName }}</span
                  >:
                  <div class="label m-0" [innerHTML]="companyInfo['website']?.shortDescription"></div>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <br />
        <strong class="h5 text-uppercase">TẢI ỨNG DỤNG DR. SIMMED</strong>
        <hr style="width: 100%; border: 1px solid #fff" />
        <div class="install-app">
          <div class="qr-app">
            <img src="/assets/images/qrcode-drsimmed.svg" alt="dr.simmed" />
          </div>
          <div class="action">
            <a class="btn btn-app" href="https://play.google.com/store/apps/details?id=com.simmed.idoctor" target="_blank">
              <img width="20" height="20" loading="lazy" data-src="/assets/images/icons/androi-icon.svg" alt="Google Play" />
              Google Play
            </a>
            <a class="btn btn-app" href="https://apps.apple.com/us/app/dr-simmed/id6448910148" target="_blank">
              <img width="20" height="20" loading="lazy" data-src="/assets/images/icons/apple.png" alt="App Store" />
              App Store
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 page">
        <div class="row">
          <div class="col-lg-6 col-md-6 flex-column">
            <strong class="h5 text-uppercase">{{ 'menu.footer.services' | translate }}</strong>
            <hr style="width: 100%; border: 1px solid #fff" />
            <ul class="mb-3">
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/chuyen-khoa']">{{ 'menu.footer.department' | translate }}</a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/goi-kham']">{{ 'menu.footer.packages' | translate }}</a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/chuyen-khoa/bac-si-gia-dinh']">
                  {{ 'menu.footer.familyDoctor' | translate }}
                </a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/dat-lich-kham']">
                  {{ 'menu.footer.booking' | translate }}
                </a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/tuyen-dung']">
                  {{ 'menu.job' | translate }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-6">
            <strong class="h5 text-uppercase">{{ 'menu.footer.quickLink' | translate }}</strong>
            <hr style="width: 100%; border: 1px solid #fff" />
            <ul>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/gioi-thieu-sim-med']">{{ 'menu.footer.about' | translate }}</a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/suc-khoe-doanh-nghiep']">{{ 'menu.company' | translate }}</a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/chinh-sach-thanh-toan-hoan-tien']">{{ 'menu.faq.refund_policy' | translate }}</a>
              </li>
              <!-- <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]='["/bao-lanh-vien-phi"]'>{{'menu.faq.insurance' | translate}}</a>
              </li> -->
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/tin-tuc/tin-tuc-simmed']">{{ 'menu.footer.news' | translate }}</a>
              </li>
              <li>
                <i class="fas fa-angle-double-right"></i>
                <a [routerLink]="['/thong-tin-lien-he']">{{ 'menu.footer.contact' | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
        <hr style="width: 100%; border: 1px solid #fff" />
        <div class="col-lg-12 p-0 text-lg-left text-sm-start text-start">
          <div [innerHTML]="companyInfo['company_name']?.shortDescription"></div>
          <div [innerHTML]="companyInfo['company_tax']?.shortDescription"></div>
          <a href="http://online.gov.vn/Home/WebDetails/63949" target="_blank">
            <img
              style="max-width: 170px"
              width="170"
              height="64"
              loading="lazy"
              alt="Simmed Đã thông báo bộ công thương"
              data-src="/assets/images/icons/logoSaleNoti.webp"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- copyright -->
<div class="footer-copyright text-center py-2">
  <div class="container">
    <p>
      <em>{{ 'menu.footer.copyright' | translate }}</em>
    </p>
  </div>
</div>

<div class="social-float">
  <ul>
    <li>
      <a href="tel:+841900 2525 35" target="_blank">
        <img src="/assets/images/icons/phone.png" alt="1900 2525 35" />
      </a>
    </li>
    <!-- <li>
      <a href="https://zalo.me/0899166538" target="_blank">
        <img src="/assets/images/icons/zalo.png" alt="SIM MEDICAL CENTER" />
      </a>
    </li> -->
  </ul>
</div>
