export class QueryObj {
  [x: string]: any;
  public page:number = 1;
  public limit:number = 10;
  public search:string = "";
  public total:number=0;
  public time:number = new Date().getTime();

  constructor (){
    try {
      let page_limit = JSON.parse(localStorage.getItem("page_limit"));
      if(page_limit)
      this.limit = page_limit;
    } catch (error) {
      this.limit = 10;
    }
    
  }
}