import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class DepartmentService extends BaseService {
  //frontend
  public getData() {
    return this.get("home/GetServiceData");
  }
  public getDataID(query) {
    return this.get("home/GetServiceByID",query);
  }

  //backend
  public getDepartment(query) {
    return this.get("admin/GetDepartment", query);
  }
  public saveDepartment(body) {
    return this.post("admin/SaveDepartment", body);
  }
  public deleteDepartment(body) {
    return this.post("admin/DeleteDepartment", body);
  }
  public updateOrderDepartment(body) {
    return this.post("admin/UpdateOrderDepartment", body);
  }
}