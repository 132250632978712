import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  TranslateService as NGXTranslateService,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
// import { MetaService } from '@ngx-meta/core';
import { Observable, of } from 'rxjs';

import { ILang } from '../models/translates.interface';
import { UniversalStorage } from '@shared/storage/universal.storage';
import { Meta } from '@angular/platform-browser';

const LANG_LIST: ILang[] = [
  { code: 'vi', name: 'Tiếng Việt', culture: 'vi-VN' },
  { code: 'en', name: 'English', culture: 'en-US' },
];
const LANG_DEFAULT: ILang = LANG_LIST[0];
const STORAGE_LANG_NAME: string = 'mlangCode';

@Injectable({ providedIn: 'root' })
export class TranslatesService {
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private _document: any,
    @Inject(NGXTranslateService) private _translate: NGXTranslateService,
    private _meta: Meta,
    @Inject(UniversalStorage) private _appStorage: Storage,
  ) {
  }

  /**
   * Returns a translation instantly from the internal state of loaded translation.
   * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
   */
  public instant(key: string | Array<string>, interpolateParams?: Object){
    return this._translate.instant(key,interpolateParams);
  }

  public onLoad(){
    return new Promise((resolve)=>{
      this._translate.get("loading").subscribe((res: string) => {
        resolve(res);
      });
    });
  }

  public get(key: string | Array<string>, interpolateParams?: Object){
    new Promise((resolve, reject)=>{
      this._translate.get(key, interpolateParams).subscribe((res: string) => {
        resolve(res);
      });
    });
  }

  public initLanguage(): Promise<any> {
    return new Promise((resolve: Function) => {
      this._translate.addLangs(LANG_LIST.map((lang: ILang) => lang.code));
      const language: ILang = this._getLanguage();
      if (language) {
        this._translate.setDefaultLang(language.code);
      } else {
        this._translate.setDefaultLang(LANG_DEFAULT.code);
      }
      this._setLanguage(language);
      resolve();
    });
  }

  private _getLanguage(): ILang {
    let language: ILang = this._getFindLang(this._appStorage.getItem(STORAGE_LANG_NAME));
    if (language) {
      return language;
    }
    if (isPlatformBrowser(this._platformId)) {
      language = LANG_DEFAULT;
      //language = this._getFindLang(this._translate.getBrowserLang());
    }
    if (isPlatformServer(this._platformId)) {
      language = LANG_DEFAULT;
    }
    language = language || LANG_DEFAULT;
    this._appStorage.setItem(STORAGE_LANG_NAME, language.code);
    return language;
  }

  public getLanguage(): ILang {
    const language: ILang = this._getLanguage();
    return language;
  }

  private _getFindLang(code: string): ILang | null {
    return code ? LANG_LIST.find((lang: ILang) => lang.code === code) : null;
  }

  private _setLanguage(lang: ILang): void {
    this._translate.use(lang.code).subscribe(() => {
      this._meta.updateTag({property: 'og:locale', content :lang.culture}); 
      this._document.documentElement.lang = lang.code;
    });
  }

  public changeLang(code: string): void {
    const lang: ILang = this._getFindLang(code);
    if (!lang || lang.code === this._translate.currentLang) {
      return;
    }
    this._appStorage.removeItem(STORAGE_LANG_NAME);
    this._appStorage.setItem(STORAGE_LANG_NAME, lang.code);
    this._setLanguage(lang);
  }

  public getLangList(): Observable<ILang[]> {
    return of(LANG_LIST);
  }

  public getCurrentLang(): string {
    return this._translate.currentLang;
  }
}

export class CommonMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (
      params.key.match(/\w+\.\w+/) &&
      params.translateService.translations['vi'] &&
      !params.translateService.translations['vi'][params.key]
    ) {
      console.warn(`Thiếu dịch cho "${params.key}"`);
    }
    return params.key;
  }
}
