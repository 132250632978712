import { Injectable } from '@angular/core';
import { BaseService } from '../../core/BaseService';

@Injectable({ providedIn: 'root' })
export class PackageService extends BaseService {
  //frontend
  public getById(linkCode) {
    return this.get('package', {
      linkCode,
    });
  }
  public getListCategoryPackage() {
    return this.get('category-package');
  }
  public getListWithCategory(linkCode) {
    return this.get('package/category', {
      linkCode,
    });
  }
  //backend
  public getInital(params) {
    return this.get('admin/GetInitalPackage', params);
  }
  public getPackage(query) {
    return this.get('admin/GetPackages', query);
  }
  public savePackages(body) {
    return this.post('admin/SavePackages', body);
  }
  public deletePackage(body) {
    return this.post('admin/DeletePackage', body);
  }
  public updateOrderPackage(body) {
    return this.post('admin/UpdateOrderPackage', body);
  }
  /** */
  public getPackageDetail(packageId) {
    return this.get('admin/GetDetailPackage', { packageId });
  }
  public saveDetailPackage(body) {
    return this.post('admin/SaveDetailPackage', body);
  }
  public deletePackageDetail(body) {
    return this.post('admin/DeletePackageDetail', body);
  }
}
