import { Injectable } from "@angular/core";

export interface DSDialogOptions{
  /**
   * If `true`, the backdrop element will be created for a given modal.
   *
   * Alternatively, specify `'static'` for a backdrop which doesn't close the modal on click.
   *
   * Default value is `true`.
   */
   backdrop?: boolean | 'static';

  size?: 'sm' | 'lg' | 'xl' | string;
  /**
   * Callback right before the modal will be dismissed.
   *
   * If this function returns:
   * * `false`
   * * a promise resolved with `false`
   * * a promise that is rejected
   *
   * then the modal won't be dismissed.
   */
   beforeDismiss?: () => boolean | Promise<boolean>;

  /**
   * If `true`, the modal will be centered vertically.
   *
   * Default value is `false`.
   *
   * @since 1.1.0
   */
 centered?: boolean;
  /**
   * A custom class to append to the dialog window.
   */
  windowClass?: string;

  /**
   * Tiêu đề dialog
   */
  title?:string | any;

  /**
   * View Content nếu cần
   */
  content?:string | any;

  /**
   * message nếu cần
   */
   message?:string | any;

  /**
   * Kiểu dialog
   */
  type?:DialogType;

  /**
   * Danh sách nút
   */
  buttons?:ButtonDialog[] ;

  icon?:string;
}

@Injectable({providedIn: 'root'})
export class DSDialogConfig implements Required<DSDialogOptions>{
  icon: string;
  message: any;
  size: 'sm' | 'lg' | 'xl' | string;
  beforeDismiss: () => boolean | Promise<boolean>;
  centered: boolean = true;
  windowClass: string;
  backdrop: boolean | 'static' = true;

  title:string | any;
  content:string | any;
  type:DialogType.Info;
  buttons:ButtonDialog[] ;
}

export enum DialogType {
  Success,
  Error,
  Info,
  Warning
}

export class ButtonDialog{
  label?:string | any="Đóng";
  id?:number =0;
  data?:any = {};
  style?:'success' | 'warning' | 'danger' | 'info' | string = 'info';
  type?:ButtonType = ButtonType.isCancel;
  icon:string = "";
  constructor(payload: Partial<ButtonDialog>){
    for(const key in payload){
      if(this.hasOwnProperty(key)){
        this[key] = payload[key];
      }
    }
  }
}

export enum ButtonType {
  isCancel,
  isOk,
}