<div class="form-group" [class]="classRow">
  <label [class]="classlabel" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></label>

  <ng-container *ngIf="type == 'textarea'; else select">
    <textarea
      [rows]="rows"
      [cols]="cols"
      [(ngModel)]="model"
      [maxlength]="maxlength"
      [minlength]="minlength"
      [pattern]="pattern"
      [required]="required"
      (ngModelChange)="onChange($event)"
      (blur)="onTouch()"
      [class]="classinput"
      class="form-control"
      #input="ngModel"
      [ngClass]="{ 'is-submit': ngControl && ngControl.formDirective?.submitted }"
      [maxlength]="maxlength"
      [placeholder]="placeholder"
      [disabled]="disabled"
    ></textarea>
    <app-input-validate [control]="input" [parent]="ngControl" [name-control]="name"></app-input-validate>
  </ng-container>
  <ng-template #select>
    <ng-container *ngIf="type === 'select'; else search">
      <select
        [ngClass]="{ 'is-submit': ngControl && ngControl.formDirective?.submitted }"
        [class]="classinput"
        class="form-control"
        [(ngModel)]="model"
        [required]="required"
        ngDefaultControl
        [name]="name"
        (ngModelChange)="onChange($event)"
        (blur)="onTouch()"
        #input="ngModel"
        [disabled]="disabled"
      >
        <option [ngValue]="default.value ? default.value : null" [disabled]="default.disabled ? true : false" selected="selected" *ngIf="default">
          {{ default.text ? default.text : default }}
        </option>
        <option *ngFor="let item of list" [ngValue]="item[key] ? item[key] : item" [selected]="selectFunction(item)">
          <ng-container *ngTemplateOutlet="customOption || defaultOption; context: { $implicit: item }"></ng-container>
          <ng-template #defaultOption>{{ item.text ? item.text : item }}</ng-template>
        </option>
      </select>
      <app-input-validate [parent]="ngControl" [control]="input" [name-control]="name"></app-input-validate>
    </ng-container>
  </ng-template>
  <ng-template #search>
    <ng-container *ngIf="type === 'search'; else image">
      <input
        [maxlength]="maxlength"
        [minlength]="minlength"
        [min]="min"
        [max]="max"
        [pattern]="pattern"
        [required]="required"
        [type]="type"
        [(ngModel)]="model"
        [ngClass]="{ 'is-submit': ngControl && ngControl.formDirective?.submitted }"
        (ngModelChange)="onChange($event)"
        (blur)="onTouch()"
        [class]="classinput"
        class="form-control"
        #input="ngModel"
        [placeholder]="placeholder"
        [disabled]="disabled"
      />
      <app-input-validate [parent]="ngControl" [control]="input" [name-control]="name"></app-input-validate>
    </ng-container>
  </ng-template>
  <ng-template #image>
    <ng-container *ngIf="type === 'image'; else orther">
      <div class="control-img">
        <div class="review" [hidden]="model == null || model == ''">
          <img [src]="static(model)" class="img-fluid" (error)="errorHandler($event)" />
          <button type="button" *ngIf="model" class="img-remove" (click)="removeImage()">
            <i class="fa-solid fa-trash"></i>
          </button>
        </div>
        <div class="control text-center" [hidden]="model != null && model != ''">
          <img class="icon" src="/assets/images/Image.svg" alt="Image" /> <br />
          <strong class="title">{{ title }}</strong> <br />
          <span class="desc">{{ desc }}</span> <br />
          <button type="button" class="btn btn-success my-3" (click)="openChooseImage()">Chọn hình ảnh</button>
        </div>
      </div>
      <app-input-validate [parent]="ngControl" [control]="input" [name-control]="name"></app-input-validate>
      <app-dialog #inputMedia [id]="idMedia" class="dialog-model modal-album" size="xl" title="Chọn hình ảnh">
        <ng-template let-dialog>
          <div class="modal-body p-0">
            <app-album mode="" [open-nav]="false" #album [album]="albumid"> </app-album>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary btn-sm" (click)="dialog.close(album)">Chọn File</button>
            <button type="button" class="btn btn-outline-dark btn-sm" (click)="dialog.close()">hủy bỏ</button>
          </div>
        </ng-template>
      </app-dialog>
    </ng-container>
  </ng-template>
  <ng-template #orther>
    <input
      [maxlength]="maxlength"
      [minlength]="minlength"
      [min]="min"
      [max]="max"
      [pattern]="pattern"
      [required]="required"
      [type]="type"
      [(ngModel)]="model"
      [ngClass]="{ 'is-submit': ngControl && ngControl.formDirective?.submitted }"
      (ngModelChange)="onChange($event)"
      (blur)="onTouch()"
      [class]="classinput"
      class="form-control"
      #input="ngModel"
      [mask]="mask"
      [placeholder]="placeholder"
      [disabled]="disabled"
    />
    <app-input-validate [parent]="ngControl" [control]="input" [name-control]="name"></app-input-validate>
  </ng-template>
</div>
