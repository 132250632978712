<!-- trang đội ngữ bác sĩ -->
<div class="container-fluid p-0">
  <!--Background top-->
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top-double">
      <h1>{{ 'doctorPage.title' | translate }} {{ departmentName }}</h1>
      <!-- <hr /> -->
      <!-- <h1 *ngIf="doctorList.doctors[0]?.departmentId!=10 && doctorList.doctors[0]?.departmentId!=2" class="department-tile-top">{{departmentName}}
      </h1>
      <h1 *ngIf="doctorList.doctors[0]?.departmentId==10 || doctorList.doctors[0]?.departmentId==2" class="department-tile-top">{{'menu.departmentObgy' | translate}}
      </h1> -->
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">Đội ngũ bác sĩ</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="doctor-page py-4 content-page">
  <div class="container" [hidden]="!doctorList">
    <div class="row">
      <div class="col-lg-9 col-sm-12">
        <!-- <h4
          *ngIf="doctorList.doctors[0]?.departmentId != 9 && doctorList.doctors[0]?.departmentId != 7 && doctorList.doctors[0]?.departmentId != 8"
          class="title-top"
        >
          {{ departmentName }}
        </h4>
        <h4 *ngIf="doctorList.doctors[0]?.departmentId == 7" class="title-top">{{ 'doctorPage.labTest' | translate }}</h4>
        <h4 *ngIf="doctorList.doctors[0]?.departmentId == 8" class="title-top">{{ 'doctorPage.doctorCLS' | translate }}</h4>
        <h4 *ngIf="doctorList.doctors[0]?.departmentId == 9" class="title-top">{{ 'doctorPage.phamarcy' | translate }}</h4> -->
        <!--Thông tin bác sĩ-->
        <ng-container *ngFor="let row of doctorList.doctors; let index = index">
          <div *ngIf="0 === index % 2" class="doctor-background1">
            <div class="doctor">
              <div class="row">
                <div class="col-md-5 col-lg-4 doctor-img">
                  <img
                    class="glightbox"
                    [src]="static(row.imagePath)"
                    [@fade]="'active'"
                    alt="{{ row.imageSeodescription }}"
                    onError="this.src='/assets/images/thumb.png'"
                  />
                </div>
                <div class="col-md-7 col-lg-8 desktop1">
                  <!-- <div class="d-flex">
                    <h4 class="doctor-level text-uppercase">{{row.levelName}} &nbsp;</h4>
                    <h4 class="doctor-name text-uppercase ps-2">{{row.doctorName}}</h4>
                  
                  </div> -->
                  <h2 class="text-uppercase doctor-name">
                    <span class="doctor-level me-2">{{ row.levelName }}</span> {{ row.doctorName }}
                  </h2>
                  <div class="level">
                    <ul class="list-unstyled m-none">
                      <!-- <li> <i class="literacy fas fa-check-circle"></i> <strong class="literacy title">
                        {{row.diplomaTitle}}</strong></li> -->
                      <li>
                        <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.departmentTitle }}</strong>
                      </li>
                      <li>
                        <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.positionTitle }}</strong>
                      </li>
                    </ul>
                  </div>
                  <div class="short-content" [innerHTML]="row.shortDescription | safeHtml"></div>
                  <div class="btn-process text-center text-lg-left justify-content-center justify-content-lg-start d-sm-flex">
                    <a class="btn btn-booking m-1 mw-180 mx-auto mx-sm-1" [routerLink]="['/dat-lich-kham']" [queryParams]="{ doctor: row.linkCode }">
                      <i class="fas fa-calendar-alt me-2" aria-hidden="true"></i>
                      {{ 'buttons.booking' | translate }}
                    </a>
                    <a class="btn btn-viewmore m-1 mw-180 mx-auto mx-sm-1" [routerLink]="['/doi-ngu-bac-si/' + row.linkDepartment, row.linkCode]">
                      <i class="fas fa-info-circle me-2" aria-hidden="true"></i>
                      {{ 'buttons.readmore' | translate }}</a
                    >
                    <!-- <a class="btn btn-viewmore m-1 mw-180 mx-auto mx-sm-1" [routerLink]="['/bac-si', row.linkCode]">
                      <i class="fas fa-info-circle me-2" aria-hidden="true"></i>
                      {{ 'buttons.readmore' | translate }}</a
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="0 !== index % 2 && !viewMobile" class="doctor-background2">
            <div class="doctor">
              <div class="row">
                <div class="col-md-7 col-lg-8 desktop2">
                  <!-- <div class="d-flex">
                    <h4 class="doctor-level text-uppercase">{{row.levelName}} &nbsp;</h4>
                    <h4 class="doctor-name text-uppercase ps-2">{{row.doctorName}}</h4>
                  </div> -->
                  <h2 class="text-uppercase doctor-name">
                    <span class="doctor-level me-2">{{ row.levelName }}</span> {{ row.doctorName }}
                  </h2>
                  <div class="level">
                    <ul class="list-unstyled m-none">
                      <!-- <li> <i class="literacy fas fa-check-circle"></i> <strong class="literacy title">
                        {{row.diplomaTitle}}</strong></li> -->
                      <li>
                        <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.departmentTitle }}</strong>
                      </li>
                      <li>
                        <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.positionTitle }}</strong>
                      </li>
                    </ul>
                  </div>
                  <div class="short-content" [innerHTML]="row.shortDescription | safeHtml"></div>
                  <div class="btn-process text-center text-lg-left d-sm-flex justify-content-center justify-content-lg-start">
                    <a class="btn btn-booking m-1 mw-180 mx-auto mx-sm-1" [routerLink]="['/dat-lich-kham']" [queryParams]="{ doctor: row.linkCode }">
                      <i class="fas fa-calendar-alt me-2" aria-hidden="true"></i>
                      {{ 'buttons.booking' | translate }}</a
                    >
                    <a class="btn btn-viewmore m-1 mw-180 mx-auto mx-sm-1" [routerLink]="['/bac-si', row.linkCode]">
                      <i class="fas fa-info-circle me-2" aria-hidden="true"></i>
                      {{ 'buttons.readmore' | translate }}</a
                    >
                  </div>
                </div>
                <div class="col-md-5 col-lg-4 doctor-img">
                  <img
                    class="glightbox"
                    [src]="static(row.imagePath)"
                    [@fade]="'active'"
                    alt="{{ row.imageSeodescription }}"
                    onError="this.src='/assets/images/thumb.png'"
                  />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="0 !== index % 2 && viewMobile" class="doctor-background1">
            <div class="doctor">
              <div class="row">
                <div class="col-md-5 col-lg-4 doctor-img">
                  <img [src]="static(row.imagePath)" alt="{{ row.imageSeodescription }}" onError="this.src='/assets/images/thumb.png'" />
                </div>
                <div class="col-md-7 col-lg-8 desktop1">
                  <!-- <div class="d-flex">
                    <h4 class="doctor-level text-uppercase">{{row.levelName}} &nbsp;</h4>
                    <h4 class="doctor-name text-uppercase">{{row.doctorName}}</h4>
                  </div> -->
                  <h2 class="text-uppercase doctor-name">
                    <span class="doctor-level me-2">{{ row.levelName }}</span> {{ row.doctorName }}
                  </h2>
                  <div class="level">
                    <ul class="list-unstyled m-none">
                      <!-- <li> <i class="literacy fas fa-check-circle"></i> <strong class="literacy title">
                        {{row.diplomaTitle}}</strong></li> -->
                      <li>
                        <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.departmentTitle }}</strong>
                      </li>
                      <li>
                        <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.positionTitle }}</strong>
                      </li>
                    </ul>
                  </div>
                  <div class="short-content" [innerHTML]="row.shortDescription | safeHtml"></div>
                  <div class="btn-process text-center text-lg-left d-sm-flex justify-content-center justify-content-lg-start">
                    <a class="btn btn-booking m-1 mw-180 mx-auto mx-sm-1" [routerLink]="['/dat-lich-kham']">
                      <i class="fas fa-calendar-alt me-2" aria-hidden="true"></i>
                      {{ 'buttons.booking' | translate }}</a
                    >
                    <a class="btn btn-viewmore m-1 mw-180 mx-auto mx-sm-1" [routerLink]="['/bac-si', row.linkCode]">
                      <i class="fas fa-info-circle me-2" aria-hidden="true"></i>{{ 'buttons.readmore' | translate }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- bác sĩ liên quan-->
        <section class="doctor-related">
          <h2 class="title-top">{{ 'doctorPage.doctorList' | translate }}</h2>
          <div class="row">
            <div class="col-md-6 col-lg-4" *ngFor="let row of doctorList.doctorsRelated">
              <div class="category">
                <a [routerLink]="['/bac-si', row.linkCode]">
                  <img [src]="static(row.imageSmall)" alt="{{ row.imageSeotitle }}" class="img-fluid" onError="this.src='/assets/images/thumb.png'" />
                </a>
                <div class="doctor-title form-inline mt-2">
                  <a class="form-inline" [routerLink]="['/bac-si', row.linkCode]">
                    <h3>
                      <span class="doctor-level text-uppercase">{{ row.levelName }} &nbsp;</span>
                      <span class="doctor-name text-uppercase">{{ row.doctorName }}</span>
                    </h3>
                  </a>
                </div>
                <div class="content" [innerHTML]="row.shortDescription | safeHtml"></div>
                <div class="footer text-center pt-3">
                  <a class="btn btn-viewmore" [routerLink]="['/bac-si', row.linkCode]">{{ 'buttons.readmore' | translate }}</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!--Tin tức liên quan-->
      <div class="col-lg-3 col-sm-12">
        <h2 class="title-top">{{ 'newsPage.related' | translate }}</h2>
        <div class="news-title">
          <div class="news-related block-news" *ngFor="let row of doctorList.news">
            <div class="col-12">
              <div class="singer-news">
                <div class="news-related-title">
                  <a [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                    <h3>{{ row.titleName }}</h3>
                  </a>
                  <a [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                    <img
                      [src]="static(row.imageSmall)"
                      alt="{{ row.imageSeodescription }}"
                      class="img-fluid"
                      onError="this.src='/assets/images/thumb.png'"
                    />
                  </a>
                </div>
                <div class="news-related-content" [innerHTML]="row.shortDescription | safeHtml"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
