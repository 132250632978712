export class PageInfo {
  pageId: number;
  pageName: string = '';
  titleSeo: string = '';
  descriptionSeo: string = '';
  thumbnailSeo: string = '';
  searchString: string = '';
  pageType: string = 'static';
  pagePath: string = '';
  isActive: boolean = true;
  sortOrder: number = 0;

  constructor(item?) {
    this.pageId = item?.pageId;
    this.pageName = item?.pageName;
    this.titleSeo = item?.titleSeo;
    this.descriptionSeo = item?.descriptionSeo;
    this.thumbnailSeo = item?.thumbnailSeo;
    this.searchString = item?.searchString;
  }
}
