import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { SharedModule } from '@shared/share.module';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { EditorComponent } from './components/editor/editor.component';
import { MediaLibraryModule } from '../media-library/media-library.module';
import { ModalModule } from '../modal';
import { DialogModule } from '../dialog';

@NgModule({
  declarations: [EditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    MediaLibraryModule,
    DialogModule,
    EditorModule,    
  ],
  exports:[
    EditorComponent
  ],
  providers:[
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class CEditorModule { }
