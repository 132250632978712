import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatModalComponent } from './components/float-modal/float-modal.component';
import { BlockModule } from '../block/block.module';
import { FormsModule } from '@angular/forms';
import { FloatModalService } from './services/float-modal.service';

@NgModule({
  declarations: [FloatModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    BlockModule
  ],
  providers:[
    FloatModalService
  ],
  exports:[
    FloatModalComponent
  ]
})
export class FloatModalModule { }
