export class TextData {
  optionId: string;
  groupOption: string;
  imageSeotitle: string;
  imageSeodescription: string;
  createdBy: number;
  createdDate: Date;
  modifyBy: number;
  modifyDate: Date;
  publishBy: number;
  publishDate: Date;
  isPublish: boolean;
  sortOrder: number;
  isActive: boolean = true;
  languageId: string;
  titleName: string;
  shortDescription: string;
  longDescription: string;
  imageSmall: string;
  imageLarge: string;
  searchString: string;
}

export class TextDataLang {
  optionId: string;
  languageId: string;
  titleName: string;
  shortDescription: string;
  longDescription: string;
  imageSmall: string;
  imageLarge: string;
  searchString: string;
}