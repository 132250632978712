<div class="container-fluid p-0">
  <!--Background top-->
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <!-- <h1>{{'priceListPage.title' | translate}}</h1> -->
      <h1>{{ textData.titleName }}</h1>
    </div>
  </div>
</div>
<!-- product -->
<section class="price py-4">
  <div class="container">
    <div [innerHTML]="textData.longDescription"></div>
  </div>
  <div class="container">
    <div class="table-responsive text-nowrap table-sm py-3 desktop">
      <table class="table table-hover">
        <thead class="head-table">
          <tr>
            <th class="text-center" width="5%">{{ 'priceListPage.gridNumber' | translate }}</th>
            <th class="text-center" width="55%">{{ 'priceListPage.gridProductName' | translate }}</th>
            <th class="text-center" width="20%">{{ 'priceListPage.gridListPrice' | translate }}</th>
            <!-- <th class="text-center" width="20%">{{'priceListPage.gridSalePrice' | translate}}</th> -->
            <th *ngIf="showInsurancePrice.optionValue" class="text-center" width="20%">{{ 'priceListPage.gridInserancePrice' | translate }}</th>
          </tr>
        </thead>
        <tbody class="table-bordered">
          <tr *ngFor="let row of product; let index = index">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-uppercase">{{ row.productName }}</td>
            <td class="text-end number">{{ row.listPrice | number : 0 }}</td>
            <!-- <td class="text-end number">{{row.price | number:0}}</td> -->
            <td class="text-end number" *ngIf="showInsurancePrice.optionValue">{{ row.insurancePrice | number : 0 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive text-nowrap table-sm py-3 mobile">
      <table class="table table-hover">
        <thead class="head-table">
          <tr>
            <th class="text-center" width="8%">{{ 'priceListPage.gridNumber' | translate }}</th>
            <th class="text-center" width="48%">{{ 'priceListPage.gridMobileProductName' | translate }}</th>
            <th class="text-center" width="22%">{{ 'priceListPage.gridMobileListPrice' | translate }}</th>
            <!-- <th class="text-center" width="22%">{{'priceListPage.gridMobileSalePrice' | translate}}</th> -->
          </tr>
        </thead>
        <tbody class="table-bordered">
          <tr *ngFor="let row of product; let index = index">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-uppercase">{{ row.productName }}</td>
            <td class="text-end number">{{ row.listPrice | number : 0 }}</td>
            <!-- <td class="text-end number">{{row.price | number:0}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
