import { Injector, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppComponent } from './app.component';

//common
import { ServiceLocator } from './common/locator.service';

//module
import { FrontendModule } from './frontend/frontend.module';
import { SharedModule } from '@shared/share.module';

// lib
import { HttpClientModule } from '@angular/common/http';

//Backend
import { AppRoutingModule } from './app-routing.module';

import { AuthService } from '@shared/services/auth.service';
import { FeedbackComponent } from './frontend/pages/feedback/feedback.component';

@NgModule({
  declarations: [AppComponent, FeedbackComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'simmed-ui' }),
    AppRoutingModule,
    HttpClientModule,
    //lib
    SharedModule.forRoot(),
    FrontendModule,
  ],
  providers: [provideClientHydration(), AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector; // Dùng để INJECT Ở NƠI BẤT KỲ

    // translate.setDefaultLang(translate.getBrowserLang());
    //translate.use(translate.getBrowserLang());
  }
}
