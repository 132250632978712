import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class ProductService extends BaseService {
  //frontend
  public getData() {
    return this.get("home/GetProduct");
  }

  //backend
  public getProduct(param) {
    return this.get("admin/GetProduct", param);
  }
  public saveProduct(body) {
    return this.post("admin/SaveProduct",body);
  }
  public deleteProduct(body) {
    return this.post("admin/DeleteProduct",body);
  }
  public updateOrderProduct(body) {
    return this.post("admin/UpdateOrderProduct",body);
  }

  public import({files}) {
    return this.postFormData("admin/ImportProduct", {files});
  }

}