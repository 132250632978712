import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from "src/app/core/BaseComponent";
import { AboutService } from "@shared/services/about.service";
import { SEO } from "@shared/models/seo.model";
import { ResponeResult } from '@core/models/ResponeResult';
@Component({
  selector: 'app-abouts',
  templateUrl: './abouts.component.html',
  styleUrls: ['./abouts.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AboutComponent extends BaseComponent {
  public aboutDTO: AboutDTO = new AboutDTO;
  public urlLink: string | any;
  private linkCode: string | any;

  constructor(
    private aboutService:AboutService
  ) {   
    super();

    this.route.paramMap.subscribe(params => {
      this.linkCode = this.route.snapshot.paramMap.get("id");
      this.getData();
    });
  }

  ngAfterViewInit(){
    this.initLightbox();
  }


  ngOnInit() {
    this.seo.title = "Giới thiệu";
    this.updateSEO();
  }
  getData() {
    this.aboutService.getData({linkCode:this.linkCode}).then((result:ResponeResult) => {
      this.aboutDTO = result.repData;
    }, error => console.error(error));
  }
}

export class AboutDTO {
  abouts: About[] = [];
  doctors: Doctor[] = [];
}

export class About {
  titleName: string | any;
  contents: string | any;
  imagePath: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
}

export class Doctor {
  linkCode: string | any;
  linkDepartment: string | any;
  departmentCode: string | any;
  imagePath: string | any;
  imageSmall: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  levelName: string | any;
  doctorName: string | any;
  positionTitle: string | any;
  departmentTitle: string | any;
  diplomaTitle: string | any;
  shortDescription: string | any;
}
