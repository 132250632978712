import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class NewService extends BaseService {
  //frontend
  public getNew(query) {
    return this.get("home/GetNews", query);
  }
  public getNewID(query) {
    return this.get("home/GetNewsByID", query);
  }
  //backend
  public getInitalNews(query) {
      return this.get("admin/GetInitalNews", query);
  }
  public getNews(query) {
    return this.get("admin/GetNews", query);
  }
  public saveNews(body) {
    return this.post("admin/SaveNews", body);
  }
  public deleteNews(body) {
    return this.post("admin/DeleteNews", body);
  }
  //new related
  public getNewRelated(query) {
    return this.get("admin/GetNewRelated", query);
  }
  public getRelated(query) {
    return this.get("admin/GetRelated", query);
  }
  public saveRelated(body) {
    return this.post("admin/SaveRelated", body);
  }
  public deleteRelated(body) {
    return this.post("admin/DeleteRelated", body);
  }
  public updateOrderNews(body) {
    return this.post("admin/UpdateOrderNews", body);
  }
}