<div class="bg-light">
  <div class="container-fluid p-0">
    <div class="page-background-tt">
      <div class="col-12 text-center page-title-top">
        <strong [@fade]="'active'" style="color: #4d4d4d !important; font-size: 1.5rem; font-weight: 700; text-transform: uppercase">{{
          newsDTO.rowNew?.categoryName
        }}</strong>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
            <li class="breadcrumb-item"><a [routerLink]="'/tin-tuc/'">Tin tức</a></li>
            <li class="breadcrumb-item">
              <a [routerLink]="'/tin-tuc/' + newsDTO.rowNew?.linkCategory">{{ newsDTO.rowNew?.categoryName }}</a>
            </li>
            <li class="breadcrumb-item">{{ newsDTO.rowNew?.titleName }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>

  <div class="content-page py-4">
    <div class="container">
      <div class="row bg-white">
        <div class="col-lg-9 col-sm-12">
          <div class="col-lg-12 news-top">
            <strong [@fade]="'active'">{{ newsDTO.rowNew?.newsTitle }}</strong>
          </div>
          <!--TIN TỨC -->
          <div class="newsdetail">
            <div class="newsdetail-title">
              <div class="text pt-2">
                <h1 [@fade]="'active'" class="news-title-acticle float-left">{{ newsDTO.rowNew?.titleName }}</h1>
                <img src="/assets/images/general/border.png" />
              </div>
            </div>

            <div class="newsdetail-title-img text-center">
              <img class="glightbox" [src]="static(newsDTO.rowNew?.imageLarge)" alt="{{ newsDTO.rowNew.imageSeotitle }}" />
              <p class="text-center font-italic my-2">
                {{ newsDTO.rowNew?.imageSeodescription }}
              </p>
            </div>
            <div id="ds-toc-container">
              <a class="btn d-block" data-bs-toggle="collapse" href="#collapseToc" role="button">
                <i class="fa-regular fa-rectangle-list me-2"></i> Mục lục
              </a>
              <div class="collapse" id="collapseToc">
                <div class="card card-body"></div>
              </div>
            </div>
            <div class="fix-more" [ngClass]="{ 'show-more': isShowMore }">
              <div class="newsdetail-content" [@fade]="'active'" [innerHTML]="newsDTO.rowNew.longDescription | safeHtml"></div>
              <div class="view-more">
                <button (click)="readmore()">
                  <i class="fa-solid fa-angles-down fa-bounce"></i>
                  Đọc tiếp
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--Tin tức liên quan-->
        <div class="col-lg-3 col-sm-12 news-detail">
          <div class="news-title">
            <div class="col-lg-12 news-top">
              <h2>{{ 'newsPage.list' | translate }}</h2>
            </div>
            <div class="news-related" *ngFor="let row of newsDTO.newsRelateds">
              <div class="col-12">
                <div class="singer-news">
                  <div class="news-related-title">
                    <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.categoryCode, row.linkCode]">
                      <h5>{{ row.titleName }}</h5>
                    </a>
                    <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.categoryCode, row.linkCode]">
                      <img class="news-related-img" [src]="static(row.imageSmall)" alt="{{ row.imageSeotitle }}" />
                    </a>
                  </div>
                  <div class="news-related-content" [innerHTML]="row.shortDescription | safeHtml"></div>
                </div>
              </div>
            </div>
            <div class="news-related" *ngIf="!newsDTO.newsRelateds || newsDTO.newsRelateds.length == 0">
              <span class="text-light p-3">Đang cập nhật ...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
