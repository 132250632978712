import { Component, ContentChild, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import { CActiveDialog } from '../cdialog-ref';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() id: string | any;
  @Input() size: string | any = "md";
  @Input() title: string;

  private element: any;
  public msgTitle: string | any;
  public msgContent: string | any;

  public activeDialog:CActiveDialog;

  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

  constructor(private dialogService: DialogService, el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('dialog must have an id');
      return;
    }
    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    // document.body.appendChild(this.element);

    // add self (this dialog instance) to the dialog service so it's accessible from controllers
    this.dialogService.add(this);
  }

  // remove self from dialog service when component is destroyed
  ngOnDestroy(): void {
    this.dialogService.remove(this.id);
    this.element.remove();
  }

  // open dialog
  open(activeDialog: CActiveDialog): void {
    this.activeDialog = activeDialog;
    this.element.firstElementChild.style.display = 'block';
    this.element.firstElementChild.classList.add('show');
    document.body.classList.add('dialog-open');
    document.body.classList.add("modal-open");
  }

  close(){
    this.element.firstElementChild.style.display = 'none';
    this.element.firstElementChild.classList.remove('show');
    document.body.classList.remove('dialog-open');
    document.body.classList.remove("modal-open");
  }

  // close dialog
  dismiss(): void {
  }

  showAlertMsg(msgTitle: string, msgContent: string): void {
    this.element.style.display = 'block';
    document.body.classList.add('dialog-open');
  }

  closeAlertMsg(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('dialog-open');
  }
}
