import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@core/BaseComponent';
import { ResponeResult } from '@core/models/ResponeResult';
import { QueryObj } from '@shared/models/query.model';
import { SearchDTO } from '@shared/models/search.model';
import { BlockService } from '@shared/module/block/services/block.service';
import { SearchService } from '@shared/services/search.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent extends BaseComponent implements OnInit {

  active = 1; // tab nào đang được active

  public querySearch: QueryObj = new QueryObj;
  public searchDTO : SearchDTO = new SearchDTO;

  constructor(injector: Injector, private blockService: BlockService , private searchService: SearchService) { 
    super(injector);
    this.querySearch.search = this.route.snapshot.queryParams.q;
    this.seo.title = "Kết quả tìm kiếm cho : " + this.querySearch.search;
    this.updateSEO();
  }

  ngOnInit(): void {
    
  }

  onInit():void {
    this.getDataSearch();
  }

  getDataSearch(){
    this.searchDTO.isLoading = true;
    this.searchDTO.isNoData = true;
    this.blockService.load("search-result-box");
    this.searchService.search(this.querySearch).then((res : ResponeResult)=>{
      if(res.isOk)
      {
        this.searchDTO.setData(res.repData);
        if(this.searchDTO.packages.length == 0)
        {
          this.active = 2;
        }
        if(this.searchDTO.doctors.length == 0 && this.searchDTO.packages.length == 0)
        {
          this.active = 3;
        }

        this.searchDTO.isLoading = false;
        this.searchDTO.isNoData = this.searchDTO.doctors.length ==0 && this.searchDTO.packages.length == 0 && this.searchDTO.news.length == 0;
      }
    }).catch().finally(()=>{
      this.blockService.unload("search-result-box");
    });
  }
}
