import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  url:string = "";
  constructor() { }

  ngOnInit(): void {
    this.url = `http://www.africau.edu/images/default/sample.pdf`;
  }

}
