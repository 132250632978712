<div class="pagination" *ngIf="count > 0">
  <form [formGroup]='myForm' class="row mt-3" style="flex-direction: initial;">
    <div class="col-xs-4 description text-start">
      <div class="d-flex">
        <div class="number" style="text-align:right;"><label>Số dòng</label></div>
        <div class="ms-2">
          <select class="form-control form-control-sm" name="cars" formControlName="limit" style="width:65px"
            (change)="onChangeLimit()">
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="300">300</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-xs-8 number d-flex p-0">
      <div class="ms-auto">
        <label *ngIf="perPage*page <= count ; else labelPer">
          {{perPage * (page - 1) + 1}} - {{perPage * page}} trong số {{ count }}
        </label>
        <ng-template #labelPer>
          <label> {{perPage * (page - 1) + 1}} - {{count}} trong số {{ count }} </label>
        </ng-template>
        <button class="btn btn-sm" (click)="onPrev()" [disabled]="page === 1 || loading"
          [ngClass]="{ 'disabled': page === 1 || loading }">
          &laquo;
        </button>
        <button class="btn btn-sm btnnumber" *ngFor="let pageNum of getPages()" (click)="onPage(pageNum)"
          [ngClass]="{'active': pageNum === page, 'disabled': loading}">
          {{ pageNum }}
        </button>
        <button class="btn btn-sm" (click)="onNext(true)" [disabled]="lastPage() || loading"
          [ngClass]="{ 'disabled': lastPage() || loading }">
          &raquo;
        </button>
      </div>
    </div>
  </form>
</div>