export class Package {
  categoryCode: string | any;
  linkCode: string | any;
  listPrice: number | any;
  salePrice: number | any;
  fromDate: Date | any;
  toDate: Date | any;
  publishDate: Date | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  packageName: string | any;
  titleName: string | any;
  shortDescription: string | any;
  imagePath: string | any;
  isApplyDate: boolean;
  isHost: boolean;
  isDisccount: boolean;
  disccountText: string;
  nameCategory: string | any;

  packageId: number | any;
  packageCode: string | any;
  quantity: number | any;
  imageSEOTitle: string | any;
  imageSEODescription: string | any;
  departmentName: string | any;
  longDescription: string | any;
  doctorNotes: string | any;
  ultrasoundNotes: string | any;
  labNotes: string | any;
  termconditionNotes: string | any;

  groupServices: GroupService[] = [];
}

export class PackageDTO {
  rowPackage: Package = new Package();
  packages: Package[] = [];
}

export class PackageCategory {
  linkCode: string | any;
  categoryName: string | any;
  packageCategoryId: number;

  constructor(item?: any) {
    this.categoryName = item?.categoryName ? item.categoryName : 'Tất cả';
    this.linkCode = item?.linkCode ? item.linkCode : '';
    this.packageCategoryId = item?.packageCategoryId
      ? item.packageCategoryId
      : 0;
  }
}

export class GroupService {
  groupId: number | null = null;
  groupName: string | null = null;
  services: Service[] = [];
  sortOrder: number = 1;
}
export class Service {
  description: '3';
  groupId: 3;
  isPackage: false;
  serviceDetails: ServiceDetail[] = [];
  serviceId: 5;
}
export class ServiceDetail {
  serviceGroupId: number | null = null;
  serviceId: number | null = null;
  serviceName: string = '';
  sortOrder: number = 0;
}
