import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ServiceLocator } from '../common/locator.service';
import { SEO } from '@shared/models/seo.model';
import { Tag, TagType } from '@shared/models/tags.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PageService } from '@shared/services/page.service';
import { ResponeResult } from './models/ResponeResult';
import { PageInfo } from '@shared/models/page.model';
import { RoleService } from '@shared/services/role.service';
import stringUtil from '@shared/utils/string.util';

import { Lightbox, LightboxEvent } from 'ngx-lightbox';
import { debounceTime, first, of, Subscription } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class BaseComponent {
  public API_URL: string = '';
  public STATIC_URL: string = '';
  public schema: any;

  protected route: ActivatedRoute;
  protected title: Title;
  protected meta: Meta;
  protected http: HttpClient;
  protected router: Router;
  public dom;
  public platform: Object;
  public seo: SEO;
  public isPageOverrideSeo: boolean = false;
  public pageSevice: PageService;
  public roleService: RoleService;
  public isBrowser: boolean;
  protected _albums = [];
  private _lightbox: Lightbox;
  _lightboxEvent: LightboxEvent;
  private _subscription: Subscription;

  constructor(private injectorObj?: Injector, seo: boolean = true) {
    if (this.injectorObj) {
      this.route = this.injectorObj.get(ActivatedRoute);
      this.title = this.injectorObj.get(Title);
      this.meta = this.injectorObj.get(Meta);
      this.http = this.injectorObj.get(HttpClient);
      this.router = this.injectorObj.get(Router);
      this.API_URL = environment.API_URL;
      this.STATIC_URL = environment.STATIC_URL;
      this.dom = this.injectorObj.get(DOCUMENT);
      this.platform = this.injectorObj.get(PLATFORM_ID);
      this.pageSevice = this.injectorObj.get(PageService);
      this.roleService = this.injectorObj.get(RoleService);
      this._lightbox = this.injectorObj.get(Lightbox);
      this._lightboxEvent = this.injectorObj.get(LightboxEvent);

      this.isBrowser = isPlatformBrowser(this.platform);
    } else {
      this.injectorObj = ServiceLocator.injector;
      this.route = ServiceLocator.injector.get(ActivatedRoute);
      this.title = ServiceLocator.injector.get(Title);
      this.meta = ServiceLocator.injector.get(Meta);
      this.http = ServiceLocator.injector.get(HttpClient);
      this.router = ServiceLocator.injector.get(Router);
      this.API_URL = environment.API_URL;
      this.dom = ServiceLocator.injector.get(DOCUMENT);
      this.platform = ServiceLocator.injector.get(PLATFORM_ID);
      this.pageSevice = ServiceLocator.injector.get(PageService);
      this.roleService = ServiceLocator.injector.get(RoleService);
      this._lightbox = ServiceLocator.injector.get(Lightbox);
      this._lightboxEvent = ServiceLocator.injector.get(LightboxEvent);
      this.isBrowser = isPlatformBrowser(this.platform);
    }
    if (seo) {
      this.seo = new SEO();
      this.seo.description = 'Phòng khám y khoa';
      this.seo.keywords =
        'Y Khoa, Y học, Y Tế, Khám Sản, Khám Nhi, Khám Nội, Khám ngoại, Khám bệnh, Khám';
      this.initSEO();
      this.pageSevice
        .noValid()
        .getInfo(this.router.url)
        .then((res: ResponeResult) => {
          if (res.repData && res.repData.length > 0) {
            let pageInfo = new PageInfo(res.repData[0]);
            this.seo.title = pageInfo.titleSeo;
            this.seo.title = stringUtil.capitalize(this.seo.title);
            this.seo.description = pageInfo.descriptionSeo;
            this.seo.tags.push(<Tag>{
              type: TagType.property,
              property: 'og:image',
              content: 'https://api.simmed.vn/' + pageInfo.thumbnailSeo,
            });
            this.isPageOverrideSeo = true;
            this.initSEO();
          }
        })
        .catch(() => {});
    }
    this._albums = [];
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.scrollTop();
    });
  }

  //#region SEO
  private initSEO(isCanonicalURL?: boolean) {
    this.seo.title = stringUtil.ucFirst(this.seo.title);
    this.title.setTitle(this.seo.title);
    this.meta.updateTag({ property: 'og:image:alt', content: this.seo.title });
    this.meta.updateTag({ property: 'og:title', content: this.seo.title });
    this.meta.updateTag({ name: 'title', content: this.seo.title });
    this.meta.updateTag({ name: 'description', content: this.seo.description });
    this.meta.updateTag({ name: 'keywords', content: this.seo.keywords });
    this.meta.updateTag({ property: 'og:site_name', content: this.seo.title });
    this.meta.updateTag({
      property: 'og:description',
      content: this.seo.description,
    });
    const canURL = this.dom.URL;
    this.meta.updateTag({ property: 'og:url', content: canURL });
    this.seo.tags.forEach((tag) => {
      if (tag.type == TagType.property) {
        this.meta.updateTag({ property: tag.property, content: tag.content });
      } else {
        this.meta.updateTag({ name: tag.name, content: tag.content });
      }
    });
    if (isCanonicalURL) {
      this.initCanonicalURL();
    }
  }

  public updateSEO(isCanonicalURL?: boolean) {
    this.seo.title = stringUtil.ucFirst(this.seo.title);
    this.seo.title += ' - Phòng Khám Sim Med';

    if (!this.isPageOverrideSeo) {
      this.initSEO(isCanonicalURL);
    } else {
      if (this.title.getTitle() == '') {
        this.title.setTitle(this.seo.title);
        this.meta.updateTag({ property: 'og:title', content: this.seo.title });
        this.meta.updateTag({
          property: 'og:site_name',
          content: this.seo.title,
        });
      }
      if (isCanonicalURL) {
        this.initCanonicalURL();
      }
    }
  }

  public initCanonicalURL() {
    this.setCanonicalURL();
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    this.updateCanonicalUrl(canURL);
    this.meta.updateTag({ property: 'og:url', content: canURL });
  }
  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
  //#endregion

  scrollTop() {
    if (isPlatformBrowser(this.platform)) {
      // here you can run any browser specific code, like:
      this.mysetTimeout$(() => {
        window.scroll(0, 0);
      }, 100);
    }
  }

  public access(module: string, action = 'view') {
    if (!this.roleService.isAccess(module, action)) {
      this.router.navigate(['quan-tri/401']);
    }
  }

  public function_access(module: any, action = 'view') {
    let a = this.roleService.isAccess(module, action);
    if (!a) {
      return false;
    } else {
      return true;
    }
  }

  public function_check_access(module: string, action = 'view') {
    let a = this.roleService.isAccessCheck(module, action);
    if (!a) {
      return false;
    } else {
      return true;
    }
  }

  public static(file = '') {
    return environment.STATIC_URL + file;
  }

  //#region setup glightbox
  initLightbox() {
    if (this.isBrowser) {
      let self = this;
      setTimeout(() => {
        let imgs = document.getElementsByClassName('glightbox');
        this._albums = [];
        for (let i = 0; i < imgs.length; i++) {
          const element = imgs[i];
          this._albums.push({
            src: element.getAttribute('src'),
            caption: element.getAttribute('alt'),
            thumb: element.getAttribute('thumb'),
          });
          element.addEventListener('click', function () {
            self.openLightbox(i);
          });
        }
      }, 1000);
    }
  }

  public openLightbox(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index, {
      wrapAround: true,
      showImageNumberLabel: true,
      showZoom: true,
      showRotate: true,
      centerVertically: true,
      fitImageInViewPort: true,
      disableScrolling: true,
    });
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event) =>
      this._onReceivedEvent(event)
    );
  }

  private _onReceivedEvent(event: any): void {
    // remember to unsubscribe the event when lightbox is closed
    console.log('EVENT LIGHTBOX ', event);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  //#endregion

  public hostUrl(file = '') {
    return environment.hostUrl + file;
  }

  ucfirst(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  mysetTimeout$(cb: () => void, timer: number) {
    of(true).pipe(debounceTime(timer), first()).subscribe(cb);
  }
}
