import { PagingComponent } from './components/paging/paging.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './components/upload/upload.component';
import { AlbumComponent } from './components/album/album.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from '../modal';
import { BlockModule } from '../block/block.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { ImageLazyModule } from '../image-lazy/image-lazy.module';
import { MediaItemComponent } from './components/media-item/media-item.component';



@NgModule({
  declarations: [UploadComponent, AlbumComponent, DialogComponent, PagingComponent, MediaItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ModalModule,
    BlockModule,
    ReactiveFormsModule,
    ImageLazyModule
  ],
  exports:[
    AlbumComponent,
    UploadComponent
  ]
})
export class MediaLibraryModule { }
