import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { DialogComfirmComponent } from './dialog-comfirm/dialog-comfirm.component';

@NgModule({
  declarations: [DialogComponent, DialogComfirmComponent],
  imports: [
    CommonModule
  ],
  exports:[DialogComponent, DialogComfirmComponent]
})
export class DialogModule { }
