import { Component } from '@angular/core';
import { BaseComponent } from "src/app/core/BaseComponent";
import { TextdataService } from "@shared/services/textdata.service";
import { ResponeResult } from "@core/models/ResponeResult";
import { TextData } from "@shared/models/textdata.model";
@Component({
  selector: 'app-security',
  templateUrl: './security.component.html'
})
export class SecurityComponent extends BaseComponent {
  textData:TextData = new TextData;
  
  constructor(private textDataService:TextdataService) {
    super();
  }

  ngOnInit() {
   
    this.seo.title = "CHÍNH SÁCH BẢO MẬT";
    this.updateSEO();
    this.getData();
  }

  getData(){
    this.textDataService.noValid().getTextDataByID("CHINH-SACH-BAO-MAT").then((res: ResponeResult)=>{
      this.textData = res.repData;
      this.seo.title = this.textData.titleName;
      this.seo.description = this.textData.shortDescription;
      this.updateSEO();
    }).catch().finally(()=>{
  
    })
  }
}
