import { Component } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CartItem } from 'src/app/common/models/cartItem';
import { NotiService } from '@shared/module/notification/notification.service';
import { BaseComponent } from "@core/BaseComponent";
import { CartService } from "@shared/module/cart/services/cart.service";
import { Store } from "@shared/models/store.model";
import { DialogService } from "@shared/module/dialog";
import { ButtonDialog, ButtonType } from "@shared/module/dialog/dialog-config";
import { TranslatesService } from "@shared/module/translate";

@Component({
  selector: 'app-shopping',
  templateUrl: './shopping.component.html',
  styleUrls: ['./shopping.component.css']
})

export class ShoppingComponent extends BaseComponent {
  submitted: boolean = false;
  stores: Store[];
  isPayment: boolean = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(
    public cartService: CartService,
     private notiService: NotiService,
      private dialogService: DialogService,
      private translate: TranslatesService
      ) {
    super();
  }

  ngOnInit() {
    this.title.setTitle('Giỏ hàng - Phòng khám Sim Med');
    this.getData();
    if (this.cartService.value.cartItems.length > 0)
      this.isPayment = true;
    else
      this.isPayment = false;
  }

  getData() {
    let params = new HttpParams();
    params = params.append("isActive", "1");
    this.http.get<any>(this.API_URL + 'home/GetStore', { params: params }).subscribe(result => {
      if (result.isOk) {
        this.stores = result.repData;
      }
      else {
        //Show alert error
      }
    }, error => console.error(error));
  }

  changeStore(event: Event) {
  }

  addQuantity(step = 1, rowPackage) {
    rowPackage.quantity += step;
    if (rowPackage.quantity < 1)
    {
      rowPackage.quantity = 1;
      this.removeItemShoppingCart(rowPackage);
        
    }else{
      this.cartService.changeQuantity(rowPackage);
    }
  }

  removeItemShoppingCart(item: CartItem) {
    
    this.dialogService.alerComfirm({centered:true,
      message:this.translate.instant("shopping.messages.remove_item"),
      buttons:[
        new ButtonDialog({label:this.translate.instant("buttons.cancel"), id:0, style:"secondary", icon:"fas fa-times"}),
        new ButtonDialog({label:this.translate.instant("buttons.agree"), id:1, style:"danger", type: ButtonType.isOk, icon:"fas fa-trash"}),
      ],
    }).result.then((result:ButtonDialog) => {
      if(result.id == ButtonType.isOk)
      {
        this.cartService.remove(item);
        this.notiService.success(this.translate.instant("shopping.notify.remove_success"), this.options);
        if (this.cartService.value.cartItems.length > 0)
          this.isPayment = true;
        else
          this.isPayment = false;
      }
    });
  }

  removeShoppingCart() {
    this.dialogService.alerComfirm({centered:true,
      message:this.translate.instant("shopping.messages.remove_all"),
      buttons:[
        new ButtonDialog({label:this.translate.instant("buttons.cancel"), id:0, style:"secondary", icon:"fas fa-times"}),
        new ButtonDialog({label:this.translate.instant("buttons.agree"), id:1, style:"danger", type: ButtonType.isOk, icon:"fas fa-trash"}),
      ],
    }).result.then((result:ButtonDialog) => {
      if(result.id == ButtonType.isOk)
      {
        this.cartService.clearAllItem();
        this.notiService.success(this.translate.instant("shopping.notify.remove_success"), this.options);
        if (this.cartService.value.cartItems.length > 0)
          this.isPayment = true;
        else
          this.isPayment = false;
      }
    });
  }
}


