/**
 * A reference to the newly opened modal returned by the `NgbModal.open()` method.
 */
export class CDialogRef {
  private _resolve: (result?: any) => void;
  private _reject: (reason?: any) => void;

  constructor (){
    this.result = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this.result.then(null, () => {});
  }
  /**
   * The promise that is resolved when the modal is closed and rejected when the modal is dismissed.
   */
  result: Promise<any>;

  /**
   * Closes the modal with an optional `result` value.
   *
   * The `NgbMobalRef.result` promise will be resolved with the provided value.
   */
  close(result?: any): void {
    this._resolve(result);
  }
}

export class CActiveDialog {
  close(result?: any): void {}
  dismiss(reason?: any): void {}
}