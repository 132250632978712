import { AuthenParams } from "@app/common/models/AuthenParams";

export class OptionSystem {
  optionId:string = "";
  optionName:string = "";
  optionValue:string = "";
  dataType:string = "";
  notes:string = "";

  constructor(optionId:string="",optionName:string="", optionValue:string ="", dataType:string=""){
    this.optionId = optionId;
    this.optionName = optionName;
    this.optionValue = optionValue;
    this.dataType = dataType;
  }
}

export class ReqOptionSystem extends AuthenParams{
  rowData:OptionSystem = new OptionSystem();
  isNew:boolean = true;
}

export const OPTION_MENU: string = "OPTIONMENUWEBSITE";
export const SHOW_INSERANCE_PRICE: string = "SHOW_INSERANCE_PRICE";
