import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from "src/app/core/BaseComponent";
import { JobService } from "@shared/services/job.service";
import { ResponeResult } from "@core/models/ResponeResult";

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class JobComponent extends BaseComponent {
  public urlLink: string | any;
  private linkCode: string | any;
  public jobs: Job[];

  constructor(
    public route: ActivatedRoute,
    public jobService: JobService
    ) {
	super();
    
    this.route.paramMap.subscribe(params => {
      this.linkCode = this.route.snapshot.paramMap.get("id");
      this.getData();
    });
  }

  ngOnInit() {
    this.seo.title = "Tuyển dụng";
    this.updateSEO();
  }

  getData() {
    this.jobService.getData({linkCode:this.linkCode}).then((result:ResponeResult)=>{
      if (result.isOk) {
        this.jobs = result.repData;
      }
    }, error => console.error(error));
  }
}

export class Job {
  linkCode: string | any;
  publishDate: Date | any;
  titleName: string | any;
  shortDescription: string | any;
  imageSmall: string | any;
}
