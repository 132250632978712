export class MomoResponse {
  public partnerCode:string=""; //	√	Thông tin tích hợp
  public accessKey:string="";//	√	Thông tin tích hợp
  public requestId:string="";//	√	Định danh mỗi request
  public amount:string="";//	√	Số tiền thanh toán
  public orderId:string="";//	√	Mã đơn hàng của đối tác
  public orderInfo:string="";//	√	Thông tin đơn hàng
  public orderType:string="";//	√	momo_wallet
  public requestType:string="";
  public transId:string="";//	√	Mã giao dịch của MoMo
  public resultCode:number=0;	//√	Trạng thái thanh toán của đơn hàng
  public message:string="";//	√	Mô tả lỗi bằng tiếng Anh
  public localMessage:string="";//	√	Mô tả lỗi bằng tiếng Việt
  public payType:string="";//	√	Hình thức thanh toán: web or qr
  public responseTime:string="";//	√	Thời gian trả kết quả thanh toán về đối tác Định dạng: YYYY-MM-DD HH:mm:ss Múi giờ: GMT +7
  public extraData:string="";//      Thông tin thêm
  public signature:string="";//	√	partnerCode=$partnerCode&accessKey=$accessKey &requestId=$requestId &amount=$amount&orderId=$orderId &orderInfo=$orderInfo &orderType=$orderType&transId=$transId &message=$message&localMessage=$localMessage
  //&responseTime=$responseTime &errorCode=$errorCode &payType=$payType&extraData=$extraData
  constructor(payload: Partial<MomoResponse>){
    for(const key in payload){
      if(this.hasOwnProperty(key)){
        this[key] = payload[key];
      }
    }
  }
}