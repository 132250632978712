
export class CartItem {
  categoryCode:string;
  linkCode:string;

  packageId: number| any;
  packageCode: string | any;
  packageName: string | any;
  imagePath: string | any;
  fromDate: Date| any;
  toDate: Date| any;
  publishDate: Date| any;
  quantity: number| any = 0;
  listPrice: number| any= 0;
  salePrice: number| any = 0;
  amount: number| any = 0;
  longDescription:string = "";
  shortDescription:string = "";

  Notes:string="";
  discountAmount: number| any = 0;
  totalAmount: number| any = 0;

  isApplyDate: boolean;
  isHot: boolean;
  sortOrder: number| any;
  constructor(item?:any){
    if(item){
      this.packageId = item.packageId;
      this.packageCode = item.packageCode;
      this.packageName = item.packageName;
      this.imagePath = item.imagePath;
      this.fromDate = item.fromDate;
      this.toDate = item.toDate;
      this.publishDate = item.publishDate;
      this.quantity = item.quantity?item.quantity:0;
      this.listPrice = item.listPrice?item.listPrice:0;
      this.salePrice = item.salePrice?item.salePrice:0;
      this.longDescription = item.longDescription?item.longDescription:"";
      this.shortDescription = item.shortDescription?item.shortDescription:"";
      this.isApplyDate = item.isApplyDate;
      this.isHot = item.isHot;
      this.sortOrder = item.sortOrder;
      this.linkCode = item.linkCode;
      this.categoryCode = item.categoryCode;
    }
  }
}

export class Customer{
  id:number;
  customerName: string | any;
  customerBirthday: Date| any;
  customerPhone: string | any;
  customerAddress: string | any;
  customerEmail: string | any;
  constructor (){
      this.id = 0;
      this.customerName = "";
      this.customerBirthday = null;
      this.customerPhone = "";
      this.customerAddress = "";
      this.customerEmail = "";
  }
}

export class CartSummary{
  amount: number| any;
  quantity: number| any;
  discountAmount: number| any;
  totalAmount: number| any;

  constructor(){
    this.amount = 0;
    this.quantity =0;
    this.discountAmount = 0;
    this.totalAmount = 0;
  }
}

export class Cart {
  orderId:number|any = 0;
  tabselect : number| any;
  step: number| any;
  storeId: number| any;
  note: string | any;
  paymentType: number| any;
  paymentName: string | any;
  createdDate: Date| any;

  summary: CartSummary | any;
  customer:Customer |any;
  cartItems: CartItem[];

  constructor(){
    this.storeId = null;
    this.orderId = 0;
    this.step = 1;
    this.tabselect = 1;
    this.note = "";
    this.paymentType = 1;
    this.createdDate = new Date;
    this.summary = new CartSummary();
    this.customer = new Customer();
    this.cartItems = [];
  }
}
