import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FronendAppComponent } from '@app/frontend/layout/frontend/frontend-app.component';
import { HomeComponent } from './pages/home/home.component';
import { PackageComponent } from './pages/package/package.component';
import { PackageDetailComponent } from './pages/package-detail/package-detail.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServiceDetailsComponent } from './pages/servicedetails/servicedetails.component';
import { DoctorComponent } from './pages/doctors/doctors.component';
import { DoctorDetailComponent } from './pages/doctordetail/doctordetail.component';
import { BookingComponent } from './pages/booking/booking.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news-detail/newsdetail.component';
import { AboutComponent } from './pages/abouts/abouts.component';
import { JobComponent } from './pages/job/job.component';
import { JobDetailComponent } from './pages/job-detail/job-detail.component';
import { FaqAnswerComponent } from './pages/faq-answer/faq-answer.component';
import { TermConditionComponent } from './pages/termcondition/termcondition.component';
import { SecurityComponent } from './pages/security/security.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ShoppingComponent } from './pages/shopping/shopping.component';
import { PayComponent } from './pages/pay/pay.component';
import { ProductComponent } from './pages/product/product.component';
import { BusinessComponent } from './pages/business/business.component';
import { InsurranceComponent } from './pages/insurrance/insurrance.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { OtherPageComponent } from './pages/other-page/other-page.component';
import { PageNotFoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

export const routes: Routes = [
  {
    path: '',
    component: FronendAppComponent,
    children: [
      {
        path: 'quan-tri',
        redirectTo: '/quan-tri/dashboard',
        pathMatch: 'full',
      },
      { path: 'dang-nhap', redirectTo: '/auth/login', pathMatch: 'full' },
      //1.Trang home
      { path: '', component: HomeComponent, pathMatch: 'full' },
      //2. Gói khám
      { path: 'goi-kham', component: PackageComponent },
      { path: 'goi-kham/:id', component: PackageComponent },
      { path: 'goi-kham/:pc/:id', component: PackageDetailComponent },
      //3.Dịch vụ
      { path: 'chuyen-khoa', component: ServicesComponent },
      { path: 'chuyen-khoa/:id', component: ServiceDetailsComponent },
      //4.Đội ngũ bác sĩ
      { path: 'doi-ngu-bac-si/:id', component: DoctorComponent },
      { path: 'doi-ngu-bac-si/:pc/:id', component: DoctorDetailComponent },
      { path: 'bac-si/:id', component: DoctorDetailComponent },
      //5.Đặt lịch khám
      { path: 'dat-lich-kham', component: BookingComponent },
      //6.Tin tức
      { path: 'tin-tuc', component: NewsComponent },
      { path: 'tin-tuc/:id', component: NewsComponent },
      { path: 'tin-tuc/:pc/:id', component: NewsDetailComponent },
      { path: 'tin-tuc/:pc/:id/:mobile', component: NewsDetailComponent },
      //7.Giới thiệu
      { path: 'gioi-thieu-sim-med', component: AboutComponent },
      //8.FAQs
      { path: 'tuyen-dung', component: JobComponent },
      { path: 'tuyen-dung/:id', component: JobDetailComponent },
      { path: 'bac-si-tra-loi', component: FaqAnswerComponent },
      { path: 'bao-lanh-vien-phi', component: InsurranceComponent },
      { path: 'chinh-sach-hoat-dong', component: TermConditionComponent },
      { path: 'chinh-sach-bao-mat', component: SecurityComponent },
      //9.Thông tin liên hệ
      { path: 'thong-tin-lien-he', component: ContactComponent },

      //10. Giỏ hàng
      { path: 'gio-hang', component: ShoppingComponent },
      //11. Thanh toán
      { path: 'thanh-toan', component: PayComponent },
      //12. bảng giá dịch vụ
      { path: 'gia-dich-vu', component: ProductComponent },
      //13. Dịch vụ doanh nghệp
      { path: 'suc-khoe-doanh-nghiep', component: BusinessComponent },
      { path: 'tim-kiem', component: SearchResultComponent },
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: ':page', component: OtherPageComponent },
    ],
  },
  { path: 'documents/:path', component: DocumentsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FrontendRoutingModule {}
