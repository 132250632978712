import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class JobService extends BaseService {
  //frontend
  public getData(query) {
    return this.get("home/getJob", query);
  }
  public getDataID(query) {
    return this.get("home/GetJobByID", query);
  }

  //backend
  public getJob(query) {
    return this.get("admin/GetJob", query);
  }
  public saveJob(body) {
    return this.post("admin/SaveJob", body);
  }
  public updateOrderJob(body) {
    return this.post("admin/UpdateOrderJob", body);
  }
  public deleteJob(body) {
    return this.post("admin/DeleteJob", body);
  }
}