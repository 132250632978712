import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { ModalModule } from './module/modal';
import { CommonModule, DatePipe } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

//
import { InputModule } from './module/input/input.module';

//cuss
import { PaginationComponent } from './module/pagination/pagination.component';
import { FilterByStorePipe } from './pipe/filterStore.component';
import { AlertModule } from './module/notification';
import { SafeHtmlPipe } from './pipe/safeHtmlPipe.component';

import { MediaLibraryModule } from './module/media-library/media-library.module';
import { TranslatesModule } from './module/translate/translates.module';
import { BlockModule } from './module/block/block.module';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { NoteListModule } from './module/note-list/note-list.module';
import { ScriptService } from './script.service';
import { CEditorModule } from './module/editor/editor.module';
import { DialogModule, DialogService } from './module/dialog';
import { MustMatchDirective } from './directives/must-match.directive';
import { PopupComponent } from './module/popup/popup.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SortDirective } from './directives/sort.directive';
import { FloatModalModule } from './module/float-modal/float-modal.module';
import { WindowScrollDirective } from './directives/windown-scroll.directive';
import { MatchHeightDirective } from './directives/match-height.directive';
import { MenufilterPipe } from './pipe/menufilter.pipe';
import { MenuShowFilterPipe } from './pipe/menushowfilter.pipe';
import { ImageLazyModule } from './module/image-lazy/image-lazy.module';

import {
  NgbDatepickerModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbCarouselModule,
} from '@ng-bootstrap/ng-bootstrap';
import { OutsideDirective } from './directives/outside.directive';
import { StaticPipe } from './pipe/static.pipe';
import { NumberFormatPipe } from './pipe/numberFormat.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // NgbModule,
    ModalModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    //custom
    AlertModule,
    DialogModule,
    InputModule,
    CEditorModule,
    MediaLibraryModule,
    BlockModule,
    NoteListModule,
    FloatModalModule,
    NgbCarouselModule,
    NgbNavModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgbDropdownModule,
  ],
  declarations: [
    PaginationComponent,
    FilterByStorePipe,
    SafeHtmlPipe,
    InfiniteScrollDirective,
    MatchHeightDirective,
    WindowScrollDirective,
    MustMatchDirective,
    SortDirective,
    PopupComponent,
    MenufilterPipe,
    MenuShowFilterPipe,
    NumberFormatPipe,
    OutsideDirective,
    StaticPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    TranslatesModule,

    NgbNavModule,
    NgbDatepickerModule,
    NgbCarouselModule,
    NgbCollapseModule,
    NgbDropdownModule,
    //custom
    PaginationComponent,
    AlertModule,
    DialogModule,
    InputModule,
    CEditorModule,
    MediaLibraryModule,
    //pipe
    FilterByStorePipe,
    MenufilterPipe,
    MenuShowFilterPipe,
    NumberFormatPipe,
    SafeHtmlPipe,
    BlockModule,
    InfiniteScrollDirective,
    MatchHeightDirective,
    WindowScrollDirective,
    MustMatchDirective,
    SortDirective,
    NoteListModule,
    NgxMaskModule,
    FloatModalModule,
    ImageLazyModule,
    OutsideDirective,
    StaticPipe,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    DatePipe,
    ScriptService,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return { ngModule: SharedModule };
  }
}
