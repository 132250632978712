import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgModel } from '@angular/forms';
import { DialogService } from '@shared/module/dialog';
import { AlbumComponent } from '@shared/module/media-library/components/album/album.component';
import { AlbumDetail } from '@shared/module/media-library/models/album-detail.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => InputComponent),
    //   multi: true
    // }
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  // @Output() change: EventEmitter<any> = new EventEmitter();

  //validater
  @Input('required') required: boolean;
  @Input('minlength') minlength: number;
  @Input('maxlength') maxlength: number;
  @Input('pattern') pattern: string;
  @Input('mask') mask: string;
  @Input('min') min: number;
  @Input('max') max: number;
  @Input('mode') mode: string = 'editor';

  @Input('classlabel') classlabel: string;
  @Input('classinput') classinput: string;
  @Input('classRow') classRow: string;

  //attr
  @Input('model') model: any;
  @Input('default') default: any;
  @Input('key') key: string;

  @Input('title') title: any;
  @Input('desc') desc: any;

  @Input('placeholder') placeholder: any = '';
  @Input('label') label: string;
  @Input('name-control') name: any = 'input';
  @Input('cols') cols: number;
  @Input('rows') rows: number = 3;
  @Input('type') type: string = 'text';
  @Input('list') list: any[];
  @Input('input-style') inputStyle: string = 'basic'; // inline
  @Input('disabled') disabled: any;

  @Input('defaultOption') customOption: TemplateRef<any>;

  public isBrowser: boolean;
  // public ngControl: NgControl;
  idMedia: string = 'media_' + new Date().getTime();

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    public ngControl: NgModel,
    private dialogService: DialogService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      // const DecoupledEditor = require('@ckeditor/ckeditor5-build-decoupled-document');
      // this.Editor = DecoupledEditor;
      // this.Editor.defaultConfig = {
      //     toolbar: {
      //       items: ['yourListOfButtons'],
      //     },
      // };
    }
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    this.ngControl.valueAccessor = this;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(input: string) {
    this.model = input;
    // this.onChange(input)
  }
  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  selectFunction($event) {
    // this.change.emit($event);
  }

  openChooseImage() {
    this.dialogService.open(this.idMedia).result.then((res) => {
      if (res) {
        if (res instanceof AlbumComponent) {
          let list = res.getSelected();
          if (list.length > 0) {
            let media: AlbumDetail = list[0];
            this.model = media.imagePath;
            this.onChange(media.imagePath);
          }
        }
      }
    });
  }
  removeImage() {
    this.model = null;
  }

  errorHandler(event) {
    if (this.type == 'image' && (!this.default || this.default == '')) {
      event.target.src = '/assets/images/thumb.png';
    } else {
      event.target.src = this.default;
    }
  }

  public static(file = '') {
    return environment.STATIC_URL + file;
  }
}
