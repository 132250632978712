<div class="container-fluid p-0">
  <!--Background top-->
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ textData.titleName }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">Chính sách hoạt động</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<!-- about us -->
<section class="term-condition content-page">
  <div [innerHTML]="textData.longDescription"></div>
</section>
