import { AlbumDetail } from './../models/album-detail.model';
import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";
import { QueryObj } from "@shared/models/query.model";
@Injectable({ providedIn: 'root' })
export class MediaService extends BaseService {
    public getMedia(q:QueryObj) {
        return this.get("media/album", q);
    }
    public createAlbum(reqData){
      return this.post("media/album/save", reqData)
    }

    public removeMedia(albumDetail:AlbumDetail){
      return this.post("media/album/file",albumDetail);
    }
}