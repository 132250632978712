<!-- 404 !oops page not found -->
<div class="not-page">
  <div class="container">
      <div class="row" >
        <div class="col-md-12 col-lg-6 page-1">
          <div class="row justify-content-center">
            <div class="found-1">404</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 py-3 page-2">
          <div class="pb-3"><i class="fas fa-exclamation-triangle"></i></div>
          <div class="pb-1"><h4 class="font-weight-bold">{{'not-found.sorry' | translate}}</h4></div>
          <div class="pb-1"><h6>{{'not-found.not_find' | translate}}</h6></div>
          <div class="row btn-process justify-content-center">
            <a class="btn btn-viewmore m-1" [routerLink]='["/"]'><i class="fas fa-arrow-circle-right"></i> {{'not-found.go_home' | translate}}</a>
          </div>
        </div>
      </div> 
  </div>
</div>
