import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[wscroll]'
})
export class WindowScrollDirective  {

  timerLoad = null;
  emitted = false;
  public isBrowser:boolean;

  constructor(private element: ElementRef,  @Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @Input('wscroll') loadMore;
  @Input() scrollThreshold = 10; // px
  @HostListener('window:scroll',['$event'])
  public onScroll($event) {
    // const scrolled =$event.srcElement.scrollingElement.scrollTop;// this.element.nativeElement.scrollTop;
    // const heightScroll =  $event.srcElement.scrollingElement.scrollHeight;//this.element.nativeElement.scrollHeight;
    const heightContext = this.element.nativeElement.offsetHeight;
    
    let scrollThreshold = document.body.offsetHeight - this.element.nativeElement.offsetTop + this.scrollThreshold;

    if(this.isBrowser)
    {
      clearTimeout(this.timerLoad);
      this.timerLoad = setTimeout(() => {
        if ((window.innerHeight + window.scrollY + scrollThreshold) >= document.body.offsetHeight && !this.emitted) {
          this.emitted = true;
          // this.loadMore.emit();
          this.loadMore();
        } else if ((window.innerHeight + window.scrollY) < document.body.offsetHeight) {
          this.emitted = false;
        }
      }, 100);
    }
   
  }

}
