import { Injectable } from '@angular/core';
import { BaseService } from '@core/BaseService';
import { Feedback } from '@shared/models/feedback.model';

@Injectable({ providedIn: 'root' })
export class FeedbackService extends BaseService {
  //frontend
  public GetDataFeedback(query: any) {
    return this.get('admin/GetFeedback', query);
  }
  public saveFeedback(body) {
    return this.post('https://api-idoctor.simmed.vn/api/feedback/save', body);
  }
}
