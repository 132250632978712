import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { NotiService } from '@shared/module/notification/notification.service';

import { PackageService } from 'src/app/shared/services/package.service';
import { ResponeResult } from '@core/models/ResponeResult';
import { CartService } from '@shared/module/cart/services/cart.service';
import { CartItem } from '@shared/module/cart/models/cart.model';
import { BaseComponent } from '@core/BaseComponent';
import { Package, PackageDTO } from '@shared/models/package.model';
import { Tag, TagType } from '@shared/models/tags.model';

import { trigger, transition, style, animate } from '@angular/animations';
import { ConfigService } from '@shared/services/config.service';
import { OPTION_MENU } from '@shared/models/option-system';
@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(700, style({ opacity: 1 })),
      ]),
      transition('* => void', [animate(700, style({ opacity: 0 }))]),
    ]),
  ],
})
export class PackageDetailComponent extends BaseComponent {
  packageDTO: PackageDTO = new PackageDTO();
  urlLink: string | any;
  urlRoot: string | any;
  linkCode: string | any;
  tabIndex: number = 1;
  public menuConfig = null;
  public isShowPay: boolean = false;

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  constructor(
    public cartService: CartService,
    injector: Injector,
    protected notiService: NotiService,
    private packageService: PackageService,
    private configService: ConfigService
  ) {
    super(injector);
    this.loadConfigMenu();

    this.route.paramMap.subscribe((params) => {
      this.linkCode = this.route.snapshot.paramMap.get('id');
      this.tabIndex = 1;
      this.getData();
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.initLightbox();
  }

  getData() {
    this.packageService
      .noValid()
      .getById(this.linkCode)
      .then((result: ResponeResult) => {
        this.packageDTO = result.repData;
        if (this.packageDTO != null) {
          var mpackage = this.packageDTO.rowPackage;
          this.seo.title = mpackage.packageName?.toLowerCase();
          this.seo.description = mpackage.shortDescription;

          this.seo.tags.push(<Tag>{
            type: TagType.property,
            property: 'og:type',
            content: 'product ',
          });
          this.seo.tags.push(<Tag>{
            type: TagType.property,
            property: 'og:image',
            content: this.static(mpackage.imagePath),
          });

          this.seo.tags.push(<Tag>{
            type: TagType.name,
            name: 'twitter:image',
            content: this.static(mpackage.imagePath),
          });
          this.updateSEO(true);
        } else {
          this.packageDTO.rowPackage = new Package();
          // this.router.navigate(['/page-not-found']);
        }
      })
      .catch(() => {
        this.router.navigate(['/page-not-found']);
      });
    // let params = new HttpParams();
    // params = params.append("linkCode", this.linkCode);
    // this.http.get<PackageDTO>(this.urlRoot + 'home/GetPackageByID', { params: params }).subscribe(result => {
    //   this.packageDTO = result;
    //   if (this.packageDTO != null) { }
    //   else {
    //     this.packageDTO.rowPackage = new Package();
    //   }
    //   //window.scroll(0, 0);
    // });
  }

  loadConfigMenu() {
    this.configService
      .getOptionHome({ key: OPTION_MENU })
      .then((res: ResponeResult) => {
        let optionMenu = res.repData;
        if (optionMenu && optionMenu.optionId != '') {
          this.menuConfig = JSON.parse(optionMenu.optionValue);
          this.isShowPay = this.checkActiveMenu('shopping');
        }
      });
  }

  checkActiveMenu(key) {
    if (this.menuConfig) {
      return this.menuConfig[key] == true;
    }
    return false;
  }
  getPackages(linkCode: string) {
    let params = new HttpParams();
    // params = params.append("linkCode", linkCode);
    // this.http.get<Package[]>(this.urlRoot + 'home/GetPackageByCategory', { params: params }).subscribe(result => {
    //   this.packageDTO.Packages = result;
    // }, error => console.error(error));
  }
  addQuantity(step = 1) {
    this.packageDTO.rowPackage.quantity += step;
    this.changeQuantity();
  }
  changeQuantity() {
    if (this.packageDTO.rowPackage.quantity < 1)
      this.packageDTO.rowPackage.quantity = 1;
  }

  addProductToShoppingCart() {
    var rowPackage = new CartItem(this.packageDTO.rowPackage);
    // rowPackage.packageId = this.packageDTO.rowPackage.packageId;
    // rowPackage.packageCode = this.packageDTO.rowPackage.packageCode;
    // rowPackage.packageName = this.packageDTO.rowPackage.packageName;
    // rowPackage.imagePath = this.packageDTO.rowPackage.imagePath;
    // rowPackage.fromDate = this.packageDTO.rowPackage.fromDate;
    // rowPackage.toDate = this.packageDTO.rowPackage.toDate;
    // rowPackage.publishDate = this.packageDTO.rowPackage.publishDate;
    // rowPackage.quantity = this.packageDTO.rowPackage.quantity;
    // rowPackage.price = this.packageDTO.rowPackage.listPrice;
    // rowPackage.salePrice = this.packageDTO.rowPackage.salePrice;
    // rowPackage.amount = rowPackage.quantity * rowPackage.price;
    // rowPackage.discountAmount = (rowPackage.price - rowPackage.salePrice) * rowPackage.quantity / 100;
    // rowPackage.totalAmount = rowPackage.amount - rowPackage.discountAmount;
    // rowPackage.isApplyDate = this.packageDTO.rowPackage.isApplyDate;
    // rowPackage.isHost = this.packageDTO.rowPackage.isHost;
    this.cartService.add(rowPackage);
    this.cartService.openMenuItem();

    this.notiService.success('Add item to cart sucessfull!', this.options);
  }
}
