<div class="modal-body">
  <p>
    File "<strong class="text-danger">{{data.file?.name}}</strong>" đã tồn tại !!! <br>
    <i>Đường dẫn : {{data.path}}</i>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close({mode:0,  file:data})">Bỏ qua</button>
  <button type="button" class="btn btn-outline-success" (click)="activeModal.close({mode:1, file:data})">Vẫn tải lên</button>
  <button type="button" class="btn btn-outline-danger" (click)="activeModal.close({mode:2, file:data})">Ghi đè</button>
</div>