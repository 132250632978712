import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { ModalService } from '@shared/module/modal';
//import { CartService, } from '../../common/services/cart.service';
import {
  NgbSlideEvent,
  NgbSlideEventSource,
  NgbCarousel,
} from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/BaseComponent';
import { HomeService } from 'src/app/shared/services/home.service';
import { ResponeResult } from 'src/app/core/models/ResponeResult';
import { Tag, TagType } from '@shared/models/tags.model';
import { trigger, transition, style, animate } from '@angular/animations';
import * as AOS from 'aos';
import { ReferLinkDTO } from '@shared/models/referlink.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(1200, style({ opacity: 1 })),
      ]),
      transition('* => void', [animate(700, style({ opacity: 0 }))]),
    ]),
  ],
})
export class HomeComponent extends BaseComponent {
  homeDTO: HomeDTO;
  doctors: Doctor[];
  newSYT: ReferLinkDTO = new ReferLinkDTO();
  //bcarousel
  paused = false;
  pauseOnHover = true;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  reviews = [
    {
      avt: 'https://lh3.googleusercontent.com/a-/ACB-R5ShuDB-oFRCIL3xUhCZS3d90wv_FKBSMskT_G5KHw=w75-h75-p-rp-mo-br100',
      desc: 'Dịch vụ chu đáo, đội ngũ nhân viên y tế nhiệt tình. Phòng khám khang trang, sạch sẽ, hiện đại. Mình khám ở đây cảm giác rất riêng tư, chi phí khám lại rẻ nữa',
      link: 'https://goo.gl/maps/WnxYeLhdq9mAhurt5',
    },
    {
      avt: 'https://lh3.googleusercontent.com/a-/ACB-R5TCpmLMuaFwFXXuNBZps5YkgaDJ3-WYhdYmi2-a2g=w75-h75-p-rp-mo-br100',
      desc: 'Phòng khám sạch đẹp, đúng chuẩn 5*, bé mình từ nhỏ đều chích ngừa tại đây, nhân viên ở đây đều nhiệt tình và dễ thương.',
      link: 'https://goo.gl/maps/dbefSbS9t1kSKCSt5',
    },
    {
      avt: 'https://lh3.googleusercontent.com/a-/ACB-R5RijffBwuMpbwBZoY4UEFrti4hEnYRBnlnP0k5IMA=w75-h75-p-rp-mo-br100',
      desc: 'Một địa chỉ rất tốt ngay tại Tân Phú, con mình hôm vừa rồi bị sốt co giật rất may phòng khám nằm ngay dưới nhà nên con mình được cấp cứu ngay.',
      link: 'https://goo.gl/maps/tPmsjGZPhzbDSDWS8',
    },
    {
      avt: 'https://lh3.googleusercontent.com/a-/ACB-R5QhZ52xQmiS2_MDa3vh8dIFLtytnEJkqF6sQhQdEw=w75-h75-p-rp-mo-br100',
      desc: 'Hôm nay dẫn bé đi chích ngừa bác sĩ nhi khám rất nhẹ nhàng, nhân viên vui vẻ thân thiện',
      link: 'https://goo.gl/maps/jRyq8teaNabcg6EZ8',
    },
    {
      avt: 'https://lh3.googleusercontent.com/a-/ACB-R5Qc08VVDhW4Kx-taJkcyv7xts-jnOK7Tc1PTgQakw=w75-h75-p-rp-mo-ba4-br100',
      desc: 'Biết đến simed nhân dịp test lại đường huyết vào một ngày mưa tầm tã, vì có con nhỏ nên phải chạy tới lui. Rùi thích simed từ độ ấy bởi sự siêu nhiệt tình từ anh bảo vệ đến các nhân sự hỗ trợ và đội ngũ bác sĩ. ',
      link: 'https://goo.gl/maps/NsrDUYyZy52cogEM6',
    },
    {
      avt: 'https://lh3.googleusercontent.com/a-/ACB-R5S-_xdsJrGbVWdMrsNmsATVO40QJJZs6P1Jo0CD=w75-h75-p-rp-mo-br100',
      desc: 'Phòng khám đẹp, dịch vụ tốt, nhân viên thân thiện và nhiệt tình, bác sĩ rất tận tâm.',
      link: 'https://goo.gl/maps/JhBeNTT9GanyZ4jP8',
    },
  ];

  slideReviewConfig = {
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: 'unslick', // destroys slick
      },
    ],
    // infinite: false,
    arrows: false,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 2,
  };

  constructor(
    protected modalService: ModalService,
    private homeService: HomeService
  ) {
    super();
  }
  ngOnInit() {
    // this.title.setTitle('');
    this.homeDTO = new HomeDTO();
    this.homeDTO.slides = [];
    this.homeDTO.simmed = new Simmed();
    this.homeDTO.departments = [];
    this.homeDTO.packages = [];
    this.homeDTO.news = [];
    this.homeDTO.vaccine = [];
    this.homeDTO.simmed.titleName = null;
    this.schema = {
      '@context': 'https://schema.org',
      publisher: {
        '@type': 'Organization',
        name: 'SIM MEDICAL CENTER',
        logo: {
          '@type': 'ImageObject',
          url: 'https://api.simmed.vn/assets/uploads/home/logo.svg',
        },
      },
      '@type': 'MedicalWebPage',
      headline: 'SIM MEDICAL CENTER - SỰ LỰA CHỌN TỐT NHẤT CHO SỨC KHỎE',
      description:
        'Với định hướng phát triển theo tiêu chuẩn quốc tế JCI, SIM Medical Center lấy khách hàng làm trung tâm, tập trung vào sự an toàn, chất lượng chăm sóc y tế và hệ thống quản lý chuyên nghiệp. Song song đó, chúng tôi chú trọng đầu tư phát triển đồng đều cả hai yếu tố Nhân lực và Cơ sở vật chất với sứ mệnh: Mang y đức, tài năng và công nghệ tân tiến để kiến tạo trải nghiệm dịch vụ y tế trọn vẹn đến toàn thể khách hàng.',
      keywords:
        'Y Khoa, Y học, Y Tế, Khám Sản, Khám Nhi, Khám Nội, Khám ngoại, Khám bệnh, Khám',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Ho Chi Minh City, VN',
        streetAddress: '123 abc',
      },
      openingHours: ['Mo-Sa 09:00-22:30', 'Su 09:00-23:00'],
      telephone: '',
      image: [
        'https://api.simmed.vn/assets/uploads/abouts/z3400905890844_b0a5c2da238b237f8c6d34b77f18339d_1652148733.jpg',
        'https://api.simmed.vn/assets/uploads/abouts/2.png',
        'https://api.simmed.vn/assets/uploads/abouts/3.png',
      ],
    };
    this.route.paramMap.subscribe((params) => {
      this.getData();
    });
  }

  getData() {
    this.homeService.GetHomeData().then((result: ResponeResult) => {
      this.homeDTO = result.repData;
      this.doctors = result.repData.doctors;
      this.seo.tags.push(<Tag>{
        type: TagType.property,
        property: 'og:type',
        content: 'website',
      });
      this.updateSEO();
    });
    this.homeService.GetNewSYT().then((result: ResponeResult) => {
      this.newSYT = result.repData;
    });
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      setTimeout(() => {
        AOS.init();
      }, 1000);
    }
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (
      this.unpauseOnArrow &&
      slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT ||
        slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
    ) {
      this.togglePaused();
    }
    if (
      this.pauseOnIndicator &&
      !slideEvent.paused &&
      slideEvent.source === NgbSlideEventSource.INDICATOR
    ) {
      this.togglePaused();
    }
  }
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  //carousel
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    showNavigationArrows: false,
    showNavigationIndicators: false,
    autoplaySpeed: 350000,
    swipeToSlide: true,
    nextArrow: "<i class='fas fa-chevron-right'></i>",
    prevArrow: "<i class='fas fa-chevron-left'></i>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  slickInit(event: any) {
    console.log('slick initialized');
  }
  //end-carousel
  onActivate(event: any) {
    //window.scroll(0, 0);
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}

export class HomeDTO {
  slides: Slide[];
  simmed: Simmed;
  departments: Department[];
  packages: Package[];
  news: News[];

  vaccine: Vaccin[];
}

export class Slide {
  titleName: string | any;
  contents: string | any;
  imagePath: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  pageLink: string | any;
  isDisplayContent: Boolean;
}

export class Simmed {
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  titleName?: string | any;
  shortDescription: string | any;
  imageSmall: string | any;
  imageLarge: string | any;
}

export class Department {
  linkCode: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  logoImage: string | any;
  imagePath: string | any;
  titleName: string | any;
  departmentName: string | any;
  shortDescription: string | any;
  cssStyle: string | any;
}

export class Package {
  categoryCode: string | any;
  linkCode: string | any;
  listPrice: number | any;
  salePrice: number | any;
  fromDate: Date | any;
  toDate: Date | any;
  publishDate: Date | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  packageName: string | any;
  titleName: string | any;
  shortDescription: string | any;
  imagePath: string | any;
  isApplyDate: boolean;
  isHost: boolean;
  isDisccount: boolean;
}

export class News {
  linkCode: string | any;
  linkCategory: string | any;
  titleName: string | any;
  newsDate: Date | any;
  shortDescription: string | any;
  tags: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  viewNumber: number | any;
  imageSmall: string | any;
  newsTitle: string | any;
}

export class Doctor {
  linkCode: string | any;
  linkDepartment: string | any;
  departmentCode: string | any;
  imagePath: string | any;
  imageSmall: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  levelName: string | any;
  doctorName: string | any;
  positionTitle: string | any;
  departmentTitle: string | any;
  diplomaTitle: string | any;
  shortDescription: string | any;
}

export class Vaccin {
  productCode: string | any;
  productName: string | any;
  madeIn: string | any;
  containt: string | any;
  comments: string | any;
  productNameEng: string | any;
  madeInEng: string | any;
  containtEng: string | any;
  commentsEng: string | any;
  image: string | any;
  createdBy: number | any;
  createdDate: Date | any;
  modifyBy: number | any;
  modifyDate: Date | any;
  sortOrder: string | any;
  isPublish: boolean;
  searchString: string | any;
}
