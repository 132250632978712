<div id="frontend">
  <!-- navbar -->
  <app-nav-menu class="sticky-top menu-nav">
  </app-nav-menu>

  <!-- Back to top button -->
  <a class="backTop" href="#"> <img src="/assets/images/icon/backtop.svg"
      alt="Alternate Text" /></a>

  <!-- body -->
  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <!-- footer -->
  <app-nav-footer></app-nav-footer>

  <ng-template #advertise let-modal>
    <div class="advertise">
      <div class="modal-body">
        <button type="button" class="close" aria-label="Close"
          (click)="modal.close('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="!adv.isUseHtmlTemplate">
          <img [src]="static(adv.imagePath)" class="img-fluid" alt="simmed"
            onError="this.src='/assets/images/screenshot.png'">
          <a [href]="adv.linkViewMore"
            class="btn btn-outline-primary btn-readmore" *ngIf="adv.isViewMore">
            <i class="fa fa-arrow-right" aria-hidden="true"></i> Xem thêm
          </a>
        </div>
        <div *ngIf="adv.isUseHtmlTemplate">
          <div [innerHTML]="adv.htmlTemplate">

          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>