export class Alert {
    id: string | any;
    type: AlertType;
    message: string | any;
    autoClose: boolean = true;
    timeOut:number = 3000;
    keepAfterRouteChange: boolean | any;
    fade: boolean= true;

    constructor(init?:Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}