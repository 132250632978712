import { Doctor } from './docter.model';
import { Store } from './store.model';

export class BookingDTO {
  stores: Store[];
  doctors: Doctor[];
  doctorFromStores: Doctor[] = [];
}

export class Booking {
  BookingId: number | any;
  StoreId: number | any; // phòng khám
  BookingDate: Date | any;
  TimeStatus: number | any; // giờ khám
  BookingHour: number | any;
  BookingMinute: number | any;
  createdDate: Date | any;
  FullName: string | any;
  Email: string | any;
  MobilePhone: string | any;
  Address: string | any;
  Notes: string | any;
  DepartmentId: number | any;
  DoctorId: number | any; // bác sĩ
  status: number | any;
  IsNewPatient: boolean;
  IsPatient: boolean;
  ModifyBy: number | any;
  ModifyDate: Date | any;
  TrackingComment: string | any;
  TrackingDate: Date | any;
  TrackingBy: number | any;
  TrackingNotes: string | any;
  SearchString: string | any;
  SourceType: number | any = 2;
  UserId: number | any;
  BookingStatus: number | any = 1;

  initWithForm(item: BookingForm) {
    this.StoreId = item.Store?.storeId;
    this.DoctorId = item.Doctor?.doctorId;
    this.BookingDate = item.BookingDate;
    this.TimeStatus = item.TimeStatus ? item.TimeStatus.value : 1;
    this.FullName = item.FullName;
    this.Email = item.Email;
    this.MobilePhone = item.MobilePhone;
    this.Notes = item.Notes;
    this.Address = item.Address;
  }
}

export class BookingForm {
  TimeStatus: any; // giờ khám
  Doctor: Doctor; // bác sĩ
  Store: Store = new Store(); // phòng khám

  BookingDate: Date | any;
  FullName: string | any;
  Email: string | any;
  MobilePhone: string | any;
  Address: string | any;
  Notes: string | any;

  constructor(item?) {
    this.TimeStatus = item?.TimeStatus ? item?.TimeStatus : null;
    this.Doctor = item?.Doctor ? item?.Doctor : null;
    this.Store = item?.Store ? item?.Store : null;
    this.BookingDate = item?.BookingDate ? item?.BookingDate : new Date();
  }
}
