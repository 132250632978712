import { NgModule } from '@angular/core';
import { ImageLazyDirective } from './image-lazy.directive';

@NgModule({
  declarations: [
    ImageLazyDirective
  ],
  exports: [
    ImageLazyDirective
  ]
})
export class ImageLazyModule { }
