import { Doctor } from "./docter.model";
import { News } from "./New";
import { Package } from "./package.model";

export class SearchDTO{
  news: News[] = [];
  packages : Package[] = [];
  doctors: Doctor[] = [];
  isLoading:boolean = true;
  isNoData : boolean = true;
  public setData(payload: Partial<SearchDTO>){
    this.packages = payload.packages;
    this.doctors = payload.doctors;
    this.news = payload.news;
  }
}