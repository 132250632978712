<div class="sticky-add-to-cart-wrapper" style="height: auto">
  <div class="sticky-add-to-cart" *ngIf="product != null">
    <div class="button btn-quantity">
      <button class="btn" (click)="addQuantity(-1)">
        <i class="fa-solid fa-minus"></i>
      </button>
      <input [value]="product.quantity" class="quantity" min="1" name="quantity" type="number" />
      <button class="btn" (click)="addQuantity(1)">
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <button class="button btn-buy btn" (click)="addToCart()">
      <i class="fa-solid fa-cart-plus"></i>
      {{ 'cart.button.buy.package' | translate }}
    </button>
    <button class="button btn-consultation btn" data-bs-toggle="modal" data-bs-target="#modalConsultation">
      <i class="fa-solid fa-headset"></i>
      {{ 'cart.button.buy.consultation' | translate }}
    </button>
  </div>
</div>
<!-- Modal Body -->
<!-- if you want to close by clicking outside the modal, delete the last endpoint:data-bs-backdrop and data-bs-keyboard -->
<div
  class="modal fade"
  id="modalConsultation"
  tabindex="-1"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  role="dialog"
  aria-labelledby="modalTitleId"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title" id="modalTitleId">Gửi yêu cầu tư vấn</span>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0">
        <app-block id="contact">
          <div class="package" *ngIf="product">
            <div class="row g-1">
              <div class="col-md-4">
                <div class="thumb">
                  <img
                    class="glightbox"
                    [src]="static(product?.imagePath)"
                    alt="{{ product?.packageName }}"
                    onError="this.src='/assets/images/thumb.png'"
                  />
                </div>
              </div>
              <div class="col-md-8">
                <div class="info">
                  <strong>{{ product.packageName }}</strong> <br />
                  <p class="text-justify" [@fade]="'active'" [innerHTML]="product.shortDescription"></p>
                  <span [@fade]="'active'" class="package-price"
                    >{{ 'packageDetailPage.salePrice' | translate }}: {{ product.salePrice | number : 0 }}₫
                  </span>
                </div>
              </div>
            </div>
          </div>
          <form #frmContact="ngForm" (ngSubmit)="onSubmit(frmContact)">
            <div class="row">
              <div class="form-group col-md-12">
                <app-input
                  [label]="'contactPage.fullname' | translate"
                  [name-control]="'contactPage.fullname' | translate"
                  name="fullName"
                  [(ngModel)]="reqData.fullName"
                  maxlength="250"
                  required
                ></app-input>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <app-input
                  [label]="'contactPage.email' | translate"
                  [name-control]="'contactPage.email' | translate"
                  name="email"
                  [(ngModel)]="reqData.email"
                  [pattern]="emailPattern"
                  maxlength="100"
                ></app-input>
              </div>
              <div class="form-group col-md-6">
                <app-input
                  [label]="'contactPage.phone' | translate"
                  [name-control]="'contactPage.phone' | translate"
                  name="phone"
                  [(ngModel)]="reqData.phoneNumber"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  [pattern]="mobNumberPattern"
                  maxlength="50"
                  required
                ></app-input>
              </div>
            </div>
            <div class="form-group">
              <app-input
                type="textarea"
                [label]="'contactPage.content' | translate"
                [name-control]="'contactPage.content' | translate"
                name="content"
                [(ngModel)]="reqData.contents"
                maxlength="4000"
                rows="4"
              ></app-input>
            </div>
            <div class="col-12 text-center text-lg-left">
              <button type="submit" class="btn btn-contact mt-3" [disabled]="isLoading">
                <i class="fas fa-check"></i> {{ 'buttons.submit' | translate }} <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
              </button>
            </div>
          </form>
        </app-block>
      </div>
    </div>
  </div>
</div>

<!-- popup -->
<jw-modal id="custom-modal-1" class="success">
  <div class="jw-modal-body">
    <span class="fas fa-check-circle"></span>
    <h5 class="title1">{{ 'contactPage.alert' | translate }}</h5>
    <div class="title2">
      <span>{{ 'contactPage.hosline' | translate }}</span>
      <a href="tel:1900252535" title="Call: 1900 252 535">1900 252 535</a>
    </div>
    <button (click)="closeModal('custom-modal-1')"><i class="fas fa-times"></i></button>
  </div>
</jw-modal>
