import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    // const sanitizedContent = DOMPurify.sanitize(value);
    //  return angular.bypassSecurityTrustHtml(sanitizedContent);
    if (value == null) return '';
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }
}
