import { Injectable } from "@angular/core";
import { BaseService } from "../../core/BaseService";


@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
    public login(user,pass) {
        return this.post("admin/login", {
            username:user,
            password:pass
        });
    }
}