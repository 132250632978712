import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageLazyService {
  private observer: IntersectionObserver;

  constructor() {
    this.init();
  }

  private init() {
    // Khởi tạo
    this.observer = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach((entry) => {
          // chưa đến viewport, dừng sớm bớt đau khổ
          if (!entry.isIntersecting) {
            return;
          }

          // src được lưu trong data-src, chúng ta copy nó vào src là xong.
          const lazyImage = entry.target as HTMLImageElement;
          const src = lazyImage.dataset.src;
          if (src) {
            // nếu ảnh là thẻ img, copy vào src
            // nếu ảnh là background image, copy vào background-image

            if (
              lazyImage.tagName.toLowerCase() === 'img' ||
              lazyImage.tagName.toLowerCase() === 'iframe'
            ) {
              lazyImage.src = src;
            } else {
              lazyImage.style.backgroundImage = "url('" + src + "')";
            }
            console.log('lazyload', src);
            // xong việc thì nên dọn dẹp
            lazyImage.removeAttribute('lazy');

            // tiếp tục dọn dẹp
            imgObserver.unobserve(lazyImage);
          }
        });
      },
      { rootMargin: '0px 0px -200px 0px', threshold: 0.2 }
    );
  }

  observe(target: Element) {
    // "trải chiếu nằm chờ" tấm ảnh scroll tới viewport
    if (window && !!window.IntersectionObserver) {
      this.observer.observe(target);
    } else {
      console.log('Không hỗ trợ lazyload');
      const lazyImage = target as HTMLImageElement;
      const src = lazyImage.dataset.src;

      // nếu ảnh là thẻ img, copy vào src
      // nếu ảnh là background image, copy vào background-image
      if (
        lazyImage.tagName.toLowerCase() === 'img' ||
        lazyImage.tagName.toLowerCase() === 'iframe'
      ) {
        lazyImage.src = src;
      } else {
        lazyImage.style.backgroundImage = "url('" + src + "')";
      }
    }
  }
}
