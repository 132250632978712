<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ 'jobPage.title' | translate }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="'/tuyen-dung'">Tuyển dụng</a>
          </li>
          <li class="breadcrumb-item">{{ jobDTO.rowJob.titleName }}</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<!-- Tuyển dụng -->
<div class="recruitment-detail py-4 content-page">
  <div class="container" [hidden]="!jobDTO">
    <div class="row">
      <div class="col-lg-9 col-sm-12">
        <div class="col-lg-12 news-top">
          <h4>{{ 'jobPage.title_s' | translate }}</h4>
        </div>
        <!--Thông tin tuyển dụng-->
        <div class="job-list">
          <div class="job-item col-12">
            <div class="title">
              <h5 class="text-uppercase">{{ jobDTO.rowJob.titleName }}</h5>
              <h6>{{ 'jobPage.general_clinic' | translate }}</h6>
            </div>
            <div class="label">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              <span class="ps-1">{{ 'jobPage.date' | translate }}: {{ jobDTO.rowJob.publishDate | date : 'dd/MM/yyyy' }}</span>
            </div>
            <div class="description" [innerHTML]="jobDTO.rowJob.longDescription | safeHtml"></div>
          </div>
        </div>
      </div>

      <!--Thông tin tuyển dụng liên quan-->
      <div class="col-lg-3 col-sm-12">
        <div class="news-title">
          <div class="col-lg-12 news-top">
            <h4>{{ 'newsPage.related' | translate }}</h4>
          </div>
          <div class="job-related" *ngFor="let row of jobDTO.jobRelated">
            <div class="col-12">
              <div class="singer-news">
                <div class="job-related-title">
                  <a [routerLink]="['/tuyen-dung', row.linkCode]">
                    <h5>{{ row.titleName }}</h5>
                  </a>
                  <h6>{{ 'jobPage.general_clinic' | translate }}</h6>
                </div>
                <div class="label">
                  <span class="float-left"><i class="fas fa-file-invoice-dollar pe-2"></i>{{ 'jobPage.wage_agreement' | translate }}</span>
                  <span class="float-right"><i class="fas fa-clock-o pe-2"></i>{{ jobDTO.rowJob.publishDate | date : 'dd/MM/yyyy' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Couter -->
<div class="bg-counter">
  <div class="container counter-cc">
    <h5 class="py-2">{{ 'jobPage.hrInfomation' | translate }}</h5>
    <h6>
      {{ 'jobPage.hrPerson' | translate }}
      <br />
    </h6>
    <h6>
      {{ 'jobPage.hrEmail' | translate }}
    </h6>
  </div>
</div>
