<!-- Bác sĩ trả lời -->
<div class="container-fluid p-0">
  <!--Background top-->
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top-double">
      <h1>FAQS</h1>
      <hr />
      <h1 class="department-tile-top">BÁC SĨ TRẢ LỜI</h1>
    </div>
  </div>
</div>

<section class="answer">
  <div class="container">
    <div class="tabcontent">
      <div class="accordion" id="accordionExample">
        <!-- Accordion item 1 -->
        <div class="card">
          <div class="card-header">
            <button class="btn" href="#collapseOne" data-bs-toggle="collapse" aria-expanded="true" aria-controls="#collapseOne">
              <i class="fa fa-question-circle"></i>
              <h6>Làm thế nào để đặt lịch khám qua website?</h6>
            </button>
          </div>
          <div class="collapse show" id="collapseOne">
            <div class="card-body">
              <p>Bấm nút <strong>"ĐẶT LỊCH KHÁM"</strong> trên thanh menu phía trên hoặc trên trang chủ website www.simmed.vn, điền đầy đủ thông tin và bấm nút Đặt lịch khám để hoàn tất.</p>
            </div>
          </div>
        </div>
        <!-- Accordion item 2 -->
        <div class="card">
          <div class="card-header" id="one">
            <button class="btn"  href="#collapseTwo" data-bs-toggle="collapse" aria-expanded="false" aria-controls="#collapseTwo">
              <i class="fa fa-question-circle"></i>
              <h6>Làm thế nào để kiểm tra lại lịch hẹn khám của tôi?</h6>
            </button>
          </div>
          <div class="collapse" id="collapseTwo">
            <div class="card-body">
              <p>
                Quý khách bấm vào <strong>"Bệnh Án Điện Tử"</strong> và đăng nhập để kiểm tra thông tin lịch hẹn khám. <br />
                Hoặc Quý khách có thể kiểm tra trong hộp thư email của mình ngay sau khi đặt hẹn.
              </p>
            </div>
          </div>
        </div>
        <!-- Accordion item 3 -->
        <div class="card">
          <div class="card-header">
            <button class="btn" href="#collapseThree" data-bs-toggle="collapse" aria-expanded="false" aria-controls="#collapseThree">
              <i class="fa fa-question-circle"></i>
              <h6>Làm thế nào để tạo tài khoản đăng nhập “Bệnh án điện tử”?</h6>
            </button>
          </div>
          <div class="collapse" id="collapseThree">
            <div class="card-body">
              <p>Khi đến khám chữa bệnh tại SIM Medical Center, bộ phận Chăm sóc khách hàng sẽ giúp Quý khách lập tài khoản Bệnh án điện tử và hướng dẫn đăng nhập:</p>
              <p>
                - Lần đăng nhập đầu tiên, Quý khách phải đổi lại password. Lưu ý password phải có 6 ký tự trở lên, trong đó có một số và một ký tự đặc biệt.
                Ví dụ: password đơn giản là tên bạn@năm sinh, AN@1988.<br />
                - Quý khách nên lưu lại password và username vì nếu đăng nhập sai 3 lần sẽ bị khóa tài khoản. Khi đó Quý khách cần phải liên lạc phòng khám để xin lại password mới.
              </p>
            </div>
          </div>
        </div>
        <!-- Accordion item 4 -->
        <div class="card">
          <div class="card-header">
            <button class="btn" href="#collapseFour" data-bs-toggle="collapse" aria-expanded="false" aria-controls="#collapseFour">
              <i class="fa fa-question-circle"></i>
              <h6>Tôi có thể xem được những thông tin gì trong “Bệnh án điện tử”?</h6>
            </button>
          </div>
          <div class="collapse" id="collapseFour">
            <div class="card-body">
              <p>Quý khách có thể đặt lịch hẹn khám chữa bệnh, kiểm tra được thông tin lịch hẹn, kết quả khám chữa bệnh, siêu âm, xét nghiệm và toa thuốc của mình. Ngoài ra Quý khách còn có thể trao đổi trực tiếp cùng các bác sĩ thông qua tính năng chat của "Bệnh án điện tử".</p>
            </div>
          </div>
        </div>
        <!-- Accordion item 5 -->
        <div class="card">
          <div class="card-header">
            <button class="btn" href="#collapseFive" data-bs-toggle="collapse" aria-expanded="false" aria-controls="#collapseFive">
              <i class="fa fa-question-circle"></i>
              <h6>Nếu quên password hoặc username của tài khoản “Bệnh án điện tử” thì phải làm sao?</h6>
            </button>
          </div>
          <div class="collapse" id="collapseFive">
            <div class="card-body">
              <p>Với trường hợp bị quên tên đăng nhập hoặc mật khẩu của "Bệnh án điện tử", Quý khách vui lòng gửi thông tin của mình tại link www.simmed.vn/lien-he hoặc đến trực tiếp Phòng khám SIM Medical Center để được nhân viên cung cấp lại username hoặc password mới.</p>
            </div>
          </div>
        </div>
        <!-- Accordion item 6 -->
        <div class="card">
          <div class="card-header">
            <button class="btn" href="#collapseSix" data-bs-toggle="collapse" aria-expanded="false" aria-controls="#collapseSix">
              <i class="fa fa-question-circle"></i>
              <h6>Vì sao tôi không đăng nhập được vào tài khoản “Bệnh án điện tử”?</h6>
            </button>
          </div>
          <div class="collapse" id="collapseSix">
            <div class="card-body">
              <p>Khi Quý khách đăng nhập sai trên 3 lần, tài khoản "Bệnh án điện tử" của Quý khách sẽ tạm thời bị khóa. Để khôi phục lại tài khoản, Quý khách vui lòng cung cấp thông tin cho Phòng khám tại link www.simmed.vn/lien-he hoặc có thể đến trực tiếp Phòng khám SIM Medical Center.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

