export class Contact {
  contactId: string | any;
  subject: string | any = '';
  fullName: string | any = '';
  email: string | any = '';
  phoneNumber: string | any = '';
  contents: string | any = '';
  createdDate: Date | any = '';
  status: number | any = 1;
  searchString: string | any = '';
  contactType: number = 1;
  companyName: String = '';
  companyAddress: String = '';
  companyExamNumber: number = 0;
  trackingComment: string;
  statusBookingName: string;
  trackingDate: Date;
  trackingBy: number;
  trackingNotes: string;
  ref: string;
  packageId: number;
}
