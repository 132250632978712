export class PayOnline {
  type:PayOnlineID;
  logo :string;
  name: string;
  checked?:boolean = false;
}

export enum PayOnlineID {
  momo,
  zalo,
  vnpay
}