import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";

@Injectable({ providedIn: 'root' })
export class PageService extends BaseService {
  public getInfo(pagePath = "/") {
    return this.get("GetPageInfo", { pagePath });
  }

  public getList(query) {
    return this.get("admin/pages",  query);
  }

  public save(page) {
    return this.post("admin/page",  page);
  }

  public delete(id) {
    return this.post("admin/page/delete",  id);
  }

  
}