<div>
  <div class="container-fluid p-0">
    <div class="page-background-tt">
      <div class="col-12 text-center page-title-top">
        <h1>{{ 'contactPage.title' | translate }}</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
            <li class="breadcrumb-item">Liên hệ</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>

  <div class="contact">
    <app-block (onInit)="initData()" id="contact">
      <div class="container bg-white py-3">
        <h4 class="title-top">{{ 'contactPage.contact' | translate }}</h4>
        <form class="row" #frmContact="ngForm" (ngSubmit)="onSubmit(frmContact)">
          <!-- form  -->
          <div class="col-md-7">
            <div class="row">
              <div class="col-12">
                <div [innerHTML]="textData.longDescription"></div>
              </div>
              <!-- chủ đề -->
              <div class="form-group col-md-6">
                <app-input
                  [label]="'contactPage.subject' | translate"
                  [name-control]="'contactPage.subject' | translate"
                  name="subject"
                  [(ngModel)]="reqData.subject"
                  maxlength="250"
                  required
                ></app-input>
              </div>
              <!-- họ tên -->
              <div class="form-group col-md-6">
                <app-input
                  [label]="'contactPage.fullname' | translate"
                  [name-control]="'contactPage.fullname' | translate"
                  name="fullName"
                  [(ngModel)]="reqData.fullName"
                  maxlength="250"
                  required
                ></app-input>
              </div>
              <!-- Email -->
              <div class="form-group col-md-6">
                <app-input
                  [label]="'contactPage.email' | translate"
                  [name-control]="'contactPage.email' | translate"
                  name="email"
                  [(ngModel)]="reqData.email"
                  [pattern]="emailPattern"
                  maxlength="100"
                ></app-input>
              </div>
              <!-- Phone -->
              <div class="form-group col-md-6">
                <app-input
                  [label]="'contactPage.phone' | translate"
                  [name-control]="'contactPage.phone' | translate"
                  name="phone"
                  [(ngModel)]="reqData.phoneNumber"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  [pattern]="mobNumberPattern"
                  maxlength="50"
                  required
                ></app-input>
              </div>
              <!-- content -->
              <div class="form-group col-md-12">
                <app-input
                  type="textarea"
                  [label]="'contactPage.content' | translate"
                  [name-control]="'contactPage.content' | translate"
                  name="content"
                  [(ngModel)]="reqData.contents"
                  maxlength="4000"
                  rows="4"
                ></app-input>
              </div>
              <!-- submit -->
              <div class="col-12 text-center text-lg-left">
                <button type="submit" class="btn btn-contact mt-3 mb-4" [disabled]="isLoading">
                  <i class="fas fa-check"></i> {{ 'buttons.submit' | translate }} <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- end form  -->
          <!-- map -->
          <div class="col-md-5 locationsection">
            <div class="map-responsive">
              <iframe
                title="simmmed - map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.5098849883243!2d106.62338931462253!3d10.772205192324359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752dabe9b777e7%3A0x14698f2dd1fa27b8!2zU0lNIE1lZGljYWwgQ2VudGVyIC0gUEsgUGjhu6UgU-G6o24sIE5oaSBLaG9hLCBCw6FjIFPhu4kgR2lhIMSQw6xuaA!5e0!3m2!1sen!2s!4v1565940067412!5m2!1sen!2s"
                width="600"
                height="350"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
              ></iframe>
            </div>
          </div>
          <!--end map -->
        </form>
      </div>
    </app-block>
  </div>

  <!-- popup -->
  <jw-modal id="custom-modal-1" class="success">
    <div class="jw-modal-body">
      <span class="fas fa-check-circle"></span>
      <h5 class="title1">{{ 'contactPage.alert' | translate }}</h5>
      <div class="title2">
        <h6>{{ 'contactPage.hosline' | translate }}</h6>
        <a href="tel:1900252535" title="Call: 1900 252 535">1900 252 535</a>
      </div>
      <button (click)="closeModal('custom-modal-1'); resetData()"><i class="fas fa-times"></i></button>
    </div>
  </jw-modal>
</div>
