import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from 'src/app/core/BaseComponent';
import { PartnerService } from '@shared/services/partner.service';
import { ResponeResult } from '@core/models/ResponeResult';
import { ContactService } from '@shared/services/contact.service';
import { DialogService } from '@shared/module/dialog';
import { Contact } from '@shared/models/contact.model';
import { ButtonDialog } from '@shared/module/dialog/dialog-config';
import { TranslatesService } from '@shared/module/translate';
import { BlockService } from '@shared/module/block/services/block.service';
import { TextdataService } from '@shared/services/textdata.service';
import { TextData } from '@shared/models/textdata.model';
import { NewService } from '@shared/services/new.services';
import { QueryObj } from '@shared/models/query.model';
import { New } from '@shared/models/New';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class BusinessComponent extends BaseComponent {
  public partners: Partner[];
  public viewMobile: boolean = false;
  public textData: TextData = new TextData();
  reqData: Contact = new Contact();
  public photoRealistics: New[] = [];

  constructor(
    private partnerService: PartnerService,
    private contactService: ContactService,
    private dialogService: DialogService,
    private translateService: TranslatesService,
    private blockService: BlockService,
    private textDataService: TextdataService,
    private newService: NewService
  ) {
    super();

    this.route.paramMap.subscribe((params) => {
      this.getData();
    });
  }

  ngOnInit() {
    this.seo.title = 'Dịch vụ doanh nghiệp';
    this.updateSEO();

    this.reqData = new Contact();
    this.reqData.status = 1;
    this.reqData.createdDate = new Date();
    this.reqData.contactType = 2;
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      let self = this;
      setTimeout(() => {
        let imgs = document.getElementsByClassName('glightbox');
        this._albums = [];
        for (let i = 0; i < imgs.length; i++) {
          const element = imgs[i];
          this._albums.push({
            src: element.getAttribute('src'),
            caption: element.getAttribute('alt'),
            thumb: element.getAttribute('thumb'),
          });
          element.addEventListener('click', function () {
            self.openLightbox(i);
          });
        }
      }, 1000);
    }
  }

  getData() {
    this.partnerService.getData().then(
      (result: ResponeResult) => {
        this.partners = result.repData;
      },
      (error) => console.error(error)
    );

    this.textDataService
      .noValid()
      .getTextDataByID('DICH-VU-DOANH-NGHIEP')
      .then((res: ResponeResult) => {
        this.textData = res.repData;
        this.seo.title = this.textData.titleName;
        this.seo.description = this.textData.shortDescription;
        this.updateSEO();
      })
      .catch()
      .finally(() => {});

    let queryNews = new QueryObj();
    queryNews.linkCode = 'hinh-anh-thuc-te';
    queryNews.startRow = 0;
    queryNews.maxRows = 4;
    this.newService
      .getNew(queryNews)
      .then((res: ResponeResult) => {
        this.photoRealistics = res.repData;
      })
      .catch()
      .finally(() => {});
  }

  onSubmit(frmContact: NgForm) {
    if (frmContact.invalid) {
      return;
    } else {
      this.reqData.companyExamNumber = Number(this.reqData.companyExamNumber);
      this.blockService.load('company-contact');
      this.reqData.trackingNotes = 'WEBSITE';
      this.contactService
        .addContact(this.reqData)
        .then((res) => {
          this.dialogService
            .success({
              centered: false,
              buttons: [
                new ButtonDialog({
                  label: this.translateService.instant('form.button.agree'),
                  id: 1,
                  style: 'success',
                }),
              ],
              message: `<div class="text-center">
                      <h6>${this.translateService.instant(
                        'contactPage.hosline'
                      )}</h6>
                      <a href="tel:1900252535" title="Call: 1900 252 535">1900 252 535</a>
                    </div>`,
              title: this.translateService.instant('contactPage.alert'),
            })
            .result.then((result: ButtonDialog) => {});
        })
        .catch((err) => {})
        .finally(() => {
          this.blockService.unload('company-contact');
        });
    }
  }

  //carousel
  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  slickInit(event: any) {
    console.log('slick initialized');
  }
  //end-carousel
}
export class Partner {
  languageId: string | any;
  companyName: string | any;
  companyAddress: string | any;
  notes: string | any;
  searchString: string | any;
  logo: string | any;
}
