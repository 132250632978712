import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { CartItem } from '@app/common/models/cartItem';
import { BaseComponent } from '@core/BaseComponent';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-cart-menu-item',
  templateUrl: './cart-menu-item.component.html',
  styleUrls: ['./cart-menu-item.component.scss'],
})
export class CartMenuItemComponent extends BaseComponent implements OnInit {
  constructor(public cartService: CartService, injector: Injector) {
    super(injector, false);
  }

  @ViewChild('cart', { static: false }) cartElement;

  ngOnInit(): void {
    this.onDocumentClick = this.onDocumentClick.bind(this);
    if (this.isBrowser) {
      document.addEventListener('click', this.onDocumentClick);
    }
  }

  removeItem(cart: CartItem) {
    this.cartService.remove(cart);
  }

  protected onDocumentClick(event: MouseEvent) {
    if (this.cartElement.nativeElement.contains(event.target)) {
      return;
    }
    // console.log(this.cartService.showNav);
    this.cartService.closeMenuItem();
    // console.log('Clicked outside!');
  }
}
