import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";
import { Order } from "@shared/models/order.model";
import { Cart } from "@shared/module/cart/models/cart.model";


@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService {
  
  public checkout(cart: Cart){
    return this.post("order/create",cart);
  }
  public checkoutVNPAT(cart: Cart){
    return this.post("payment/order/vnpay", cart);
  }
  public checkoutZaloPay(cart: Cart){
    return this.post("payment/order/zalopay", cart);
  }
  public checkoutMomo(cart: Cart){
    return this.post("payment/order/momo", cart);
  }
  public checkoutMegaPay(cart: Cart){
    return this.post("payment/order/megapay", cart);
  }

  public checkoutMegaPayRes(megaReq: any){
    return this.post("payment/order/megapay/check", megaReq);
  }

  public checkoutVNPayRes(vnpayReq: any){
    return this.post("payment/order/vnpay_ipn", vnpayReq);
  }

  public checkoutMomoRes(momoReq: any){
    return this.post("payment/order/momo/check", momoReq);
  }

  public checkoutZaloRes(zaloReq: any){
    return this.post("payment/order/zalo/check", zaloReq);
  }

  public syncCart(cart: Cart){
    return this.post("store/sync",cart);
  }

  public getList(query){
    return this.get("admin/order", query);
  }

  public getListDashboard(query){
    return this.get("admin/dashboard/order", query);
  }

  public getInfo(orderId){
    return this.get("admin/order/info", {orderId});
  }

  public getStatus(){
    return this.get("admin/order/status");
  }

  public update(order : Order){
    return this.post("admin/order/update",order);
  }

  public delete(id){
    return this.post("admin/order/delete",{id});
  }
}