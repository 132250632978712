<!--Navbar-->
<div class="header">
  <!-- div 1 -->
  <div class="container">
    <div class="row navbar-header">
      <!-- logo -->
      <div class="col-md-2 col-5">
        <div class="logo-menu">
          <a [routerLink]='["/"]' (click)="isMenuCollapsed = true">
            <img [src]="static('assets/uploads/home/logo.svg')" alt="Simmed" width="237" height="74"
              onError="this.src='/assets/images/logo.svg'">
          </a>
        </div>
      </div>
      <!-- button mobile-->
      <div class="col-md-10 col-7 menu-button">
        <div ngbDropdown class="d-flex justify-content-end align-items-center">
          <app-translate-menu-item  *ngIf="isShowLang"></app-translate-menu-item>
          <app-cart-menu-item class="p-1"></app-cart-menu-item>
          <div class="search-mobie" outside (outSideClick)="onBlurSearchMobie()">
            <button class="btn btn-search" (click)="toggleSearchMobie()">
              <i class="fas fa-search fa-xl"></i>
            </button>
            <div class="search-mobie-control" *ngIf="isShowResultSearchMobie">
              <input type="search" (ngModelChange)="doSearch()"
                (blur)="onBlurSearch()" (focus)="onFocusSearch()"
                (keyup.enter)="onEnter()" [(ngModel)]="querySearch.search"
                class="form-control"
                placeholder="Tìm kiếm ...">
                <div class="search-result scrollbar style-3"
                [hidden]="!isShowResultSearch">
                <app-block id="search-box">
                  <div class="" *ngIf="!searchDTO.isNoData; else norecored">
                    <ul class="list-search-box">
                      <li *ngIf="searchDTO.packages.length > 0">
                        <div class="header">
                          <i class="fas fa-medkit me-2"></i>
                          <span>Gói khám</span>
                        </div>
                        <ul>
                          <li *ngFor="let item of searchDTO.packages">
                            <a [routerLink]="['/goi-kham', item.categoryCode, item.linkCode]"
                              class="list-group-item list-group-item-action">
                              <div class="d-flex">
                                <div class="thumb me-2">
                                  <img [src]="static(item.imagePath)"
                                    class="img-fluid"
                                    onError="this.src='/assets/images/noimage.jpg'">
                                </div>
                                <span>{{item.packageName}}</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li *ngIf="searchDTO.doctors.length > 0">
                        <div class="header">
                          <i class="fas fa-user-md me-2"></i>
                          <span>Bác sĩ</span>
                        </div>
                        <ul>
                          <li *ngFor="let item of searchDTO.doctors">
                            <a [routerLink]="['/bac-si',  item.linkCode]"
                              class="list-group-item list-group-item-action">
                              <div class="d-flex">
                                <div class="thumb me-2">
                                  <img [src]="static(item.imagePath)"
                                    class="img-fluid"
                                    onError="this.src='/assets/images/noimage.jpg'">
                                </div>
                                <div class="content">
                                  <span class="text-uppercase">
                                    <strong>{{item.levelName}}</strong>
                                    {{item.doctorName}}</span>
                                  <p class="des"
                                    [innerHTML]="item.shortDescription"></p>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li *ngIf="searchDTO.news.length > 0">
                        <div class="header">
                          <i class="fas fa-user-md me-2"></i>
                          <span>Tin tức</span>
                        </div>
                        <ul>
                          <li *ngFor="let item of searchDTO.news">
                            <a [routerLink]="['/tin-tuc', item.linkCategory, item.linkCode]"
                              class="list-group-item list-group-item-action">
                              <div class="d-flex">
                                <div class="thumb me-2">
                                  <img [src]="static(item.imageSmall)"
                                    class="img-fluid"
                                    onError="this.src='/assets/images/noimage.jpg'">
                                </div>
                                <div class="content">
                                  <span>{{item.titleName}}</span>
                                  <p class="des"
                                    [innerHTML]="item.shortDescription"></p>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <ng-template #norecored>
                    <span *ngIf="searchDTO.isLoading">Đang tìm kiếm dữ
                      liệu...</span>
                    <span *ngIf="!searchDTO.isLoading">Không tìm thấy !!!</span>
                  </ng-template>
                </app-block>
              </div>
            </div>
          </div>
          <button class="navbar-toggler collapsed pe-0" aria-label="Menu"
            (click)="isMenuCollapsed = !isMenuCollapsed">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
      <!--main-menu-->
      <div class="col-md-5 menu">
        <div class="d-flex">
          <!-- menu drop-->
          <div ngbDropdown #mainMenu="ngbDropdown" class="d-inline-block">
            <button class="btn menudropdown" id="dropdownMenuButton" aria-label="Menu"
              ngbDropdownToggle (mouseenter)="buttonMenuEnter(mainMenu)"
              (mouseleave)="buttonMenuLeave(mainMenu)">
              <i class="fa-solid fa-bars"></i>
            </button>
            <ul ngbDropdownMenu class="navbar-menu dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              (mouseenter)="menuEnter(mainMenu)"
              (mouseleave)="menuLeave(mainMenu)"
              (click)="onEvent($event)">

              <li class="bground" [class.drop-menu]="item.childs.length>0"
                *ngFor="let item of listMenu"
                [routerLinkActive]='["link-active"]'
                [routerLinkActiveOptions]="{exact: true}">
                <a ngbDropdownItem class="nav-link"
                  [class.toggle-nav]="item.childs.length>0"
                  [routerLink]='[item.link]'
                  *ngIf="item.link">
                  {{item.label}}
                </a>
                <a ngbDropdownItem class="nav-link"
                  [class.toggle-nav]="item.childs.length>0"
                  *ngIf="!item.link">
                  {{item.label}}
                </a>
                <ul class="dropdown-submenu navbar-menu"
                  *ngIf="item.childs.length>0">
                  <li [routerLinkActive]='["link-active"]'
                    *ngFor="let sub of item.childs">
                    <a class="dropdown-item" [routerLink]='[sub.link]'>
                      {{sub.label}}
                    </a>
                  </li>
                </ul>
              </li>


            </ul>
          </div>
          <!--search-->
          <div class="col-md-10">
            <div class="search">
              <div class="input-group search-control">
                <div class="btn-group">
                  <div class="ps-3"></div>
                  <!-- <button class="dropbtn form-control" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tổng Danh Mục</button>
                    <ul class="dropdown-search">
                      <li><a class="dropdown-item">Gói Khám Ưu Đãi</a></li>
                      <li><a class="dropdown-item">Gói Khám Tiết Kiệm</a></li>
                      <li><a class="dropdown-item">Ưu Đãi Giờ Vàng</a></li>
                      <li><a class="dropdown-item">Voucher</a></li>
                    </ul> -->
                </div>
                <input type="search" (ngModelChange)="doSearch()"
                  (blur)="onBlurSearch()" (focus)="onFocusSearch()"
                  (keyup.enter)="onEnter()" [(ngModel)]="querySearch.search"
                  class="form-control"
                  placeholder="Tìm kiếm ...">
                <div class="input-group-append" (click)="onEnter()">
                  <span class="input-group-icon d-block mt-1 me-2 ms-1">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </span>
                </div>
              </div>
              <div class="search-result scrollbar style-3"
                [hidden]="!isShowResultSearch">
                <app-block id="search-box">
                  <div class="" *ngIf="!searchDTO.isNoData; else norecored">
                    <ul class="list-search-box">
                      <li *ngIf="searchDTO.packages.length > 0">
                        <div class="header">
                          <i class="fas fa-medkit me-2"></i>
                          <span>Gói khám</span>
                        </div>
                        <ul>
                          <li *ngFor="let item of searchDTO.packages">
                            <a [routerLink]="['/goi-kham', item.categoryCode, item.linkCode]"
                              class="list-group-item list-group-item-action">
                              <div class="d-flex">
                                <div class="thumb me-2">
                                  <img [src]="static(item.imagePath)"
                                    class="img-fluid"
                                    onError="this.src='/assets/images/noimage.jpg'">
                                </div>
                                <span>{{item.packageName}}</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li *ngIf="searchDTO.doctors.length > 0">
                        <div class="header">
                          <i class="fas fa-user-md me-2"></i>
                          <span>Bác sĩ</span>
                        </div>
                        <ul>
                          <li *ngFor="let item of searchDTO.doctors">
                            <a [routerLink]="['/bac-si',  item.linkCode]"
                              class="list-group-item list-group-item-action">
                              <div class="d-flex">
                                <div class="thumb me-2">
                                  <img [src]="static(item.imagePath)"
                                    class="img-fluid"
                                    onError="this.src='/assets/images/noimage.jpg'">
                                </div>
                                <div class="content">
                                  <span class="text-uppercase">
                                    <strong>{{item.levelName}}</strong>
                                    {{item.doctorName}}</span>
                                  <p class="des"
                                    [innerHTML]="item.shortDescription"></p>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li *ngIf="searchDTO.news.length > 0">
                        <div class="header">
                          <i class="fas fa-user-md me-2"></i>
                          <span>Tin tức</span>
                        </div>
                        <ul>
                          <li *ngFor="let item of searchDTO.news">
                            <a [routerLink]="['/tin-tuc', item.linkCategory, item.linkCode]"
                              class="list-group-item list-group-item-action">
                              <div class="d-flex">
                                <div class="thumb me-2">
                                  <img [src]="static(item.imageSmall)"
                                    class="img-fluid"
                                    onError="this.src='/assets/images/noimage.jpg'">
                                </div>
                                <div class="content">
                                  <span>{{item.titleName}}</span>
                                  <p class="des"
                                    [innerHTML]="item.shortDescription"></p>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <ng-template #norecored>
                    <span *ngIf="searchDTO.isLoading">Đang tìm kiếm dữ
                      liệu...</span>
                    <span *ngIf="!searchDTO.isLoading">Không tìm thấy !!!</span>
                  </ng-template>
                </app-block>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- phone -->
      <div class="col-md-2 number-phone">
        <a class="btn btn-phone" href="tel:1900252535">
          <i class="fas fa-phone-alt"></i>
          <strong> 1900 25 25 35</strong>
        </a>
      </div>
      <!-- language & cart -->
      <div class="col-md-3 d-none d-lg-flex justify-content-end">
        <!-- language -->
        <app-translate-menu-item *ngIf="isShowLang"></app-translate-menu-item>
        <!-- cart -->
        <app-cart-menu-item></app-cart-menu-item>
      </div>
    </div>
  </div>

  <div class="bookmobile">
    <a [routerLink]='["/dat-lich-kham"]' (click)="isMenuCollapsed = true"
      class="text-uppercase" style="color:#fff">
      <i class="far fa-calendar-alt pe-2" aria-hidden="true"></i>
      {{'bookingPage.title' | translate}}
    </a>
  </div>

  <!-- menu mobile -->
  <div class="navbar-slidemenu collapse" id="slidemenu"
    [ngbCollapse]="isMenuCollapsed">
    <div class="mobile-menu container" id="menu-show">
      <ul class="menu_one">
        <li class="bground" [class.relative]="item.childs.length>0"
          *ngFor="let item of listMenu" [routerLinkActive]='["link-active"]'
          [routerLinkActiveOptions]="{exact: true}">
          <a *ngIf="item.childs.length > 0 && !item.link" ngbDropdownItem
            class="nav-link nav"
            [class.toggle-nav]="item.childs.length>0"
            [href]="'#'+item.key" data-bs-toggle="collapse">
            {{item.label}}
          </a>
          <a *ngIf="item.childs.length > 0 && item.link" ngbDropdownItem
            class="nav-link nav"
            [class.navlink]="item.childs.length>0"
            [href]="'#'+item.key" data-bs-toggle="collapse">
            {{item.label}}
          </a>
          <a *ngIf="item.childs.length <= 0" ngbDropdownItem class="nav-link"
            [routerLink]='[item.link]'
            (click)="isMenuCollapsed = !isMenuCollapsed">
            {{item.label}}
          </a>

          <a *ngIf="item.childs.length>0"
            class="fas fa-plus collapsible-link"
            href="#{{item.key}}" data-bs-toggle="collapse"></a>

          <ul class="collapse menu_two" *ngIf="item.childs.length>0"
            [id]="item.key">
            <li [routerLinkActive]='["link-active"]'
              *ngFor="let sub of item.childs">
              <a class="nav-link nav" [routerLink]='[sub.link]'
                (click)="isMenuCollapsed = !isMenuCollapsed">
                <i class="fas fa-angle-right"></i> {{sub.label}}
              </a>
            </li>
          </ul>
        </li>
        <!-- language -->
        <!-- <li>
            <a class="flag"><img src="/assets/uploads/home/vietnam.svg" style="margin-top: -3px; width: 20px;"> English</a>
            <a class="flag"><img src="/assets/uploads/home/usa.svg" style="margin-top: -3px; width: 20px;"> Việt Nam </a>
          </li> -->
      </ul>
    </div>
  </div>
  <div class="bg-light-3">
    <div class="container d-none d-lg-block">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <!-- <a class="navbar-brand" href="#">Navbar</a> -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
          <ul class="nav-sub navbar-nav me-auto" style="min-height: 37px;" >
            <!--Gói khám-->
            <li class="nav-item" [class.dropdown]="item.childs.length>0"
              *ngFor="let item of listMenu | menushowfilter:[1]:'pos'"
              [routerLinkActive]='["link-active"]'>
              <a *ngIf="item.childs.length<=0" class="nav-link nav"
                (click)="isMenuCollapsed = !isMenuCollapsed"
                [routerLink]='[item.link]'
                aria-expanded="false">{{item.label}}
              </a>

              <a *ngIf="item.childs.length > 0 && item.link" ngbDropdownItem
                class="dropdown-btn nav-link toggle-nav"
                [routerLink]='[item.link]'>
                {{ item.label }}
                <!-- <i class="fa fa-chevron-down ms-2" aria-hidden="true"></i> -->
                <i class="fa fa-caret-down ms-2"></i>
              </a>
              <a *ngIf="item.childs.length > 0 && !item.link" ngbDropdownItem
                class="dropdown-btn nav-link toggle-nav">
                {{ item.label }}
                <!-- <i class="fa fa-chevron-down ms-2" aria-hidden="true"></i> -->
                <i class="fa fa-caret-down ms-2"></i>
              </a>
              <ul *ngIf="item.childs.length > 0"
                class="dropdown-submenu navbar-menu">
                <li [routerLinkActive]='["link-active"]'
                  *ngFor="let sub of item.childs">
                  <a class="dropdown-item" [routerLink]='[sub.link]'>
                    {{sub.label}}
                  </a>
                </li>
              </ul>
            </li>

          </ul>
          <ul class="nav-sub navbar-nav d-none d-xl-block">
            <!-- <li class="nav-item">
              <a class="btn btn-outline-primary my-2 my-sm-0" href="#">Search</a>
              
            </li> -->
            <li class="nav-item" [routerLinkActive]='["link-active"]'>
              <a (click)="isMenuCollapsed = !isMenuCollapsed"
                style="padding: 2px 12px;" class="nav-link nav btn btn-primary"
                [routerLink]='["/dat-lich-kham"]'
                aria-expanded="false">
                <i class="fa fa-calendar-alt me-2" aria-hidden="true"></i>
                {{'bookingPage.title' | translate}}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>

</div>
