import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss']
})
export class MediaItemComponent implements OnInit {
  
  @Input() src:string ="";
  width:number = 0;
  height:number = 0;

  constructor() { }

  @ViewChild('img') img: ElementRef;

  
  ngOnInit(): void {
  }

  onLoad() {
    this.width = (this.img.nativeElement as HTMLImageElement).naturalWidth;
    this.height = (this.img.nativeElement as HTMLImageElement).naturalHeight;
  }

  public static(file =""){
    return environment.STATIC_URL+file;
  }
  
}
