export class Album {
  public albumId:number;
  public albumName:string;
  public folder:string;
  public notes:string
  public albumParent:number;
  selected: boolean = false;
}

export class ReqAlbum {
  rowAlbum:Album;
  isNew:boolean = true;
}