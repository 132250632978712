import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'numberformat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    let num: number = 0;
    if (typeof value === 'number') {
      num = value;
    }
    // Sử dụng number pipe của Angular để định dạng số
    let formattedPrice = new Intl.NumberFormat('en-US').format(num);
    // Thay thế dấu ',' bằng dấu '.'
    return formattedPrice.replace(/,/g, '.');
  }
}
