import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlbumDetail } from '@app/admin/models/modelDTO';
import { ResponeResult } from '@core/models/ResponeResult';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Album } from '../../models/album.model';
import { FileObj } from '../../models/fileobj.model';
import { UploadService } from '../../services/upload.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  isHighlight:boolean =false;
  @Input('header') isHeader:boolean = true;

  filePendings: FileObj[] = [];
  fileUploads: AlbumDetail[] = [];

  @Input('album') album:Album;
  @Input('aid') aid:number;

  autoId:Number = Math.floor(Date.now() / 1000);
  @Output('close') close : EventEmitter<any> = new EventEmitter();

  @ViewChild('controlFile') controlFile: ElementRef;

  constructor(private uploadService: UploadService ,
    private modalService: NgbModal
     ) { }

  ngOnInit(): void {
    this.autoId = Math.floor(Date.now() / 1000);
  }

  @ViewChild('content') content: ElementRef;
  
  doUpload(file, preview){
    let fileObj = new FileObj(file)
    fileObj.preview = preview;
    this.filePendings.push(
      fileObj
    )
    fileObj.start();
    this.controlFile.nativeElement.value = "";
    this.uploadService.upload({files:file, albumid:this.album?this.album?.albumId:this.aid}).then((res :ResponeResult)=>{
      if(res.isOk)
      {
        this.uploadFileSuccess(res, fileObj);
      }else{
        fileObj.reset();
        fileObj.isError = true;
        if(res.repData.length > 0)
        {
          fileObj.path = res.repData[0]?.path;
          let modal = this.modalService.open(DialogComponent,{windowClass:"index-dialog"});
          modal.componentInstance.data = fileObj;
          modal.result.then((result) => {
            if(result.mode == 1)
            {
              this.doUploadOveright(result.file,false);
            }else if(result.mode == 2)
            {
              this.doUploadOveright(result.file,true);
            }else{
              let index = this.filePendings.findIndex(x=>x.file == result.file.file)
              this.filePendings.splice(index,1);
            }
          });
        }
      }
    }).catch().finally(()=>{

    });
  }

  doUploadOveright(fileObj :FileObj, overight){
   
    fileObj.start();
    this.uploadService.uploadExsit({files:fileObj.file,  albumid:this.album?this.album?.albumId:this.aid, overight}).then((res :ResponeResult)=>{
      if(res.isOk)
      {
        this.uploadFileSuccess(res, fileObj);
        
      }else{
        fileObj.reset();
        fileObj.isError = true;

        if(res.repData.length > 0)
        {
          fileObj.path = res.repData[0]?.path;
          let modal = this.modalService.open(DialogComponent);
          modal.componentInstance.data = fileObj;
          modal.result.then((result) => {
            if(result.mode == 1)
            {
              this.doUploadOveright(result.file,false);
            }else if(result.mode == 2)
            {
              this.doUploadOveright(result.file,true);
            }
          });
        }
      }
    }).catch().finally(()=>{

    });
  }

  private uploadFileSuccess(res: ResponeResult, fileObj: FileObj) {
    for (let i = 0; i < res.repData.length; i++) {
      const element = res.repData[i];
      this.fileUploads.push(element);
    }
    fileObj.status = true;
    fileObj.finished();
    let index = this.filePendings.findIndex(x=>x.file == fileObj.file)
    this.filePendings.splice(index,1);
    if(this.filePendings.length <= 0 )
    {
      this.doClose();
    }
  }

  doClose(){
    this.filePendings = [];
    this.close.emit(this.fileUploads);
    this.fileUploads = [];
  }

  onDragEnter($event, insertionPoint:string){
    this.highlight($event);
  }
  onDragOver($event, insertionPoint:string){
    this.highlight($event);
    $event.dataTransfer.dropEffect = 'move';
  }
  onDragLeave($event, insertionPoint:string){
    this.unhighlight();
  }
  onDrop(event, insertionPoint:string){
    event.stopPropagation();
    event.preventDefault();
    this.isHighlight = false;
    let files = event.dataTransfer.files;
    if (files) {
      files = [...files];
      files.forEach( file=>{
        this.uploadFile(file)
      });
    }
  }

  inputFileChange(event) {
    let files = event.target.files;
    files = [...files];
    files.forEach( file=>{
      this.uploadFile(file)
    });
  }
  uploadFile(file) {
    let reader = new FileReader();
    var self = this;
    reader.readAsDataURL(file);
    reader.onloadend = function() {
      // self.preview = reader.result; //.push(reader.result);
      //let result = { file: file, preview: reader.result };
      self.doUpload(file, reader.result);
    };
  }
  highlight(e) {
    e.stopPropagation();
    e.preventDefault();
    this.isHighlight = true;
  }
  unhighlight() {
    this.isHighlight = false;
  }
}