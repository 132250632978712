import { JsonldModule } from './../shared/module/jsonld/jsonld.module';
import { LightboxModule } from 'ngx-lightbox';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/share.module';
import { NavMenuComponent } from '@app/frontend/layout/components/nav-menu/nav-menu.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SlickCarouselModule } from 'ngx-slick-carousel';

//componnet
import { FronendAppComponent } from '@app/frontend/layout/frontend/frontend-app.component';
import { NavFooterComponent } from '@app/frontend/layout/components/nav-footer/nav-footer.component';

import { HomeComponent } from './pages/home/home.component';
import { PackageComponent } from './pages/package/package.component';
import { PackageDetailComponent } from './pages/package-detail/package-detail.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServiceDetailsComponent } from './pages/servicedetails/servicedetails.component';
import { DoctorComponent } from './pages/doctors/doctors.component';
import { DoctorDetailComponent } from './pages/doctordetail/doctordetail.component';
import { BookingComponent } from './pages/booking/booking.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news-detail/newsdetail.component';
import { AboutComponent } from './pages/abouts/abouts.component';
import { JobComponent } from './pages/job/job.component';
import { JobDetailComponent } from './pages/job-detail/job-detail.component';
import { FaqAnswerComponent } from './pages/faq-answer/faq-answer.component';
import { TermConditionComponent } from './pages/termcondition/termcondition.component';
import { SecurityComponent } from './pages/security/security.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ShoppingComponent } from './pages/shopping/shopping.component';
import { PayComponent } from './pages/pay/pay.component';
import { ProductComponent } from './pages/product/product.component';
import { PageNotFoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { BusinessComponent } from './pages/business/business.component';
import { InsurranceComponent } from './pages/insurrance/insurrance.component';
import { FrontendhGuard } from '@shared/guards/frontend.guard';
import { CartModule } from '@shared/module/cart/cart.module';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { OtherPageComponent } from './pages/other-page/other-page.component';
import { PopupComponent } from './layout/frontend/popup/popup.component';
import { FrontendRoutingModule } from './frontend-routing.module';
import { DocumentsComponent } from './pages/documents/documents.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';

@NgModule({
  imports: [
    SharedModule,
    LightboxModule,
    NgxCaptchaModule,
    SlickCarouselModule,
    CartModule,
    FrontendRoutingModule,
    JsonldModule,
  ],
  declarations: [
    NavMenuComponent,
    FronendAppComponent,
    NavFooterComponent,
    HomeComponent,
    PackageComponent,
    PackageDetailComponent,
    ServicesComponent,
    ServiceDetailsComponent,
    DoctorComponent,
    DoctorDetailComponent,
    BookingComponent,
    NewsComponent,
    NewsDetailComponent,
    AboutComponent,
    JobComponent,
    JobDetailComponent,
    FaqAnswerComponent,
    TermConditionComponent,
    SecurityComponent,
    ContactComponent,
    ShoppingComponent,
    PayComponent,
    ProductComponent,
    PageNotFoundComponent,
    BusinessComponent,
    InsurranceComponent,
    SearchResultComponent,
    OtherPageComponent,
    PopupComponent,
    DocumentsComponent,
    LandingPageComponent,
  ],
  exports: [],
  providers: [
    // AuthService,
    FrontendhGuard,
  ],
})
export class FrontendModule {
  // constructor(private readonly translate: TranslateService) {
  //   // debugger;
  //   translate.setDefaultLang(translate.getBrowserLang());
  //   translate.use(translate.currentLang);
  // }
}
