import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from "src/app/core/BaseComponent";
import { DepartmentService } from "@shared/services/department.service";
import { ResponeResult } from "@core/models/ResponeResult";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ServicesComponent extends BaseComponent {
  public serviceDTO: ServiceDTO;

  constructor(
    private departmentService:DepartmentService
  ) {
    super();
    
    this.route.paramMap.subscribe(params => {
      this.getData();
    });
  }

  ngOnInit() {
    this.seo.title = "Chuyên khoa";
    this.updateSEO();
  }

  getData() {
    this.departmentService.noValid().getData().then((result:ResponeResult)=>{
      if (result.isOk) {
        this.serviceDTO = result.repData;
        this.seo.title = this.serviceDTO.service.titleName;
        this.seo.description = this.serviceDTO.service.shortDescription;
        this.updateSEO();
      }
    }).catch(()=>{
      this.router.navigate(['/page-not-found']);
    });
  }
}

export class ServiceDTO {
  Service: TextData;
  Imunization: TextData;
  Pharmacy: TextData;
  Departments: Department[];
  Packages: Package[];
  imunization: any;
  packages: any;
  pharmacy : any;
  service: any;
  departments: any;
}

export class TextData {
  optionId: number| any;
  titleName: string | any;
  shortDescription: string | any;
  imageSmall: string | any;
  imageLarge: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  isPublish: boolean;
  isActive: boolean = true;
}

export class Department {
  linkCode: string | any;
  titleName: string | any;
  newsTitle: string | any;
  departmentId: number| any;
  serviceTitle: string | any;
  departmentName: string | any;
  shortDescription: string | any;
  longDescription: string | any;
  logoImage: string | any;
  imagePath: string | any;
  imageLarge: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
}

export class Package {
  linkCode: string | any;
  listPrice: number| any;
  salePrice: number| any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  packageName: string | any;
  titleName: string | any;
  shortDescription: string | any;
  imagePath: string | any;
}
