export class Store {
  storeId: number| any;
  storeName: string | any;
  searchString: string | any;
  linkCode: string | any;
  address: string | any;
  notes: string | any;

  constructor(item?:any){
    this.storeName = item?.storeName?item.storeName:'Chọn phòng khám';
    this.searchString = item?.searchString?item.searchString:"";
    this.storeId = item?.storeId?item.storeId:0;
    this.address = item?.address?item.address:0;
    this.storeId = item?.notes?item.notes:0;
    this.linkCode = item?.linkCode?item.linkCode:0;
  }
}
