import {
  Injectable
} from '@angular/core';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import {
  Cart,
  CartItem,
  CartSummary
} from '../models/cart.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public showNav: boolean = false;

  private cartSubject: BehaviorSubject < Cart > ;
  public currentCart: Observable < Cart > ;

  constructor() {
    let store = localStorage.getItem('Cart');
    if(store)
    {
      this.cartSubject = new BehaviorSubject < Cart > (JSON.parse(store));
    }else{
      this.cartSubject = new BehaviorSubject < Cart > (null);
    }
    
    this.currentCart = this.cartSubject.asObservable();
  }

  public get value(): Cart {
    var cart;
    if (this.cartSubject.value == null) {
      cart = new Cart();
      this.cartSubject.next(cart);
    } else {
      cart = this.cartSubject.value;
      this.cartSubject.next(cart);
    }
    localStorage.setItem('Cart', JSON.stringify(cart));
    return this.cartSubject.value;
  }

  /**
   * Thêm 1 sản phẩm/package vào giỏ hàng
   */
  public add(product: any) {
    var cart = this.cartSubject.value;
    if (cart.summary.quantity == 0)
      cart.summary.createdDate = new Date;

    var isExisting = false;
    for (var i = 0; i < cart.cartItems.length; i++) {
      let item = cart.cartItems[i];
      if (item.packageId == product.packageId) {
        isExisting = true;
        item.quantity += product.quantity;
        break;
      }
    }

    if (!isExisting) {
      var rowData = new CartItem(product);
      rowData.sortOrder = cart.cartItems.length + 1;
      cart.cartItems.push(rowData);
    }
    this.calculatorSummary(cart);
    return true;
  }

  /**
   * Xóa 1 sản phẩm/package khỏi giỏ hàng
   */
  public remove(product) {
    let cart = this.cartSubject.value;
    var index = cart.cartItems.findIndex(x => x.packageId == product.packageId);
    cart.cartItems.splice(index, 1);
    this.calculatorSummary(cart);
  }

  /**
   * Xóa toàn bộ giỏ hàng
   */
  public clear() {
    localStorage.removeItem('Cart');
    var cart: Cart = new Cart();
    this.cartSubject.next(cart);
    localStorage.setItem('Cart', JSON.stringify(cart));
  }

  public clearAllItem() {
    let cart = this.cartSubject.value;
    cart.step = 2;
    cart.cartItems = [];
    cart.orderId = 0;
    this.calculatorSummary(cart);
  }

  public changeQuantity(product: CartItem) {
    var cart = this.cartSubject.value;
    if (product.quantity == 0) {
      var index = cart.cartItems.findIndex(x => x.packageId == product.packageId);
      cart.cartItems.splice(index, 1);
    } else {
      var rowData = cart.cartItems.find(x => x.packageId == product.packageId);
      if(rowData){
        rowData.quantity = product.quantity;
        rowData.listPrice = product.listPrice;
        rowData.salePrice = product.salePrice;
      }
    }
    this.calculatorSummary(cart);
  }

  public changeStoreCart() {
    localStorage.setItem('Cart', JSON.stringify(this.cartSubject.value));
  }

  private calculatorSummary(cart) {
    var quantity = 0,
      amount = 0,
      discountAmount = 0,
      totalAmount = 0;

    for (var i = 0; i < cart.cartItems.length; i++) {
      let item = cart.cartItems[i];

      item.amount = item.quantity  * item.listPrice;
      item.discountAmount = item.quantity  * item.listPrice -  item.quantity  * item.salePrice;
      item.totalAmount = item.amount - item.discountAmount;


      quantity += item.quantity;
      amount += item.amount;
      discountAmount += item.discountAmount;
      totalAmount += item.totalAmount;
    }
    cart.summary.quantity = quantity;
    cart.summary.amount = amount;
    cart.summary.discountAmount = discountAmount;
    cart.summary.totalAmount = totalAmount;
    localStorage.setItem('Cart', JSON.stringify(cart));
  }

  openMenuItem() {
    setTimeout(() => {
      this.showNav = true;
    }, 1);
  }

  closeMenuItem() {
    this.showNav = false;
  }
}
