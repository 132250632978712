<div class="container-fluid p-0">
  <!--Background top-->
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ textData.titleName }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">{{ textData.titleName }}</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<section class="bussiness pt-3">
  <div [innerHTML]="textData.longDescription | safeHtml"></div>

  <!-- logo doanh nghiệp -->
  <div class="bg-light py-5">
    <div class="container">
      <h4 class="title-top">{{ 'bussinessPage.title' | translate }}</h4>
      <div class="category" [dsMatchHeight]="'card'">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" (init)="slickInit($event)" [config]="slideConfig">
          <div class="card" ngxSlickItem *ngFor="let row of partners">
            <a href="">
              <img [src]="static(row.logo)" alt="{{ row.companyName }}" onError="this.src='/assets/images/thumb.png'" />
            </a>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
  <!-- mô tả doanh nghiêp -->
  <div class="sectionFour" *ngIf="photoRealistics && photoRealistics.length > 0">
    <div class="container">
      <h4 class="title-top">{{ 'bussinessPage.currImage' | translate }}</h4>
      <div class="row mt-3">
        <div *ngFor="let row of photoRealistics" class="col-12 col-sm-6 col-md-3">
          <div class="category">
            <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', 'hinh-anh-thuc-te', row.linkCode]">
              <img [src]="static(row.imageSmall)" alt="{{ row.thumbnailSeonews }}" />
            </a>
            <div class="title">
              <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', 'hinh-anh-thuc-te', row.linkCode]">{{ row.titleName }}</a>
            </div>
            <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
            <div class="footer text-center">
              <a [title]="row.titleName" class="link" class="btn btn-viewmore" [routerLink]="['/tin-tuc', 'hinh-anh-thuc-te', row.linkCode]">
                {{ 'buttons.readmore' | translate }}
              </a>
              <!-- <a class="btn btn-viewmore">Xem thêm</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- đăng kí  -->
  <app-block id="company-contact" classBlock="p-0" class="p-0">
    <div class="sectionFive">
      <div class="container">
        <form class="row" #frmContact="ngForm" (ngSubmit)="onSubmit(frmContact)">
          <div class="col-md-7 mx-auto">
            <div class="bgForm">
              <h4 class="title-top text-center">{{ 'bussinessPage.registerContract' | translate }}</h4>
              <div class="row">
                <div class="col-md-6 mb-2">
                  <app-input
                    [label]="'form.label.companyName' | translate"
                    placeholder="{{ 'bussinessPage.companyName' | translate }}"
                    [name-control]="'form.name.companyName' | translate"
                    name="companyName"
                    [(ngModel)]="reqData.companyName"
                    maxlength="250"
                    required
                  >
                  </app-input>
                </div>
                <div class="col-md-6 mb-2">
                  <app-input
                    [label]="'form.label.companyContactName' | translate"
                    [name-control]="'form.name.companyContactName' | translate"
                    name="companyContactName"
                    placeholder="{{ 'bussinessPage.referencePerson' | translate }}"
                    [(ngModel)]="reqData.fullName"
                    maxlength="250"
                    required
                  >
                  </app-input>
                </div>
                <div class="col-md-6 mb-2">
                  <app-input
                    [label]="'form.label.email' | translate"
                    [name-control]="'form.name.email' | translate"
                    name="Email"
                    placeholder="Email"
                    [(ngModel)]="reqData.email"
                    [pattern]="'[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'"
                    required
                  ></app-input>
                </div>
                <div class="col-md-6 mb-2">
                  <app-input
                    [label]="'form.label.phone' | translate"
                    [name-control]="'form.name.phone' | translate"
                    name="phone"
                    placeholder="{{ 'bussinessPage.phone' | translate }}"
                    [(ngModel)]="reqData.phoneNumber"
                    [pattern]="'^((\\+84-?)|0)?[0-9]{0,50}$'"
                    maxlength="50"
                    [required]="true"
                  ></app-input>
                </div>
                <!-- onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" -->
                <div class="col-md-12 mb-2">
                  <app-input
                    [label]="'form.label.companyAddress' | translate"
                    [name-control]="'form.name.companyAddress' | translate"
                    name="companyAddress"
                    [(ngModel)]="reqData.companyAddress"
                    maxlength="250"
                    required
                  ></app-input>
                </div>
                <!-- <div class="col-md-6 mb-2">
                  <app-input
                    [label]="'form.label.companyExamNumber' | translate"
                    [name-control]="'form.name.companyExamNumber' | translate"
                    name="companyExamNumber"
                    [(ngModel)]="reqData.companyExamNumber"
                    type="number"
                    required
                  ></app-input>
                </div> -->
                <div class="col-12 mb-2">
                  <app-input
                    type="textarea"
                    [label]="'form.label.content' | translate"
                    [name-control]="'form.name.content' | translate"
                    name="content"
                    [(ngModel)]="reqData.contents"
                    maxlength="4000"
                    rows="4"
                  ></app-input>
                </div>
              </div>
              <div class="col-12 text-center text-lg-left">
                <button type="submit" class="btn btn-contact mx-auto"><i class="fas fa-check"></i> Gửi yêu cầu</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </app-block>
</section>
