import { Component } from '@angular/core';
import { BaseComponent } from "src/app/core/BaseComponent";
import { ProductService } from "@shared/services/product.service";
import { ResponeResult } from "@core/models/ResponeResult";
import { TextdataService } from '@shared/services/textdata.service';
import { TextData } from '@shared/models/textdata.model';
import { ConfigService } from '@shared/services/config.service';
import { OptionSystem, SHOW_INSERANCE_PRICE } from '@shared/models/option-system';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

export class ProductComponent extends BaseComponent {
  public product: Product[];
  public textData:TextData = new TextData;
  public showInsurancePrice:OptionSystem = new OptionSystem;
  
  constructor(
    private productService:ProductService,
    private textDataService:TextdataService,
    private configService:ConfigService
  ) {
    super();
    
    this.route.paramMap.subscribe(params => {
      this.getData();
    });

    this.configService.noValid().getOptionHome({key:SHOW_INSERANCE_PRICE}).then((res:ResponeResult)=>{
      this.showInsurancePrice = res.repData;
      this.showInsurancePrice.optionValue = JSON.parse(res.repData.optionValue);
    });
  }

  ngOnInit() {
    this.seo.title = "Bảng giá dịch vụ";
    this.updateSEO();
  }

  getData() {

    this.textDataService.noValid().getTextDataByID("SERVICE-PRICE-LIST").then((res: ResponeResult)=>{
      this.textData = res.repData;
      this.seo.title = this.textData.titleName;
      this.seo.description = this.textData.shortDescription;
      this.updateSEO();
    }).catch().finally(()=>{ });

    this.productService.getData().then((result:ResponeResult)=>{
      if (result.isOk) {
        this.product = result.repData;       
      }
    }, error => console.error(error));
  }
}

export class Product {
  productCode: string | any;
  productName: string | any;
  productNameEng: string | any;
  listPrice: number| any;
  price: number| any;
  serviceType: string | any;
  createdDate: Date| any;
  createdBy: number| any;
  modifyDate?: Date| any;
  modifyBy?: number| any;
  sortOrder?: number| any;
  isPublish: boolean;
}
