import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
// import { HttpClient } from '@angular/common/http';
import { BaseService } from '@core/BaseService';
import { ResponeResult } from '@core/models/ResponeResult';
import { User } from '@shared/models/user';
import { UniversalStorage } from '@shared/storage/universal.storage';
// import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthService extends BaseService{
  private _authToken: string;
  private _authState: BehaviorSubject<boolean>;
  private _interruptedUrl: string;
  private _initialData: string[] = [
    'interruptedUrl','currentUser', "token", "role"
  ];
  public currentUser: User;

  constructor(private _router: Router, @Inject(UniversalStorage) private _appStorage: Storage,) {
    super();
    // this._appStorage = new UniversalStorage();
    // this._appStorage.setItem('interruptedUrl',"123");
    // this.cookieService.put("xx","xxx");
    // this.cookieService.put("sss","hii");
    this._authState = new BehaviorSubject(!1);
    this._initialData.forEach((value) => {
      this[value] = this._getStoredItems(value);
    });
   
    // console.log("XXQ",this.currentUser);
  }

  public get interruptedUrl(): string {
    return this._interruptedUrl;
  }

  public set interruptedUrl(url: string) {
    this._interruptedUrl = url;
    if (!url) {
      // this.cookieService.remove("interruptedUrl");
      // localStorage.removeItem("interruptedUrl");
      this._appStorage.removeItem('interruptedUrl');
    } else {
      this._saveValueInLocalStorage('interruptedUrl', url);
    }
  }

  public get token(): string {
    return this._authToken ? this._authToken : '';
  }

  public set token(token: string) {
    this._authToken = token;
    this.changeAuthState = !!token;
  }

  public set changeAuthState(newState: boolean) {
    this._authState.next(newState);
  }

  public isAuthenticated(): boolean {
    // This method is required to implement authentication.
    //this._saveValueInLocalStorage('token',"TOKENDEMOEXAMPLE");
    //return true;
    return !!this.token;
  }

  public logIn( user: string, password: string ) {
    return new Promise((resolve, reject)=>{
      this.post("admin/login", {
          username:user,
          password:password
      }).then((res : ResponeResult)=>{
        if(res.isOk)
        {
          this._saveValueInLocalStorage('token',res.repData.token);
          this._saveValueInLocalStorage('role',res.repData.role);
          let userData = Object.assign({}, res.repData);
          delete userData.role;
          delete userData.token;
          this.currentUser = userData;
          this._saveValueInLocalStorage('currentUser',JSON.stringify(userData));
          // this._router.navigate([this.interruptedUrl && this.interruptedUrl.length ? this.interruptedUrl : '/home'])
          // .then(() => {
          //   this.interruptedUrl = '';
          //   // TODO: If Notification (toast) service is present can show successfully Logged in message
          // });
          resolve(res);
        }else {
          reject(res);
        }
      }).catch(err=>{
        reject(err);
      });
    });
  }

  public clearToken() {
    this.token = '';
    // this.cookieService.remove("currentUser");
    // this.cookieService.remove("token");
    this._appStorage.removeItem("currentUser");
    this._appStorage.removeItem("token");
    this._appStorage.removeItem("role");
  }

  public logOut() {
    this.token = '';
    // this.cookieService.remove("currentUser");
    // this.cookieService.remove("token");
    // localStorage.clear();
    this._appStorage.clear();
    this._router.navigate(['/auth', 'login']).then(() => {
      // TODO: If Notification (toast) service is present can show successfully Logged out message
    });
  }

  private _getStoredItems(key: string): any {
    if(key == "currentUser"){
      let strUser = this._appStorage.getItem(key);// localStorage.getItem('currentUser');
      // let strUser = this.cookieService.get(key);
      if(strUser && strUser != "" && strUser != "undefined")
        return <User>JSON.parse(strUser);
      return new User;
    }else if(key == "role"){
      let strRole = this._appStorage.getItem(key);// localStorage.getItem('currentUser');
      // let strUser = this.cookieService.get(key);
      if(strRole && strRole != "" && strRole != "undefined")
        return JSON.parse(strRole);
      return {};
    }
    // return localStorage.getItem(key);
    // return this.cookieService.get(key);
    return this._appStorage.getItem(key);//
  }

  private _saveValueInLocalStorage(key: string, value: string): void {
    // For saving auth token in Cookie storage.
    // localStorage.setItem(key, value);
    // this.cookieService.put(key,value);
    this._appStorage.setItem(key, value);
    if (key === 'token') {
      this.token = value;
    }
  }
}
