import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class DocterService extends BaseService {  
  //frontend
  public getData(query) {
    return this.get("home/GetDoctors", query);
  }
  public getDataID(query) {
    return this.get("home/GetDoctorByID", query);
  }

  //backend
  public getInitalDoctor(query) {
    return this.get("admin/GetInitalDoctor", query);
  }
  public getDoctors(query) {
    return this.get("admin/GetDoctors", query);
  }
  public saveDoctor(body) {
    return this.post("admin/SaveDoctor",body);
  }
  public deleteDoctor(body) {
    return this.post("admin/DeleteDoctor",body);
  }
  public updateOrderDoctor(body) {
    return this.post("admin/UpdateOrderDoctor",body);
  }
}