export class MegaPay{
  domain:string = ""
  merId:string = ""
  merTrxId:string = ""
  timeStamp:string = ""
  token:string = ""
  description:string="";
  goodsNm:string ="";
  currency:string ="";
  invoiceNo:string = "";
  notiUrl:string = "";
  userLanguage:string="";
  amount:number =0;
}

export class MegaPayResponse{
  amount:number = 0; // "10000"
  bankId:string = "";// "SML"
  bankName:string = "";// "SML"
  buyerFirstNm: string = "";//""
  buyerLastNm: string = "";//""
  cardNo: string = "";//"970400xxxxxx0018"
  cardType: string = "";//""
  currency: string = "";//"VND"
  domesticToken: string = "";//""
  endDt: string = "";//""
  firstAmount: string = "";//""
  goodsNm: string = "";//"tea milk"
  instmntMon: string = "";//""
  instmntType: string = "";//""
  invoiceNo: string = "";//"CODE123456"
  issueBankName: string = "";//""
  merId: string = "";//"EPAY000001"
  merTrxId: string = "";//"MERTRXID20210412165931_7286"
  merchantFeeIs: string = "";
  merchantToken: string = "";//"61257b4b358d49c0ba6502a93eaa3298efd7710e9cf10bae956c23ce22024499"
  nextAmount: string = "";//""
  payType: string = "";//"DC"
  resultCd: string = "";//"00_000"
  resultMsg: string = "";//"SUCCESS"
  startDt: string = "";//""
  subappid: string = "";//""
  termIs: string = "";//""
  timeStamp: number = 0;//"20210412165931"
  transDt: number =0;//"20210412"
  transTm: number =0;//"170202"
  trxId: string = "";//"EPAY000001DC202104121702028505"
  userFeeIs: string = "";//""
  vaName: string = "";//""
  vaNumber: string = "";//""

  constructor(payload: Partial<MegaPayResponse>){
    for(const key in payload){
      if(this.hasOwnProperty(key)){
        this[key] = payload[key];
      }
    }
  }
}