import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class PartnerService extends BaseService {
  //frontend
  public getData() {
    return this.get("home/GetPartner");
  }
  public getDataAs() {
    return this.get("home/GetInsurance");
  }

  //backend
  public getPartner(query) {
    return this.get("admin/GetPartner", query);
  }
  public savePartner(body) {
    return this.post("admin/SavePartner",body);
  }
  public deletePartner(body) {
    return this.post("admin/DeletePartner",body);
  }
  public updateOrderPartner(body) {
    return this.post("admin/UpdateOrderPartner",body);
  }
}