<div class="cart-menu-item align-middle" [ngClass]="{ open: cartService.showNav }" #cart>
  <div class="button-cart">
    <a [routerLink]="['/gio-hang']" class="d-flex">
      <img src="/assets/images/icon/shop.svg" alt="Giỏ hàng" style="width: 22px; height: 22px" />
      <span class="d-none d-xl-inline-block">{{ 'cart.label' | translate }}</span>
    </a>
    <span class="badge">{{ cartService.value.summary.quantity }}</span>
  </div>
  <div class="panel-cart">
    <div class="arrow">
      <i class="fa-solid fa-caret-up"></i>
    </div>
    <div class="context" *ngIf="cartService.value.cartItems.length > 0; else nocart">
      <ul class="cart-list-product">
        <li *ngFor="let item of cartService.value.cartItems">
          <button class="remove" (click)="removeItem(item)">
            <i class="fa-solid fa-xmark"></i>
          </button>
          <a [routerLink]="['/goi-kham', item.categoryCode, item.linkCode]">
            <img [src]="static(item.imagePath)" alt="{{ item.packageName }}" onError="this.src='/assets/images/thumb.png'" />
            <span>{{ item.packageName }}</span>
          </a>
          <span class="quantity">
            <span>{{ item.quantity }}</span> x <span>{{ item.salePrice | number : 0 }} đ</span>
          </span>
        </li>
      </ul>
      <p class="cart-total">
        <strong>{{ 'cart.summary.total' | translate }} : </strong>
        <span class="amount">{{ cartService.value.summary.totalAmount | number : 0 }} đ</span>
      </p>
      <div class="d-grid buttons">
        <a class="btn btn-block btn-primary mb-1" [routerLink]="['/gio-hang']">{{ 'cart.button.view' | translate }}</a>
        <a class="btn btn-block btn-success" [routerLink]="['/thanh-toan']">{{ 'cart.button.checkout' | translate }}</a>
      </div>
    </div>
    <ng-template class="context" #nocart>
      <p class="text-center pt-3">{{ 'cart.lbnocart' | translate }}</p>
    </ng-template>
  </div>
</div>
