<div ngbDropdown class="d-inline-block">
  <button class="btn d-flex align-items-center p-1" id="dropdownBasic1" ngbDropdownToggle>
    <div class="icon me-2">
      <img [src]="'/assets/images/'+currentLang.code+'.svg'" [alt]="currentLang.name" style="width:22px; height: 22px;" />
    </div>
    <!-- /d-none d-lg-inline-block -->
    <span class="d-none d-md-inline-block" >{{currentLang.name}}</span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <button ngbDropdownItem *ngFor="let lang of langList$ | async" (click)="changeLang(lang)">
      <div class="d-flex align-items-center">
        <div class="icon me-3">
          <img [src]="'/assets/images/'+lang.code+'.svg'" [alt]="lang.name" style="width:20px; height: 20px;" />
        </div>
        {{ lang.name }}
      </div>
    </button>
  </div>
</div>