import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './components/cart/cart.component';
import { CartMenuItemComponent } from './components/cart-menu-item/cart-menu-item.component';
import { RouterModule } from '@angular/router';

import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { TranslatesModule } from '../translate/translates.module';
import { InputModule } from '../input/input.module';
import { FormsModule } from '@angular/forms';
import { ModalModule } from "../modal/modal.module";
import { BlockModule } from "../block/block.module";



@NgModule({
    declarations: [CartComponent, CartMenuItemComponent, AddToCartComponent],
    exports: [
        CartMenuItemComponent,
        AddToCartComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslatesModule,
        InputModule,
        FormsModule,
        ModalModule,
        BlockModule
    ]
})
export class CartModule { }
