<app-jsonld [json]="schema"></app-jsonld>
<div class="bg-light">
  <!--Section Slide-->
  <div class="slide">
    <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let row of homeDTO.slides; let index = index" [ngClass]="{ active: index == 0 }">
          <div class="picsum-img-wrapper">
            <a *ngIf="row.pageLink" [href]="row.pageLink">
              <img [src]="row.imagePath | static" loading="lazy" width="1920" height="645" [alt]="row.imageSeotitle" />
            </a>
            <img *ngIf="!row.pageLink" [src]="row.imagePath | static" loading="lazy" width="1920" height="645" [alt]="row.imageSeotitle" />
          </div>
          <div class="carousel-caption">
            <div class="container">
              <div class="overlay">
                <div class="row">
                  <div class="col-12 col-md-7 mt-auto">
                    <div *ngIf="row.isDisplayContent" class="description" [innerHTML]="row.contents"></div>
                  </div>
                </div>
              </div>
              <div class="overlay-mobile">
                <div class="col-7 ms-auto p-0">
                  <div *ngIf="row.isDisplayContent" class="description" [innerHTML]="row.contents"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" style="z-index: 21" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" style="z-index: 21" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div *ngIf="homeDTO.slides.length == 0" class="slide-loading">
      <div class="text">Đang tải slide ...</div>
    </div>
    <div class="services d-none d-lg-block col-md-5">
      <div class="container">
        <div class="row">
          <div class="col-6 col-md-6 block pe-0" [routerLink]="['/dat-lich-kham']">
            <div class="block-content">
              <i class="fa-solid fa-calendar-days fa-2x"></i>
              <strong class="mt-3 h5">{{ 'homePage.sliderBooking' | translate }}</strong>
            </div>
          </div>
          <div class="col-6 col-md-6 block ps-0" [routerLink]="['/chuyen-khoa/tiem-chung']">
            <div class="block-content">
              <i class="fa-solid fa-syringe fa-2x"></i>
              <strong class="mt-3 h5">{{ 'homePage.sliderVaccin' | translate }}</strong>
            </div>
          </div>
          <div class="col-6 col-md-6 block pe-0" [routerLink]="['/suc-khoe-doanh-nghiep']">
            <div class="block-content">
              <i class="fa-solid fa-building fa-2x"></i>
              <strong class="mt-3 h5">{{ 'homePage.sliderEnterprise' | translate }}</strong>
            </div>
          </div>
          <div class="col-6 col-md-6 block ps-0" [routerLink]="['/goi-kham']">
            <div class="block-content">
              <i class="fa-solid fa-user-doctor fa-2x"></i>
              <strong class="mt-3 h5">{{ 'homePage.sliderPackage' | translate }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Box Mobile-->
  <section class="box-mobile d-lg-none">
    <div class="col-12">
      <div class="row px-3">
        <div class="col-6 col-sm-3 block" [routerLink]="['/dat-lich-kham']">
          <div class="block-content">
            <i class="fa-solid fa-calendar-days fa-2x"></i>
            <strong class="h5 mt-3">{{ 'homePage.sliderBooking' | translate }}</strong>
          </div>
        </div>
        <div class="col-6 col-sm-3 block" [routerLink]="['/chuyen-khoa/tiem-chung']">
          <div class="block-content">
            <i class="fa-solid fa-syringe fa-2x"></i>
            <strong class="h5 mt-3">{{ 'homePage.sliderVaccin' | translate }}</strong>
          </div>
        </div>
        <div class="col-6 col-sm-3 block" [routerLink]="['/suc-khoe-doanh-nghiep']">
          <div class="block-content">
            <i class="fa-solid fa-building fa-2x"></i>
            <strong class="h5 mt-3">{{ 'homePage.sliderEnterprise' | translate }}</strong>
          </div>
        </div>
        <div class="col-6 col-sm-3 block" [routerLink]="['/goi-kham']">
          <div class="block-content">
            <i class="fa-solid fa-user-doctor fa-2x"></i>
            <strong class="h5 mt-3">{{ 'homePage.sliderPackage' | translate }}</strong>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- chuyên khoa -->
  <section class="department pt-3" [@fade]="'active'">
    <div class="container">
      <strong class="title-top h5">{{ 'homePage.specialist' | translate }}</strong>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" (init)="slickInit($event)" [config]="slideConfig">
        <div
          ngxSlickItem
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="600"
          data-aos-duration="500"
          *ngFor="let row of homeDTO.departments; let index = index"
          class="text-center"
        >
          <img
            width="165"
            height="165"
            loading="lazy"
            data-src="{{ static(row.logoImage) }}"
            [alt]="row.imageSeotitle"
            class="img-fluid"
            onError="this.src='/assets/images/thumb.png'"
          />
          <strong class="title">{{ row.departmentName }}</strong>
          <div class="img-boder"><img width="410" height="3" alt="simmed border" src="/assets/images/general/border.png" /></div>
          <div class="text text-line-4" [innerHTML]="row.shortDescription | safeHtml"></div>
          <a class="btn btn-viewmore rev-btn" [routerLink]="['/chuyen-khoa', row.linkCode]">
            {{ 'buttons.readmore' | translate }}
          </a>
        </div>
      </ngx-slick-carousel>
    </div>
  </section>

  <!-- Gói khám -->
  <section class="package clearfix card-block pt-3" [@fade]="'active'">
    <div class="container">
      <strong class="title-top h5">{{ 'homePage.package' | translate }}</strong>
      <div class="row" [dsMatchHeight]="'card'">
        <div class="col-md-12 col-lg-6 mb-3" *ngFor="let row of homeDTO.packages; let i = index">
          <div class="category card" data-aos-anchor=".department" data-aos="zoom-in" data-aos-offset="600" data-aos-duration="700">
            <div class="row">
              <!-- images -->
              <div class="col-sm-6 col-md-5 col-image">
                <div class="discount">
                  <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" alt="discount1" />
                </div>
                <div class="image">
                  <a class="link" [title]="row.packageName" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                    <img
                      loading="lazy"
                      data-src="{{ static(row.imagePath) }}"
                      [alt]="row.imageSeotitle"
                      width="600"
                      height="375"
                      onError="this.src='/assets/images/thumb.png'"
                    />
                    <div class="disccountText" *ngIf="row.isDisccount && row.disccountText && row.disccountText.length > 0">
                      <img class="icon" src="/assets/images/promotion.png" width="50px" alt="promotion" />
                      <div class="text" [innerHTML]="row.disccountText"></div>
                    </div>
                  </a>
                </div>
              </div>
              <!-- content -->
              <div class="col-sm-6 col-md-7">
                <div class="title">
                  <a [title]="row.packageName" class="link" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                    <strong class="h3 h5">{{ row.packageName }}</strong>
                  </a>
                  <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                  <div class="pt-2">
                    <div class="d-flex justify-content-start">
                      <span class="salePrice">{{ row.salePrice | number : 0 }}₫</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!---vaccine -->
  <section class="vaccinex clearfix card-block bg-light-2 py-3" [@fade]="'active'">
    <div class="container">
      <strong class="title-top h5">{{ 'homePage.vaccin' | translate }}</strong>
      <div class="row" [dsMatchHeight]="'card-vaccine'">
        <div class="col-md-3 col-sm-6 mb-3" *ngFor="let row of homeDTO.vaccine">
          <!-- <a [title]="row.productName" class="link" [routerLink]="['/chuyen-khoa/tiem-chung']" [queryParams]="{ vaccin: row.productCode }">
            <div class="category card card-vaccine mb-0" data-aos-anchor=".package" data-aos="zoom-in" data-aos-offset="600" data-aos-duration="700">
              <div class="vaccine-title">
                <strong class="h5 h3">{{ row.productName }}</strong>
              </div>
              <img
                width="1152"
                height="538"
                loading="lazy"
                [alt]="row.productName"
                data-src="{{ static(row.image) }}"
                [alt]="row.productName"
                onError="this.src='/assets/images/thumb.png'"
              />

              <div class="content">
                <p [innerHTML]="row.comments | safeHtml"></p>
              </div>
            </div>
          </a> -->
          <a [title]="row.productName" class="link" [href]="row.url && row.url != '' ? row.url : '/chuyen-khoa/tiem-chung?vaccin=' + row.productCode">
            <div class="category card card-vaccine mb-0" data-aos-anchor=".package" data-aos="zoom-in" data-aos-offset="600" data-aos-duration="700">
              <div class="vaccine-title">
                <strong class="h5 h3">{{ row.productName }}</strong>
              </div>
              <img
                width="1152"
                height="538"
                loading="lazy"
                [alt]="row.productName"
                data-src="{{ static(row.image) }}"
                [alt]="row.productName"
                onError="this.src='/assets/images/thumb.png'"
              />

              <div class="content">
                <p [innerHTML]="row.comments | safeHtml"></p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- <div class="footer text-center">
        <button [routerLink]="['/chuyen-khoa/tiem-chung']"
          class="btn btn-viewmore">{{'buttons.readmore' |
        translate}}</button>
      </div> -->
    </div>
  </section>

  <!-- doctor -->
  <section class="doctor-related card-block pt-3" [@fade]="'active'">
    <div class="container">
      <strong class="title-top h5">{{ 'homePage.doctor' | translate }}</strong>
      <div class="row">
        <div class="col-lg-3 col-md-6 mb-3" *ngFor="let row of doctors">
          <div class="category card" data-aos-anchor=".vaccine" data-aos="zoom-out" data-aos-offset="600" data-aos-duration="700">
            <a [title]="row.doctorName" class="link" [routerLink]="['/bac-si', row.linkCode]">
              <img
                width="600"
                height="685"
                [alt]="row.doctorName"
                class="img"
                loading="lazy"
                data-src="{{ static(row.imageSmall) }}"
                [alt]="row.imageSeotitle"
                onError="this.src='/assets/images/thumb.png'"
              />
            </a>
            <div class="doctor-title form-inline mt-2">
              <a [title]="row.doctorName" class="link" class="form-inline" [routerLink]="['/bac-si', row.linkCode]">
                <strong class="doctor-level text-uppercase">{{ row.levelName }} &nbsp; </strong>
                <span class="doctor-name text-uppercase">{{ row.doctorName }}</span>
              </a>
            </div>
            <div class="content" [innerHTML]="row.shortDescription | safeHtml"></div>
            <div class="footer text-center pt-3">
              <a [title]="row.doctorName" class="btn btn-viewmore" [routerLink]="['/bac-si', row.linkCode]"> {{ 'buttons.readmore' | translate }} </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!---news -->
  <section class="blog bg-white card-block pt-3" [@fade]="'active'">
    <div class="container">
      <strong class="title-top h5">{{ 'homePage.news' | translate }}</strong>
      <div class="row">
        <div class="col-lg-3 col-md-6 mb-3" *ngFor="let row of homeDTO.news">
          <div class="category card card-blog" data-aos-anchor=".doctor-related" data-aos="flip-left" data-aos-offset="600" data-aos-duration="700">
            <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
              <img
                width="300"
                height="187"
                class="image"
                style="width: 100%; height: auto"
                loading="lazy"
                data-src="{{ static(row.imageSmall) }}"
                [alt]="row.imageSeotitle"
              />
            </a>
            <!-- <div class="label">
            <label>{{row.newsTitle}}</label>
            <label class="timeDate float-right"><i class="far fa-calendar-alt px-2"
                aria-hidden="true"></i>{{row.newsDate | date: 'dd/MM/yyyy'}}</label>
          </div> -->
            <div class="title pt-3">
              <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                <strong class="mb-0">{{ row.titleName }}</strong>
              </a>
            </div>
            <div class="content" [innerHTML]="row.shortDescription | safeHtml"></div>
            <div class="footer text-center">
              <a [title]="row.titleName" class="link" class="btn btn-viewmore" [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">{{
                'buttons.readmore' | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="reviews">
    <div class="top">
      <div class="container">
        <div class="heading" data-aos="fade-up" data-aos-duration="1000">
          <h3 class="title">Chia sẻ từ khách hàng</h3>
          <!-- <p>Khi tham gia dịch vụ tại SIM Med</p> -->
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <div class="list-reviews">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideReviewConfig">
            <div ngxSlickItem *ngFor="let slide of reviews" class="slide-item" data-aos="flip-up" data-aos-duration="1000">
              <div class="avt">
                <img data-src="{{ slide.avt }}" loading="lazy" width="130" height="130" alt="avt" />
              </div>
              <div class="d-flex justify-content-between">
                <img class="q" width="25" height="25" src="/assets/images/landing-page/icon_q_01.svg" alt="q" />
                <div class="d-flex stars">
                  <i class="xfa-star active"></i>
                  <i class="xfa-star active"></i>
                  <i class="xfa-star active"></i>
                  <i class="xfa-star active"></i>
                  <i class="xfa-star active"></i>
                </div>
              </div>
              <p class="desc">{{ slide.desc }}</p>
              <div class="d-flex justify-content-end">
                <img width="25" height="25" class="q" src="/assets/images/landing-page/icon_q_02.svg" alt="q" />
              </div>

              <a [href]="slide.link" target="_blank" class="btn btn-sm btn-info">
                <div class="es-logo">
                  <img data-src="/assets/images/ggrv.webp" loading="lazy" width="25" height="25" alt="gg" />
                </div>
                Xem tại google Maps
              </a>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </section>

  <!-- Tin sở yte -->
  <section class="card-block syt-new">
    <div class="container">
      <div class="text-center">
        <img width="145" alt="ytehcm" height="145" loading="lazy" data-src="https://api.simmed.vn/assets/uploads/ytehcm.webp" class="logo" />
        <strong class="h5 title my-3">Cổng thông tin Sở Y Tế HCM</strong>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-4">
          <div class="head">
            <strong class="title h5">Thông báo</strong>
          </div>
          <ul class="sty-news">
            <li class="sty-new-item" *ngFor="let item of newSYT.thongBao">
              <a class="title" [href]="item.urlLink">
                {{ item.title }}
              </a>
              <hr style="margin: 6px 0" />
              <div class="d-flex justify-content-between item-bottom">
                <span class="time"><i class="fa fa-solid fa-clock me-2"></i>{{ item.date | date : 'dd/MM/yyyy' }}</span>
                <a href="https://medinet.gov.vn/" target="_blank">Sở y tế HCM</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <div class="head">
            <strong class="h5 title">Tin Tức</strong>
          </div>
          <ul class="sty-news">
            <li class="sty-new-item" *ngFor="let item of newSYT.tinTuc">
              <a class="title" [href]="item.urlLink">
                {{ item.title }}
              </a>
              <hr style="margin: 6px 0" />
              <div class="d-flex justify-content-between item-bottom">
                <span class="time"><i class="fa fa-solid fa-clock me-2"></i>{{ item.date | date : 'dd/MM/yyyy' }}</span>
                <a href="https://medinet.gov.vn/" target="_blank">Sở y tế HCM</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <div class="head">
            <strong class="title h5">Văn bản</strong>
          </div>
          <ul class="sty-news">
            <li class="sty-new-item" *ngFor="let item of newSYT.vanBan">
              <a class="title" [href]="item.urlLink">
                {{ item.title }}
              </a>
              <hr style="margin: 6px 0" />
              <div class="d-flex justify-content-between item-bottom">
                <span class="time"><i class="fa fa-solid fa-clock me-2"></i>{{ item.date | date : 'dd/MM/yyyy' }}</span>
                <a href="https://medinet.gov.vn/" target="_blank">Sở y tế HCM</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!-- popup -->
  <jw-modal id="modal-pop">
    <div class="jw-modal-body">
      <div class="btnclose" (click)="closeModal('modal-pop')">
        <button>
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="popup-header"></div>
    </div>
  </jw-modal>
</div>
