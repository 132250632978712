import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from "src/app/core/BaseComponent";
import { PaginationComponent } from "@shared/module/pagination/pagination.component";
import { NewService } from "@shared/services/new.services";
import { QueryObj } from "@shared/models/query.model";
import { ResponeResult } from "@core/models/ResponeResult";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class NewsComponent extends BaseComponent {
  public news: News[] = [];
  public urlLink: string | any;
  private linkCode: string | any;
  categoryName: string | any;

  public query: QueryObj = new QueryObj;
  // total: number = 0;
  // page: number = 1;
  // limit: number = 8;
  // startRow: number = 0;

  public isNewLoad:Boolean = false;
  public isLoadding:Boolean = false;

  @ViewChild(PaginationComponent, { static: true }) pagingComponent: PaginationComponent;
  constructor(
    public route: ActivatedRoute, 
    private newService: NewService
    ) {    
    super();    
    this.route.paramMap.subscribe(params => {
      this.linkCode = this.route.snapshot.paramMap.get("id");
      // this.startRow = 0;
      // this.total = 0;
      this.query.page = 1;
      this.isNewLoad = true;
      this.getData();
    });
  }

  ngOnInit() {
    this.seo.title = "Tin tức";
    this.updateSEO();
  }

  getData() {
    this.query.linkCode = this.linkCode;
    this.query.startRow = this.getStartRow();
    this.query.maxRows = this.query.limit;
    this.isLoadding = true;
    this.newService.getNew(this.query).then((result:ResponeResult)=>{
      if (result.isOk) {
        //this.news = result.repData;
        if(this.isNewLoad)
        { 
            this.news = result.repData;
            this.query.total = result.totalRow;
            this.isNewLoad = false;
        }else{
          this.news = this.news.concat(result.repData);
          this.query.total += result.totalRow;
        }
       
        // this.total = result.totalRow;
        
        if (this.news != null && this.news.length > 0) {
          this.categoryName = this.news[0].categoryName;
          this.seo.title = this.categoryName;
          this.updateSEO();
        }
        else {
          //Gõ sai url param thì load default tin tức simmed
          this.router.navigate(['tin-tuc/tin-tuc-simmed']);
        }
      }
    }, error => console.error(error)).finally(()=>{
      this.isNewLoad = false;
      this.isLoadding = false;
    });
  }

  LoadMoreOrder = () => {
    if(Number(this.query.limit) * this.query.page >= this.query.total)
    {
      return;
    }else{
      this.query.page ++;
      this.getData();
    }
  }

  get isLoadMore(){
    return Number(this.query.limit) * this.query.page < this.query.total
  }

  getStartRow(): number {
    const startRow = (this.query.page - 1) * this.query.limit;
    return startRow;
  }

  goToPage(n: number): void {
    this.query.page = n;
    this.query.startRow = this.getStartRow();
    this.getData();
  }

  changeLimit() {
    this.query.page = 1;
    this.query.startRow = this.getStartRow();
    this.getData();
  }

  onNext(): void {
    this.query.page++;
    this.query.startRow = this.getStartRow();
    this.getData();
  }

  onPrev(): void {
    this.query.page--;
    this.query.startRow = this.getStartRow();
    this.getData();
  }
}

export class News {
  linkCode: string | any;
  linkCategory: string | any;
  titelSeonews: string | any;
  descriptionSeonews: string | any;
  thumbnailSeonews: string | any;
  imageSeotitle: string | any;
  imageSeodescription: string | any;
  newsDate: Date| any;
  titleName: string | any;
  shortDescription: string | any;
  imageSmall: string | any;
  newsTitle: string | any;
  categoryName: string | any;
}