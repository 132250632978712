import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateMenuItemComponent } from './components/translate-menu-item/translate-menu-item.component';
import { TranslatesService } from '.';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { TransferState } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { translateBrowserLoaderFactory } from '@shared/loaders/translate-browser.loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonMissingTranslationHandler } from './services/translates.service';



export function initLanguage(translateService: TranslatesService): Function {
  return (): Promise<any> => translateService.initLanguage();
}

@NgModule({
  declarations: [TranslateMenuItemComponent],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CommonMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
  ],
  exports:[
    TranslateMenuItemComponent,
    TranslateModule
  ],
  providers:[
    TranslateStore,TransferState,
    { provide: APP_INITIALIZER, useFactory: initLanguage, multi: true, deps: [TranslatesService] },
  ]
})
export class TranslatesModule {
  static forChild(arg0: { loader: { provide: typeof TranslateLoader; useFactory: (httpClient: HttpClient, transferState: TransferState) => import("@shared/loaders/translate-browser.loader").TranslateBrowserLoader; deps: (typeof HttpClient | typeof TransferState)[]; }; }): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
    throw new Error('Method not implemented.');
  }
}
