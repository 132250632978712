import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class AboutService extends BaseService {
  //frontend
  public getData(query) {
    return this.get("home/GetAbout",query);
  }

  //backend
  public getAbout(query) {
    return this.get("admin/GetAbout", query);
  }
  public saveAbout(body) {
    return this.post("admin/SaveAbout", body);
  }
  public deleteAbout(body) {
    return this.post("admin/DeleteAbout", body);
  }
}