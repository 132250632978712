import { DialogService } from './../../../dialog/dialog.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ResponeResult } from '@core/models/ResponeResult';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryObj } from '@shared/models/query.model';
import { NotiService } from '@shared/module/notification';
import { BlockService } from '@shared/module/block/services/block.service';
import { MediaService } from '@shared/module/media-library/services/media.service';
import stringUtil from '@shared/utils/string.util';
import { environment } from 'src/environments/environment';
import { Album, ReqAlbum } from '../../models/album.model';
import { ButtonDialog, ButtonType } from '@shared/module/dialog/dialog-config';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {

  active = 1; // tab nào đang được active
  timerSearch=null; //timehanlde search
  folerName:string="";  //model FolderName
  initFolder = false;   //trạng thái khởi tạo folder

  public queryObj:QueryObj = new QueryObj;

  @Input("mode") mode: string = "";
  @Input("album") albumId: number;
  @Input("open-nav") isNavOpen:boolean = true;

  public album  = {data:[]};
  public albumSelected:Album = null;
  private albumDetailSelected = null;

  public albumDetail  = {
    data: []
  };

  constructor(private modalService: NgbModal,
    private notiService: NotiService,
    private mediaService: MediaService,
    private dialogService:DialogService,
    private blockService: BlockService
    ) { 

    }

  ngOnInit(): void {
    this.initFolder = true;
    this.queryObj.limit=20;
  }

  onInit(){
    // setTimeout(() => {
    //   this.getMedia();
    // }, 1);
  }

  public getSelected(){
    var listSelected = [];
    for (let i = 0; i < this.albumDetail.data.length; i++) {
      const element = this.albumDetail.data[i];
      if(element.selected)
      {
        listSelected.push(element);
      }
    }
    return listSelected;
  }

  public static(file =""){
    return environment.STATIC_URL+file;
  }

  getMedia(){
    this.blockService.load("media");
    this.queryObj.time= new Date().getTime();
    this.mediaService.getMedia(this.queryObj).then((res: ResponeResult)=>{
      if(res.repData.album && this.initFolder) {
        this.album = res.repData.album;
        if(this.albumId){
          let listAlbum = this.album.data;
          for (let i = 0; i < listAlbum.length; i++) {
            const element = listAlbum[i];
            if(element.albumId == this.albumId)
            {
              this.openAlbum(element);
              break;
            }
          }
        }
      }
      if(res.repData.albumDetail)
        this.albumDetail = res.repData.albumDetail;
      else{
        this.albumDetail.data = [];
      }
      this.queryObj.total = res.repData.albumDetail.totalRow;

    }).finally(()=>{
      this.initFolder = false;
      this.blockService.unload("media");
    })
  }

  doChooseMedia(item){
    if(this.mode == "mutiple")
    {
      if(item.selected)
      {
        item.selected = false;
      }else{
        item.selected = true;
      }
    }else{
      if(this.albumDetailSelected)
      {
        this.albumDetailSelected.selected= false;
      }
      item.selected = true;
      this.albumDetailSelected = item;
    }
    
  }

  changeFilterAlbum(x)
  {
    for (let i = 0; i < this.album.data.length; i++) {
      let element = this.album.data[i];
      element.selected = false;
    }
    this.openAlbum(x);
  }

  openAlbum(album:Album){
    
    if(album)
    {
      if(this.albumSelected)
      {
        this.albumSelected.selected = false;
      }
      album.selected = true;
      this.queryObj.albumId = album.albumId;
    }else{
      this.queryObj.albumId = null;
    }
    
    this.albumSelected = album;
    
    this.albumDetail.data = [];
    this.active = 2;
    this.getMedia();
  }

  openDelete(item, index){
    if(this.albumDetail.data && this.albumDetail.data.length > 0)
    {
      this.dialogService.warning({centered:false,
        buttons:[
          new ButtonDialog({label:"Hủy", id:ButtonType.isCancel, style:"secondary"}),
          new ButtonDialog({label:"Xóa", id:ButtonType.isOk, style:"danger"})
        ],
        message:`Xóa hình ảnh này có thể làm bài viết có sử dụng hình ảnh bị mất hình, Bạn không thể khôi phục hình đã xóa !`,
        title:"Bạn có chắc xóa hình này chứ ?"
      }).result.then((result:ButtonDialog) => {
        if(result.id == ButtonType.isOk)
        {
          this.blockService.load("media");
          this.mediaService.removeMedia(item).then(()=>{
            // this.albumDetail.data.splice(index, 1);
            this.getMedia();


          }).finally(()=>{
            this.blockService.unload("media");
          })
        }
      });
         
    }
    console.log(item);
  }

  toggleNav(){
    this.isNavOpen = !this.isNavOpen;
  }

  openModal(content, size="md") {
    this.modalService.open(content, { size , centered:true });
  }
  
  openUploader(){
    this.active = 1;
  }
  
  doCloseUpload(file){
    this.active = 2;
    this.getMedia();
  }

  searchChange(){
    clearTimeout(this.timerSearch);
    this.timerSearch = setTimeout(() => {
      this.doSearch(this.queryObj.search);
    }, 500);
  }

  doSearch(keyword){
    // this.queryObj.search = keyword;
    this.getMedia();
  }

  doCreateFolder(frmCreateFolder:NgForm, modal:NgbActiveModal){
    if(frmCreateFolder.invalid){
      this.notiService.error("Vui lòng nhập tên thư mục");
      return;
    }else{
      this.blockService.load("media");
      let newAlbum = new Album;
      newAlbum.albumName = this.folerName;
      newAlbum.folder = stringUtil.toASSII(this.folerName);
      newAlbum.albumParent = this.albumSelected?.albumId;
      
      let reqAlbum = new ReqAlbum;
      reqAlbum.rowAlbum = newAlbum;
      this.mediaService.createAlbum(reqAlbum).then((res:ResponeResult)=>{
        this.album.data.push(res.repData);
        this.folerName = "";
        modal.close();
        this.notiService.success("Tạo thư mục thành công");
      }).catch(err=>{

      }).finally(()=>{
        this.blockService.unload("media");
      });

      
    }
  }


  goToPage(n: number): void {
    this.queryObj.page = n;
    this.getMedia();
  }

  changeLimit(limit) {
    this.queryObj.limit = limit;
    this.getMedia();
  }

  onNext(): void {
    this.queryObj.page++;
    this.getMedia();
  }

  onPrev(): void {
    this.queryObj.page--;
    this.getMedia();
  }
}
