import { Component, ViewChild } from '@angular/core';
import { ReCaptcha2Component } from 'ngx-captcha';
import { BaseComponent } from "src/app/core/BaseComponent";
import { PartnerService } from "@shared/services/partner.service";
import { ResponeResult } from "@core/models/ResponeResult";
import { TextdataService } from '@shared/services/textdata.service';
import { TextData } from '@shared/models/textdata.model';


@Component({
  selector: 'app-insurrance',
  templateUrl: './insurrance.component.html',
  styleUrls: ['./insurrance.component.scss']

})
export class InsurranceComponent extends BaseComponent {
  public partners: Partner[];
  public viewMobile: boolean = false;
  public textData:TextData = new TextData;

@ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;
constructor(
  private textDataService:TextdataService,
  private partnerService: PartnerService){
  super();
  
  this.route.paramMap.subscribe(params =>{
    this.getData();
  });
}

ngOnInit() {
  this.seo.title = "Bảo lãnh viện phí";
  this.updateSEO();
}

getData() {
  this.partnerService.getDataAs().then((result:ResponeResult)=>{
    if (result.isOk) {
      this.partners = result.repData;      
    }
  }, error => console.error(error));

  this.textDataService.noValid().getTextDataByID("BAO-LANH-VIEN-PHI").then((res: ResponeResult)=>{
    this.textData = res.repData;
    this.seo.title = this.textData.titleName;
    this.seo.description = this.textData.shortDescription;
    this.updateSEO();
  }).catch().finally(()=>{

  })

}

  //carousel
  slideConfig = {
    "slidesToShow": 6,
    "slidesToScroll": 1,
    "autoplay": true,
    "autoplaySpeed": 4000,
    "swipeToSlide": true,
    arrows:false,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ]
  };

  slickInit(event: any) {
    console.log('slick initialized');
  }
  //end-carousel
}
export class Partner {
  languageId: string | any;
  companyName: string | any;
  companyAddress: string | any;
  notes: string | any;
  searchString: string | any;
  logo: string | any;
}
