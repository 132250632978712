import { Pipe, PipeTransform } from '@angular/core';

/**
 * Dùng để lấy (được chọn) những menu có giá trị tồn tại trong 1 mảng
 *  ví dụ : [a, b, c, d, e ,f]  -> sau khi xử lý lọc với màng [c,e] sẽ thành [c,e]
 *  Tham số truyền vào : Mảng cần lọc  ví dụ [c,e]
 *  key của đối tượng
 */

@Pipe({
  name: 'menushowfilter'
})
export class MenuShowFilterPipe implements PipeTransform {

  transform(items: any[], idlists: any[], key:string): unknown {
    if (!items || !idlists) {
      return items;
    }
    
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(function(item){
      if( idlists.includes( item[key] )){
        return true;
       }
       return false;
    });
  }

}
