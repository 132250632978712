import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class ConfigService extends BaseService {

  public updateImagePath(body) {
    return this.post("admin/UpdateImagePath",body);
  }

  public saveOption(body) {
    return this.post("admin/SaveOption",body);
  }

  public getOption(key) {
    return this.get("admin/GetOption",key);
  }

  public getAllOptionOther(DataType) {
    return this.get("admin/GetAllOptionOther",{DataType});
  }

  public getOptionHome(key) {
    return this.get("home/GetOption",key);
  }

}


