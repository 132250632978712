<div class="container-fluid p-0">
  <!--Background top-->
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ 'servicePage.title' | translate }}</h1>
    </div>
  </div>
</div>

<!-- chi tiết các dịch vụ -->
<section class="services" *ngIf="serviceDTO">
  <div class="container">
    <!-- <h4 class="title-top">{{'servicePage.title' | translate}}</h4> -->
    <div class="row text text-justify mt-2" [innerHTML]="serviceDTO.service.shortDescription | safeHtml"></div>
    <div class="row text text-justify mt-2" [innerHTML]="serviceDTO.service.longDescription | safeHtml"></div>

    <div class="service-content">
      <div class="row">
        <div class="col-lg-4 col-sm-6 mb-2" *ngFor="let row of serviceDTO.departments">
          <div class="active">
            <div [routerLink]="['/chuyen-khoa', row.linkCode]">
              <div class="image">
                <img [src]="static(row.imagePath)" alt="{{ row.imageSeotitle }}}" onError="this.src='/assets/images/thumb.png'" />
                <div class="overlay"></div>
              </div>
              <div class="absolute">
                <img [src]="static(row.logoImage)" alt="{{ row.imageSeotitle }}}" onError="this.src='/assets/images/thumb.png'" />
                <h2 class="title">{{ row.departmentName }}</h2>
              </div>
              <div class="services-title">
                <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                <a class="btn btn-viewmore" [routerLink]="['/chuyen-khoa', row.linkCode]">{{ 'buttons.readmore' | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- gói khám sức khỏe -->
<section class="package clearfix card-block bg-light" *ngIf="serviceDTO">
  <div class="container">
    <span class="title-top">{{ 'servicePage.packageList' | translate }}</span>
    <div class="row" [dsMatchHeight]="'card'">
      <div class="col-md-12 col-lg-6 mb-3" *ngFor="let row of serviceDTO.packages">
        <div class="category card">
          <div class="row">
            <!-- images -->
            <div class="col-sm-6 col-md-5">
              <div class="discount">
                <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" alt="Alternate Text" />
              </div>
              <div class="image">
                <a class="link" [title]="row.packageName" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                  <img [src]="static(row.imagePath)" alt="{{ row.imageSeotitle }}" onError="this.src='/assets/images/thumb.png'" />
                </a>
              </div>
            </div>
            <!-- content -->
            <div class="col-sm-6 col-md-7">
              <div class="title">
                <a [title]="row.packageName" class="link" [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                  <span>{{ row.packageName }}</span>
                </a>
                <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                <div class="pt-2">
                  <div class="d-flex justify-content-start">
                    <span class="salePrice">{{ row.salePrice | number : 0 }}₫</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Tiêm Chủng-->
<section class="vaccination" *ngIf="serviceDTO">
  <div class="container">
    <div class="row">
      <div class="col-md-7 m-auto">
        <h2 class="title-top">{{ serviceDTO.imunization.titleName }}</h2>
        <div class="text text-justify" [innerHTML]="serviceDTO.imunization.shortDescription | safeHtml"></div>
        <div class="footer py-3 text-center text-md-right">
          <a class="btn btn-viewmore" [routerLink]="['/chuyen-khoa/tiem-chung']">{{ 'buttons.readmore' | translate }}</a>
        </div>
      </div>
      <div class="col-md-5 text-center m-auto">
        <img
          [src]="static(serviceDTO.imunization.imageSmall)"
          alt="{{ serviceDTO.imunization.imageSeotitle }}"
          onError="this.src='/assets/images/thumb.png'"
        />
      </div>
    </div>
  </div>
</section>

<!--Nhà thuốc-->
<section class="pharmacy" *ngIf="serviceDTO">
  <div class="container">
    <div class="row">
      <div class="col-md-7 m-auto">
        <h2 class="title-top">{{ serviceDTO.pharmacy.titleName }}</h2>
        <div class="text text-justify" [innerHTML]="serviceDTO.pharmacy.shortDescription | safeHtml"></div>
        <div class="footer py-3 text-center text-md-right">
          <a class="btn btn-viewmore" [routerLink]="['/chuyen-khoa/nha-thuoc']">{{ 'buttons.readmore' | translate }}</a>
        </div>
      </div>
      <div class="col-md-5 text-center m-auto">
        <img
          [src]="static(serviceDTO.pharmacy.imageSmall)"
          alt="{{ serviceDTO.pharmacy.imageSeotitle }}"
          onError="this.src='/assets/images/thumb.png'"
        />
      </div>
    </div>
  </div>
</section>
