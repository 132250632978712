<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>
        Kết quả tìm kiếm:
        <i
          ><strong>"{{ querySearch.search }}"</strong></i
        >
      </h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">Tìm kiếm</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<!--package -->
<section class="package">
  <div class="container p-0 p-md-1">
    <app-block id="search-result-box" (onInit)="onInit()" classBlock="px-0 px-md-1">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Gói khám ({{ searchDTO.packages.length }})</a>
          <ng-template ngbNavContent>
            <div class="body-result card-block">
              <div class="row">
                <div class="col-md-12 col-lg-6 mb-4" *ngFor="let row of searchDTO.packages">
                  <div class="card category mb-3">
                    <div class="row">
                      <!-- images -->
                      <div class="col-sm-6 col-md-5">
                        <div class="discount">
                          <img *ngIf="row.isHost" src="/assets/images/general/discount1.png" alt="Alternate Text" />
                        </div>
                        <div class="image">
                          <a [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                            <img [src]="static(row.imagePath)" alt="{{ row.imageSeotitle }}}" onError="this.src='/assets/images/thumb.png'" />
                          </a>
                        </div>
                      </div>
                      <!-- content -->
                      <div class="col-sm-6 col-md-7">
                        <div class="content">
                          <div class="title">
                            <a [routerLink]="['/goi-kham', row.categoryCode, row.linkCode]">
                              <h3>{{ row.packageName }}</h3>
                            </a>
                          </div>
                          <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                          <div class="price pt-2">
                            <div class="d-flex justify-content-end">
                              <h6 class="listPrice" *ngIf="row.isDisccount == true">{{ row.listPrice | number : 0 }}₫</h6>
                              <h5 class="salePrice" *ngIf="row.isDisccount == true">{{ row.salePrice | number : 0 }}₫</h5>
                              <h5 class="Price-cost" *ngIf="row.isDisccount == false">{{ row.listPrice | number : 0 }}₫</h5>
                            </div>
                            <div *ngIf="row.isApplyDate" class="more">
                              <span class="timeDate">
                                <i class="far fa-calendar-alt px-1"></i>Áp dụng: {{ row.fromDate | date : 'dd/MM/yyyy' }} -
                                {{ row.toDate | date : 'dd/MM/yyyy' }}</span
                              >
                            </div>
                            <div *ngIf="!row.isApplyDate" class="more">
                              <span class="timeDate"> <i class="far fa-calendar-alt px-1"></i>Ngày: {{ row.publishDate | date : 'dd/MM/yyyy' }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Bác sĩ ({{ searchDTO.doctors.length }})</a>
          <ng-template ngbNavContent>
            <div class="body-result">
              <ng-container *ngFor="let row of searchDTO.doctors; let index = index">
                <div *ngIf="0 === index % 2" class="doctor-background1">
                  <div class="doctor">
                    <div class="row">
                      <div class="col-lg-4 doctor-img">
                        <img [src]="static(row.imagePath)" alt="{{ row.imageSeodescription }}}" onError="this.src='/assets/images/thumb.png'" />
                      </div>
                      <div class="col-lg-8 desktop1">
                        <div class="d-flex">
                          <h4 class="doctor-level text-uppercase">{{ row.levelName }} &nbsp;</h4>
                          <h4 class="doctor-name text-uppercase ps-2">{{ row.doctorName }}</h4>
                        </div>
                        <div class="level">
                          <ul class="list-unstyled m-none">
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.diplomaTitle }}</strong>
                            </li>
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.positionTitle }}</strong>
                            </li>
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.departmentTitle }}</strong>
                            </li>
                          </ul>
                        </div>
                        <div class="short-content">
                          <p [innerHTML]="row.shortDescription | safeHtml"></p>
                        </div>
                        <div class="btn-process">
                          <a class="btn btn-booking m-1" [routerLink]="['/dat-lich-kham']">Đặt lịch khám</a>
                          <a class="btn btn-viewmore m-1" [routerLink]="['/bac-si', row.linkCode]">Xem thêm</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="0 !== index % 2 && !viewMobile" class="doctor-background2">
                  <div class="doctor">
                    <div class="row">
                      <div class="col-lg-8 desktop2">
                        <div class="d-flex">
                          <h4 class="doctor-level text-uppercase">{{ row.levelName }} &nbsp;</h4>
                          <h4 class="doctor-name text-uppercase ps-2">{{ row.doctorName }}</h4>
                        </div>
                        <div class="level">
                          <ul class="list-unstyled m-none">
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.diplomaTitle }}</strong>
                            </li>
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.positionTitle }}</strong>
                            </li>
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.departmentTitle }}</strong>
                            </li>
                          </ul>
                        </div>
                        <div class="short-content">
                          <p [innerHTML]="row.shortDescription | safeHtml"></p>
                        </div>
                        <div class="btn-process">
                          <a class="btn btn-booking m-1" [routerLink]="['/dat-lich-kham']">Đặt lịch khám</a>
                          <a class="btn btn-viewmore m-1" [routerLink]="['/bac-si', row.linkCode]">Xem thêm</a>
                        </div>
                      </div>
                      <div class="col-lg-4 doctor-img">
                        <img [src]="static(row.imagePath)" alt="{{ row.imageSeodescription }}}" onError="this.src='/assets/images/thumb.png'" />
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="0 !== index % 2 && viewMobile" class="doctor-background1">
                  <div class="doctor">
                    <div class="row">
                      <div class="col-lg-4 doctor-img">
                        <img [src]="static(row.imagePath)" alt="{{ row.imageSeodescription }}}" onError="this.src='/assets/images/thumb.png'" />
                      </div>
                      <div class="col-lg-8 desktop1">
                        <div class="d-flex">
                          <h4 class="doctor-level text-uppercase">{{ row.levelName }} &nbsp;</h4>
                          <h4 class="doctor-name text-uppercase">{{ row.doctorName }}</h4>
                        </div>
                        <div class="level">
                          <ul class="list-unstyled m-none">
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.diplomaTitle }}</strong>
                            </li>
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.positionTitle }}</strong>
                            </li>
                            <li>
                              <i class="literacy fas fa-check-circle"></i> <strong class="literacy title"> {{ row.departmentTitle }}</strong>
                            </li>
                          </ul>
                        </div>
                        <div class="short-content">
                          <p [innerHTML]="row.shortDescription | safeHtml"></p>
                        </div>
                        <div class="btn-process">
                          <a class="btn btn-booking m-1" [routerLink]="['/dat-lich-kham']">Đặt lịch khám</a>
                          <a class="btn btn-viewmore m-1" [routerLink]="['/bac-si', row.linkCode]">Xem thêm</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink>Tin tức ({{ searchDTO.news.length }})</a>
          <ng-template ngbNavContent>
            <div class="news body-result result-new card-block">
              <div class="row" [dsMatchHeight]="'card'">
                <div class="col-sm-12 col-md-12 col-lg-6 mb-3" *ngFor="let row of searchDTO.news">
                  <div class="card category">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-image">
                        <div class="image">
                          <a [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                            <img [src]="static(row.imageSmall)" alt="{{ row.thumbnailSeonews }}" />
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-5 col-md-6">
                        <div class="content">
                          <div class="title mb-2">
                            <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">{{
                              row.titleName
                            }}</a>
                          </div>
                          <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                          <div class="more mt-2">
                            <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                              {{ 'buttons.readmore' | translate }} ...</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </app-block>
  </div>
</section>
