import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BlockService } from '../../services/block.service';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlockComponent implements OnInit {
  private _element: any;
  @Input('load') isLoad:boolean = false;
  @Input() id: string | any;
  @Input() classBlock: string | any;
  @Output() onInit = new EventEmitter(); // Handle sự kiện khi block được load xong

    
  constructor(public blockService:BlockService, private el: ElementRef) {
    this._element = el.nativeElement;
   }

  ngOnInit(): void {
    this.blockService.add(this);
    this.onInit.emit();
  }

  ngOnDestroy(): void {
    this.blockService.remove(this.id);
    this._element.remove();
  }

  load(): void {
   this.isLoad = true;
    // document.body.classList.add('on-loading');
  }

  unload(): void {
    this.isLoad = false;
    // document.body.classList.remove('on-loading');
  }
}
