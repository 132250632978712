import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from "src/app/core/BaseComponent";
import { JobService } from "@shared/services/job.service";
import { ResponeResult } from "@core/models/ResponeResult";

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class JobDetailComponent extends BaseComponent {
  urlLink: string | any;
  linkCode: string | any;
  jobDTO: JobDTO = new JobDTO;

  constructor(
    public route: ActivatedRoute,
    public jobService: JobService
    ) {
    super();

    this.route.paramMap.subscribe(params => {
      this.linkCode = this.route.snapshot.paramMap.get("id");
      this.getData();
    });
  }

  ngOnInit() {
    this.seo.title = "Tuyển dụng";
    this.updateSEO();
  }

  getData() {
    this.jobService.noValid().getDataID({linkCode:this.linkCode}).then((result:ResponeResult)=>{
      if (result.isOk) {
        this.jobDTO = result.repData;
        this.seo.title = this.jobDTO.rowJob.titleName;
        this.seo.description = this.jobDTO.rowJob.shortDescription;
        this.updateSEO();
      }
    }).catch((err)=>{
      this.router.navigate(['/page-not-found']);
    });
  }
}

export class JobDTO {
  rowJob: Job = new Job;
  jobRelated: Job[] = [];
}

export class Job {
  linkCode: string | any;
  publishDate: Date | any;
  titleName: string | any;
  shortDescription: string | any;
  longDescription: string | any;
  imageSmall: string | any;
}
