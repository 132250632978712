export class MenuItem{
  label:string = "Simple Single";
  icon:string = "fa-caret-right";
  link:string|boolean = "";
  key:string= "";
  active:boolean = true;
  pos:number=1;
  childs:MenuItem[]= [];
  constructor(payload: Partial<MenuItem> = null){
    for(const key in payload){
      if(key == "childs")
      {

      }
      if(this.hasOwnProperty(key)){
        this[key] = payload[key];
      }
    }
  }
}