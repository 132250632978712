import { Injectable } from '@angular/core';
import { TextDataAdmin } from '@app/admin/models/requestDTO';
import { BaseService } from '@core/BaseService';
import { ResponeResult } from '@core/models/ResponeResult';
import { QueryObj } from '@shared/models/query.model';

@Injectable({ providedIn: 'root' })
export class TextdataService extends BaseService {
  companyInfo = null;
  public getCompanyInfo() {
    return new Promise((reslove, reject) => {
      if (this.companyInfo?.length > 0) {
        reslove(this.companyInfo);
      } else {
        let query: QueryObj = new QueryObj();
        query.groupOption = 'COMPANY';
        query.isPublish = -1;
        this.get('home/GetTextData', query)
          .then((res: ResponeResult) => {
            if (res.repData?.length > 0) {
              let data = res.repData;
              this.companyInfo = [];
              for (let i = 0; i < data.length; i++) {
                const element = <TextDataAdmin>data[i];
                this.companyInfo[element.optionId] = element;
              }

              reslove(this.companyInfo);
            }
          })
          .catch((err) => {
            reject(err);
          })
          .finally();
      }
    });
  }

  seoConfig = null;
  public getSEOconfig() {
    return new Promise((reslove, reject) => {
      if (this.seoConfig?.length > 0) {
        reslove(this.seoConfig);
      } else {
        let query: QueryObj = new QueryObj();
        query.groupOption = 'SEOCONFIG';
        query.isPublish = -1;
        this.get('home/GetTextData', query)
          .then((res: ResponeResult) => {
            if (res.repData?.length > 0) {
              let data = res.repData;
              this.seoConfig = [];
              for (let i = 0; i < data.length; i++) {
                const element = <TextDataAdmin>data[i];
                this.seoConfig[element.optionId] = element;
              }

              reslove(this.seoConfig);
            }
          })
          .catch((err) => {
            reject(err);
          })
          .finally();
      }
    });
  }

  public getTextDataByID(PageId) {
    return this.get('GetTextData', { PageId });
  }
  //backend
  public getTextData(query) {
    return this.get('admin/GetTextData', query);
  }
  public saveTextData(body) {
    return this.post('admin/SaveTextData', body);
  }
  public deleteTextData(body) {
    return this.post('admin/DeleteTextData', body);
  }
}
