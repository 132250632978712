import { Component } from '@angular/core';
import { BaseComponent } from '@core/BaseComponent';
import { TextdataService } from '@shared/services/textdata.service';
import { TextData } from '@shared/models/textdata.model';
import { ResponeResult } from '@core/models/ResponeResult';

@Component({
  selector: 'app-termcondition',
  templateUrl: './termcondition.component.html'
})
export class TermConditionComponent extends BaseComponent{
  textData:TextData = new TextData;
  constructor(private textDataService:TextdataService) {
    super();
  }
  ngOnInit() {
    this.seo.title = "Chính sách hoạt động";
    this.updateSEO();
    this.getData();
  }

  getData(){
    this.textDataService.noValid().getTextDataByID("CHINH-SACH-HOAT-DONG").then((res: ResponeResult)=>{
      this.textData = res.repData;
      this.seo.title = this.textData.titleName;
      this.seo.description = this.textData.shortDescription;
      this.updateSEO();
    }).catch().finally(()=>{
  
    })
  }
}

