export class AuthenParams {
    IpAddress: string | any;
    ClientUserName: string | any;
    ClientPassword: string | any;
    Sign: string | any;
    Token: string | any;
}

export class ReqDelete extends AuthenParams {
  id: any;
}
export class ReqID extends AuthenParams {
  id2: any;
}

export class ReqSortOrder extends AuthenParams {
  upId: any;
  downId: any;
}


