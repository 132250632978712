import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '@core/BaseComponent';
import { TextdataService } from '@shared/services/textdata.service';

@Component({
  selector: 'app-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class NavFooterComponent extends BaseComponent {
  isExpanded = false;
  companyInfo:Object = new Object;

  constructor(injector:Injector, private textdataService: TextdataService,){
    super(injector,false);
  }

  ngOnInit(): void {
    if(this.isBrowser)
    {
      this.getDataInfo();
    }
  }
  
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }


  getDataInfo() {
    this.textdataService.getCompanyInfo().then((res) => {
      this.companyInfo = res;
    }).catch().finally(() => {

    });
  }
}
