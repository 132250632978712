import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scripts: any = {};
  private styles: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });

    StyleStore.forEach((script: any) => {
      this.styles[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadScripts(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script, false)));
    return Promise.all(promises);
  }

  loadStyles(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadStyle(script)));
    return Promise.all(promises);
  }

  loadScript(name: string, async: boolean = true) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load script
        let script = document.createElement('script') as any;
        script.type = 'text/javascript';
        script.async = async;
        script.src = this.scripts[name].src;
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  loadStyle(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.styles[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load style
        let style = document.createElement('link') as any;
        style.type = 'text/css';
        style.rel = 'stylesheet';
        style.href = this.styles[name].src;
        if (style.readyState) {
          //IE
          style.onreadystatechange = () => {
            if (
              style.readyState === 'loaded' ||
              style.readyState === 'complete'
            ) {
              style.onreadystatechange = null;
              this.styles[name].loaded = true;
              resolve({ style: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          style.onload = () => {
            this.styles[name].loaded = true;
            resolve({ style: name, loaded: true, status: 'Loaded' });
          };
        }
        style.onerror = (error: any) =>
          resolve({ style: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(style);
      }
    });
  }
}

interface Scripts {
  name: string;
  src: string;
}

export const StyleStore: Scripts[] = [
  { name: 'slick-carouse', src: '/slick-carousel/slick/slick.css' },
  {
    name: 'slick-carouse-theme',
    src: '/slick-carousel/slick/slick-theme.css',
  },
  { name: 'lightbox', src: '/assets/css/lightbox.css' },
  { name: 'aos', src: '/aos/dist/aos.css' },
  { name: 'fontawesome', src: '/fontawesome/css/all.min.css' },
];

export const ScriptStore: Scripts[] = [
  { name: 'jquery', src: '/jquery/dist/jquery.min.js' },
  { name: 'bootstrap', src: '/bootstrap/dist/js/bootstrap.min.js' },
  { name: 'slick', src: '/slick-carousel/slick/slick.min.js' },
  { name: 'tinymce', src: '/tinymce/tinymce.min.js' },
  { name: 'aos', src: '/aos/dist/aos.js' },
];
