export class Feedback {
  FeedbackId: number = 0;
  FeedbackTypeId: number = 0;
  PatientNumber: string = '';
  PatientName: string = '';
  Mobile: string = '';
  Comments: string = '';
  SourceType: number = 1;
  CreateDate: Date = new Date();
  CheckListComplainTypeId: string = '';
  ClientUserName = 'SIM-MED';
  ClientPassword = 'C4CA4238A0B923820DCC509A6F75849B';
  LanguageId = 'vi-VN';
  IpAddress = '192.1.1.1';
}
