import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";


@Injectable({ providedIn: 'root' })
export class AdvertiseService extends BaseService {

  public GetAdvertise(query) {
    return this.get("GetAdvertise", query);
  }
   //frontend
   public getData(query) {
    return this.get("admin/advertise", query);
  }
  public save(body) {
    return this.post("admin/advertise", body);
  }

  public delete(id) {
    return this.post("admin/advertise/delete", id);
  }
}