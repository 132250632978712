import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
// import { ReCaptcha2Component } from 'ngx-captcha';
import { BaseComponent } from 'src/app/core/BaseComponent';
import { ModalService } from '@shared/module/modal';
import { BookingService } from '@shared/services/booking.service';
import { ResponeResult } from '@core/models/ResponeResult';
import { BlockService } from '@shared/module/block/services/block.service';
import { Booking, BookingDTO, BookingForm } from '@shared/models/booking.model';
import { TranslatesService } from '@shared/module/translate';
import { NotiService } from '@shared/module/notification';
import { CustomDateParserFormatter } from '@shared/providers/datepickerFormatter';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import stringUtil from '@shared/utils/string.util';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  providers: [
    // {provide: NgbDateAdapter, useClass: CustomAdapter},
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class BookingComponent extends BaseComponent implements OnInit {
  bookingDTO: BookingDTO = new BookingDTO();
  bookingForm = new BookingForm();
  storeName: string | any;
  doctorName: string | any;
  timeStatusName: string | any;
  isLoading = false;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{0,50}$';
  emailPattern = '[aA-zZ0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';
  isValidFormSubmitted = false;
  langCode: string = 'vi-VN';
  listTime = [];
  mindate: NgbDate;

  //#region recaptcha
  public readonly siteKey = '6LerRWgaAAAAAEvR5JlwnvcS6Jp7mVqqQJEn5y8I';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string | any;
  public doctorCode: string = '';
  //#endregion recaptcha

  @ViewChild('popSuccess') popSuccess;
  @ViewChild('popWarning') popWarning;
  // @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private bookingService: BookingService,
    private blockService: BlockService,
    private translate: TranslatesService,
    private notiService: NotiService,
    private calendar: NgbCalendar,
    private ngbModal: NgbModal
  ) {
    super();

    this.bookingForm.BookingDate = this.calendar.getToday();
    this.mindate = this.calendar.getToday();
  }

  //#region recaptcha
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  //#endregion recaptcha

  ngOnInit() {
    this.langCode = this.translate.getLanguage().culture;
    this.translate.onLoad().then(() => {
      this.listTime = [
        {
          value: 1,
          text: this.translate.instant('form.name.select_time.list.morning'),
        },
        {
          value: 2,
          text: this.translate.instant('form.name.select_time.list.afternoon'),
        },
        {
          value: 3,
          text: this.translate.instant('form.name.select_time.list.evening'),
        },
      ];

      // this.ngbModal.open(this.popSuccess, {centered:true}).result.then((result) => {
      // });

      // this.ngbModal.open(this.popWarning, {centered:true}).result.then((result) => {
      // });
    });
    this.seo.title = 'Đặt lịch khám';
    this.updateSEO();

    this.route.queryParams.subscribe((params) => {
      this.doctorCode = params.doctor;
    });
  }

  initData() {
    this.getData();
  }

  onSubmitSchedule(frm: NgForm) {
    if (frm.valid) {
      this.addBooking();
    } else {
      this.notiService.warn(
        this.translate.instant('bookingPage.alertMsgInValid')
      );
    }
  }

  resetData() {
    if (this.isBrowser) {
      window.location.reload();
    }
    // this.bookingForm = new BookingForm;
  }

  getData() {
    this.blockService.load('booking');
    this.bookingService
      .getInfo()
      .then((res: ResponeResult) => {
        this.bookingDTO = res.repData;
        this.setDefaultDataForm();
      })
      .catch()
      .finally(() => {
        this.blockService.unload('booking');
      });
  }

  setDefaultDataForm() {
    if (this.bookingDTO.stores.length > 0) {
      this.bookingForm.Store = this.bookingDTO.stores[0];
      this.changeStore(this.bookingDTO.stores[0]);

      if (this.doctorCode.length > 0) {
        for (let i = 0; i < this.bookingDTO.doctorFromStores.length; i++) {
          const element = this.bookingDTO.doctorFromStores[i];
          if (element.linkCode == this.doctorCode) {
            this.bookingForm.Doctor = element;
            return;
          }
        }
      }
    }
  }

  changeStore(event: Event | any) {
    let docters = this.bookingDTO.doctors.filter(
      (listing: any) => listing.storeId == event.storeId
    );
    this.bookingDTO.doctorFromStores = docters ? docters : [];
    this.bookingForm.Doctor = null;
  }

  addBooking() {
    var mCurrDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
    var tmpBookingDate = `${
      this.bookingForm.BookingDate.year
    }-${stringUtil.padLeadingZeros(
      this.bookingForm.BookingDate.month,
      2
    )}-${stringUtil.padLeadingZeros(this.bookingForm.BookingDate.day, 2)}`;
    var currDate = new Date(mCurrDate);
    var bookingDate = new Date(tmpBookingDate);

    if (bookingDate < currDate) {
      // this.openModal('popup-Alert');
      this.ngbModal
        .open(this.popWarning, { centered: true })
        .result.then((result) => {});

      return;
    } else {
      var reqDataApp = new AppointmentDTO();
      reqDataApp.RowBooking = new Booking();
      reqDataApp.RowBooking.initWithForm(this.bookingForm);

      reqDataApp.StoreName = this.bookingForm.Store?.storeName;
      reqDataApp.DoctorName = this.bookingForm.Doctor?.doctorName;
      reqDataApp.TimeStatus = this.bookingForm.TimeStatus.text;

      reqDataApp.RowBooking.BookingDate = bookingDate;
      reqDataApp.RowBooking.DepartmentId = -1;
      reqDataApp.RowBooking.TrackingNotes = null;
      reqDataApp.RowBooking.IsNewPatient = true;
      reqDataApp.RowBooking.Address = null;
      reqDataApp.RowBooking.Email = reqDataApp.RowBooking.Email ?? null;
      reqDataApp.RowBooking.DoctorId = reqDataApp.RowBooking.DoctorId ?? null;
      this.isLoading = true;
      this.blockService.load('booking');
      this.bookingService
        .booking(reqDataApp)
        .then((res) => {})
        .catch()
        .finally(() => {
          this.blockService.unload('booking');
          // this.openModal('custom-modal-1');
          this.ngbModal
            .open(this.popSuccess, { centered: true })
            .result.then((result) => {
              if (this.isBrowser) window.location.reload();
            });
          this.isLoading = false;
        });
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}

export class AppointmentDTO {
  RowBooking: Booking = new Booking();
  StoreName: string = '';
  DoctorName: string = '';
  TimeStatus: string = '';
}
