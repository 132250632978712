import { Component } from '@angular/core';
import { BaseComponent } from "src/app/core/BaseComponent";

@Component({
  selector: 'app-faq-answer',
  templateUrl: './faq-answer.component.html',
  styleUrls: ['./faq-answer.component.css']
})

export class FaqAnswerComponent extends BaseComponent {
  constructor() {
    super();
  }

  ngOnInit() {
    this.seo.title = "Bác sĩ trả lời";
    this.updateSEO();
  }
}
