import { Injectable } from "@angular/core";
import { BaseService } from "@core/BaseService";
import { Cart } from "@shared/module/cart/models/cart.model";


@Injectable({ providedIn: 'root' })
export class StoreService extends BaseService {
  public getStore() {
    return this.get("home/GetStore",{isActive:1});
  }

  public getStoreAdmin() {
    return this.get("GetListStore");
  }

  public checkout(cart: Cart) {
    return this.post("store/checkout", cart);
  }

  public syncCart(cart: Cart) {
    return this.post("store/sync", cart);
  }
}